<template>
  <div class="echarts-box">
    <div id="container8" ></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  data(){
    return {
      xsyf: [],
      xssl: []
    }
  },
  mounted() {
    // this.moreChart();
  },
  methods: {
    moreChart() {
      var chartDom = document.getElementById('container8');
      var myChart = echarts.init(chartDom);
      var option;
      var xData = this.xsyf;
      var yStandard = this.xssl; //标准
      option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: "rgba(00,00,00,0.7)", //设置背景颜色
          borderColor: "rgba(00,00,00,0.7)", //设置边框颜色
          textStyle: {
            fontSize: 68,
            color:'#ffffff'
          },
          formatter: function(params) {
            var result = ''
            params.forEach(function (item) {
              result += + item.data+'元/斤'
            })
            return result
          }
        },
        grid: {
          left: 140,
          top: 30,
          bottom: 50,
          right: 50
        },
        toolbox: {
          show: false,
          textStyle: {
            color: '#fff',
            fontSize: 50
          },
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: {readOnly: false},
            magicType: {type: ['line']},
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xData,
          nameTextStyle :{
            fontSize: 50
          },
          axisLabel: {
            textStyle: {
              color: '#CCCCCC',
              fontSize: 50
            },
          },
          splitArea: {
            show: false,
            interval: 0,
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false,
            lineStyle:{
              color: '#CCCCCC',
              width: 10,
              type: 'solid'
            }
          },
          splitLine: {
            show: true,
            lineStyle:{
              color: 'rgba(255,255,255,0.15)',
              width: 2,
              type: 'solid'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#CCCCCC',
              fontSize: 50
            },
          },
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#3de2d6',
              width: 6
            }
          },
        },
        series: [
          {
            name: '',
            type: 'line',
            data: yStandard,
            smooth: .4,
            symbolSize: 3,
            symbol:'circle',
            color: '#3de2d6',
            showSymbol: true,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  fontSize: 50,
                  textStyle: {
                    color: "#fff"
                  }
                }
              }
            },
            lineStyle:{
              width: 8,
              color: '#00D2FE'
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#adf1ff'
              }, {
                offset: 1,
                color: '#0B1834'
              }])
            },
          },
        ]
      };
      option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
.charts-box, #container8 {
  width: 100%;
  height: 570px;
}
</style>