<template>
  <div>
        <div id="echarts3" class="echarts3"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
// @ is an alias to /src
export default {
  name: 'sheepHose',
  data(){
      return{
          yfLists: [],
          zhiLists1: [],
          zhiLists2: [],
      }
  },
  props: {
    // echartsMonth: {
    //     type: Array
    // }
    //   echartsDetermination: {
    //       type: Object
    //   },
    //   eweProduce: {
    //       type: Object
    //   },
    //   echartsSex: {
    //       type: String
    //   } 
  },
  mounted(){
    //   this.man()
  },
  watch: {

  },
  methods:{
      man() {
        console.log(this.yfLists)
        console.log(this.zhiLists1)
        var chartDom = document.getElementById('echarts3');
        var myChart = echarts.init(chartDom);
        var option;
        var xData = this.yfLists;
        
        var y1 = this.zhiLists1; //生产
        var y2 = this.zhiLists2; //生产
        console.log()
        option = {
            // title: {
            //     text: '配种图表(单位: 只)',
            //     //subtext: '纯属虚构'
            //      textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
            //         fontFamily: 'Arial, Verdana, sans...',
            //         fontSize: 14,
            //         color: '#0B1947',
            //         fontWeight: 'normal',
            //     },
            // },


            grid:{
                top: 50,
                left: 200,
                right: 40,
                bottom:  70,
                borderWidth:1
            },
            // tooltip: {
            //     trigger: 'axis'
            // },
           color: ['#00D4F9', '#FED400'],
            // legend: {
            //     icon: 'rectangle',
            //     data: ['生产', '配种', '怀孕'],
            //     right: '4%'
            // },
            // toolbox: {
            //     show: false,
            //     feature: {
            //         dataView: {show: true, readOnly: false},
            //         magicType: {show: true, type: ['line', 'bar']},
            //         restore: {show: true},
            //         saveAsImage: {show: true}
            //     }
            // },
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    data: xData,
                    splitLine:{
                        show:false
                    },
                    axisLabel : {
                        textStyle: { //改变刻度字体样式
                            color: '#C1C2D0',
                            fontSize: 50
                        },
                        align: 'center',
                        padding: [0,45,0,0]
                    },
                    // splitArea: {
                    //     show: true,
                    //     interval: .1,
                    //     areaStyle: {
                    //         color: '#000'
                    //     },
                    // },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color: '#142D4A',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    axisLabel : {
                        textStyle: { //改变刻度字体样式
                            color: '#ccc',
                            fontSize: 50
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
               }
            ],
            series: [
                {
                    name: '',
                    type: 'bar',
                    data: y1,
                    barWidth : 50,//柱图宽度
                    color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                        offset: 0, color: '#f96fb5' // 100% 处的颜色
                    }, {
                        offset: 1, color: '#fad35c' // 0% 处的颜色
                    }]),

                    emphasis: {
                        focus: "series",
                    },
                    label: {
                        show: true,
                        color: '#f96fb5',
                        position: 'top',
                        fontSize: 56
                    },
                    itemStyle: {
                        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        emphasis: {
                            barBorderRadius: 50
                        },
                        normal: {
                            barBorderRadius:[50, 50, 50, 50],
                        },
                        color:'#00D4F9',
                        color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                            offset: 0, color: '#f96fb5' // 0% 处的颜色
                        }, {
                            offset: 1, color: '#fad35c' // 100% 处的颜色
                        }]),
                        
                    },
                },
                {
                    name: '',
                    type: 'bar',
                    data: y2,
                    barWidth : 50,//柱图宽度
                    color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                        offset: 0, color: '#036990' // 100% 处的颜色
                    }, {
                        offset: 1, color: '#00D4F9' // 0% 处的颜色
                    }]),

                    emphasis: {
                        focus: "series",
                    },
                    label: {
                        show: true,
                        color: '#C6AC28',
                        position: 'top',
                        fontSize: 56
                    },
                    itemStyle: {
                        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        emphasis: {
                            barBorderRadius: 50
                        },
                        normal: {
                            barBorderRadius:[50, 50, 50, 50],
                        },
                        // color:'#00D4F9',
                        color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                            offset: 0, color: '#C6AC28' // 0% 处的颜色
                        }, {
                            offset: 1, color: '#A79F6D' // 100% 处的颜色
                        }]),
                        
                    },
                },
            ]
        };
        option && myChart.setOption(option);
      }
  }
}
</script>

<style scoped>
    .echarts3 {
        width: 100%;
        height: 562px;
    }
</style>