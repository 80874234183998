<template>
  <div class="echarts-box">
    <div id="container6" ></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  data(){
    return {
        xsyf: [],
        xssl1: [],
        xssl2: []
    }
  },
  mounted() {
    // this.moreChart();
  },
  methods: {
    moreChart() {
      var chartDom = document.getElementById('container6');
        var myChart = echarts.init(chartDom);
        var option;
        var xData = this.xsyf;
        var y1 = this.xssl1; //标准
        var y2 = this.xssl2; //标准
        console.log(y1,y2)
        option = {
            // title: {
            //     text: '配种图表(单位: 只)',
            //     //subtext: '纯属虚构'
            //      textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
            //         fontFamily: 'Arial, Verdana, sans...',
            //         fontSize: 14,
            //         color: '#0B1947',
            //         fontWeight: 'normal',
            //     },
            // },


            grid:{
                top: 50,
                left: 180,
                right: 40,
                bottom:  70,
                borderWidth:1
            },
            // tooltip: {
            //     trigger: 'axis'
            // },
           color: ['#00D4F9', '#FED400'],
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    data: xData,
                    splitLine:{
                        show:false
                    },
                    axisLabel : {
                        textStyle: { //改变刻度字体样式
                            color: '#C1C2D0',
                            fontSize: 50
                        }
                    },
                    // splitArea: {
                    //     show: true,
                    //     interval: .1,
                    //     areaStyle: {
                    //         color: '#000'
                    //     },
                    // },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color: '#142D4A',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    axisLabel : {
                        textStyle: { //改变刻度字体样式
                            color: '#ccc',
                            fontSize: 50
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
               }
            ],
            series: [
                {
                    name: '',
                    type: 'bar',
                    data: y1,
                    barWidth : 100,//柱图宽度
                    color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                        offset: 0, color: '#00D5FB' // 100% 处的颜色
                    }, {
                        offset: 1, color: '#4E2BD8' // 0% 处的颜色
                    }]),

                    emphasis: {
                        focus: "series",
                    },
                    label: {
                        show: true,
                        color: '#00D5FB',
                        position: 'top',
                        fontSize: 56
                    },
                    itemStyle: {
                        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        // emphasis: {
                        //     barBorderRadius: 50
                        // },
                        // normal: {
                        //     barBorderRadius:[50, 50, 50, 50],
                        // },
                        // color:'#00D4F9',
                        color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                            offset: 0, color: '#00D5FB' // 0% 处的颜色
                        }, {
                            offset: 1, color: '#4E2BD8' // 100% 处的颜色
                        }]),
                        
                    },
                },
                {
                    name: '',
                    type: 'bar',
                    data: y2,
                    barWidth : 100,//柱图宽度
                    color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                        offset: 0, color: '#C6AC26' // 0% 处的颜色
                    }, {
                        offset: 1, color: '#C6AC26' // 100% 处的颜色
                    }]),

                    emphasis: {
                        focus: "series",
                    },
                    label: {
                        show: true,
                        color: '#75B06E',
                        position: 'top',
                        fontSize: 56
                    },
                    itemStyle: {
                        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        // emphasis: {
                        //     barBorderRadius: 50
                        // },
                        // normal: {
                        //     barBorderRadius:[50, 50, 50, 50],
                        // },
                        // color:'#C6AC26',
                        color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                            offset: 0, color: '#75B06E' // 0% 处的颜色
                        }, {
                            offset: 1, color: '#B0FB86' // 100% 处的颜色
                        }]),
                        
                    },
                },
            ]
        };
        option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
    .charts-box, #container6 {
        width: 100%;
        height: 600px;
    }
</style>