<template>
    <div class="dataScreenContent">
        <div class="tjCls">
             <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon2Cls"></div>
                <div class="numCls">{{info.agriculturalMachineryTotal}}<span class="dwCls">辆</span></div>
                <div class="titleCls">农机总数</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon1Cls"></div>
                <div class="numCls">{{info.cooperativeTotal}}<span class="dwCls">个</span></div>
                <div class="titleCls">合作社</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon3Cls"></div>
                <div class="numCls">{{zxcl}}<span class="dwCls">辆</span></div>
                <div class="titleCls">当前在线</div>
            </div> 
        </div>
        <div class="mapCls">
            <baidu-map :center="center" v-if="isTrue" :zoom="zoom" @ready="handler" style="height:100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
            </baidu-map>

            <!--<div class="nongjiDetailCls" style="top:550px;left: 1400px;">
                <div>农机编号：陕08-09825</div>
                <div>车主电话：131****7923</div>
                <div>车主姓名：蒋宝荣</div>
                <div>榆阳区麻黄梁镇</div>
            </div>
            <div class="nongjicheCls" style="top:1200px;left: 400px;"></div>
            <div class="nongjicheCls" style="top:1300px;left: 600px;"></div>
            <div class="nongjicheCls" style="top:1400px;left: 760px;"></div>
            <div class="nongjicheCls" style="top:1000px;left: 900px;"></div>
            <div class="nongjicheCls" style="top:900px;left: 1400px;"></div>
            <div class="nongjicheCls" style="top:700px;left: 1640px;"></div>
            <div class="nongjicheCls" style="top:1200px;left: 1400px;"></div>
            <div class="nongjicheCls downCls" style="top:1700px;left: 1330px;"></div>
            <div class="nongjicheCls downCls" style="top:1600px;left: 1450px;"></div>
            <div class="nongjicheCls downCls" style="top:1500px;left: 1230px;"></div>-->
            <!-- <div class="nongjicheCls" :class="{downCls: item.onlineStatus==2}" v-for="(item,index) in datascreen12TownshipAgriculturalMachineryBOList" :key="item.id" :style="{top: item.mapY+'px',left: item.mapX+'px'}" @click="clickDetailCls(index+1)">
                <div class="nongjiDetailCls" v-show="isShow==index+1">
                    <div>农机编号：{{item.machineryNo}}</div>
                    <div>车主电话：{{item.tel}}</div>
                    <div>车主姓名：{{item.machineryOwnerName}}</div>
                    <div>{{item.villageGroupName}}</div>
                </div>
            </div> -->
        </div>
        <div class="animate__animated animate__fadeInRight">
            <div class="dsBox3">
                <div class="yzcyCls" style="width:5920px">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="yzcysuifyBCls">
                        <div class="vTitleCls">共享农机实时状态信息</div>
                        <div class="updateDataTimeCls">数据更新于{{this.today}}</div>
                    </div>
                    <div class="dataTblCls headCls">
                        <div class="dataTblHeadCls">农机车辆号</div>
                        <div class="dataTblHeadCls">农机型号</div>
                        <div class="dataTblHeadCls">农机类型</div>
                        <div class="dataTblHeadCls">车主</div>
                        <div class="dataTblHeadCls">联系方式</div>
                        <div class="dataTblHeadCls">所属合作社</div>
                        <div class="dataTblHeadCls">最新上线时间</div>
                        <div class="dataTblHeadCls">当前位置</div>
                        <div class="dataTblHeadCls">在线状态</div>
                    </div>
                    <!-- <marquee style=" height: 800px;overflow: hidden;" direction="up" behavior="scroll" scrollamount="5"> -->

                    <list-scroll style="height: 900px;" :speed="1" v-if="isDkShow">
                        <div class="list">
                            <div v-for="(item, i) in potatoData" :key="i" class="dataTblCls bodyCls">
                                <div class="by1">{{item.agriculturalCode}}</div>
                                <div class="by1">{{item.machineryModel}}</div>
                                <div class="by1">{{item.machineryType}}</div>
                                <div class="by1">{{item.machineryOwnerName}}</div>
                                <div class="by1">{{item.tel}}</div>
                                <div class="by1">{{item.subjectionEnterprise}}</div>
                                <div class="by1">{{item.latestOnlineTime ? item.latestOnlineTime : '--'}}</div>
                                <div class="by1">{{item.address}}</div>
                                <div class="status1" v-if="item.deviceStatus == '1'">
                                    <span class="yuanquanCls"></span>
                                    <span>在线</span>
                                </div>
                                <div class="status2" v-else>
                                    <span class="yuanquanCls"></span>
                                    <span>离线</span>
                                </div>
            
                            </div>
                        </div>
                    </list-scroll>
                    
                    <!-- <div id="gxnj12yyp" style="height: 900px; overflow: hidden;">
                        <div id="gxnj12yyp1">
                            <div v-for="(item, i) in potatoData" :key="i" class="dataTblCls bodyCls">
                                <div class="by1">{{item.agriculturalCode}}</div>
                                <div class="by1">{{item.machineryModel}}</div>
                                <div class="by1">{{item.machineryType}}</div>
                                <div class="by1">{{item.machineryOwnerName}}</div>
                                <div class="by1">{{item.tel}}</div>
                                <div class="by1">{{item.subjectionEnterprise}}</div>
                                <div class="by1">{{item.latestOnlineTime ? item.latestOnlineTime : '--'}}</div>
                                <div class="by1">{{item.address}}</div>
                                <div class="status1" v-if="item.deviceStatus == '1'">
                                    <span class="yuanquanCls"></span>
                                    <span>在线</span>
                                </div>
                                <div class="status2" v-else>
                                    <span class="yuanquanCls"></span>
                                    <span>离线</span>
                                </div>
            
                            </div>
                        </div>
                        <div v-if="isYes">
                            <div v-for="(item, i) in potatoData" :key="i" class="dataTblCls bodyCls">
                                <div class="by1">{{item.agriculturalCode}}</div>
                                <div class="by1">{{item.machineryModel}}</div>
                                <div class="by1">{{item.machineryType}}</div>
                                <div class="by1">{{item.machineryOwnerName}}</div>
                                <div class="by1">{{item.tel}}</div>
                                <div class="by1">{{item.subjectionEnterprise}}</div>
                                <div class="by1">{{item.latestOnlineTime ? item.latestOnlineTime : '--'}}</div>
                                <div class="by1">{{item.address}}</div>
                                <div class="status1" v-if="item.deviceStatus == '1'">
                                    <span class="yuanquanCls"></span>
                                    <span>在线</span>
                                </div>
                                <div class="status2" v-else>
                                    <span class="yuanquanCls"></span>
                                    <span>离线</span>
                                </div>
            
                            </div>
                        </div>
                    </div> -->
                        
                        <!-- <div class="dataTblCls bodyCls">
                            <div class="">S912000063</div>
                            <div class="">东方红LX1604</div>
                            <div class="">拖拉机</div>
                            <div class="">江小龙</div>
                            <div class="">135****6612</div>
                            <div class="">龙翔农机专业合作社</div>
                            <div class="">2022-09-11 12:21:11</div>
                            <div class="">麻黄梁镇S12榆佳高速</div>
                            <div class="status2"><span class="yuanquanCls"></span>离线</div>
                        </div>
                        <div class="dataTblCls bodyCls">
                            <div class="">S912000064</div>
                            <div class="">东方红LX1604</div>
                            <div class="">拖拉机</div>
                            <div class="">张江江</div>
                            <div class="">138****9102</div>
                            <div class="">江江农机专业合作社</div>
                            <div class="">2022-11-11 15:55:10</div>
                            <div class="">麻黄梁镇S12榆佳高速</div>
                            <div class="status3"><span class="yuanquanCls"></span>正在作业</div>
                        </div>
                        <div class="dataTblCls bodyCls">
                            <div class="">S912000065</div>
                            <div class="">东方红LX1604S</div>
                            <div class="">拖拉机</div>
                            <div class="">谢启龙</div>
                            <div class="">138****8176</div>
                            <div class="">水草农机专业合作社</div>
                            <div class="">2022-10-11 19:15:10</div>
                            <div class="">麻黄梁镇S12榆佳高速</div>
                            <div class="status1"><span class="yuanquanCls"></span>在线</div>
                        </div>
                        <div class="dataTblCls bodyCls">
                            <div class="">S912000066</div>
                            <div class="">东方红LX1604S</div>
                            <div class="">拖拉机</div>
                            <div class="">蒋宝荣</div>
                            <div class="">138****8210</div>
                            <div class="">富财农机专业合作社</div>
                            <div class="">2022-10-12 22:33:17</div>
                            <div class="">麻黄梁镇S12榆佳高速</div>
                            <div class="status1"><span class="yuanquanCls"></span>在线</div>
                        </div>
                        <div class="dataTblCls bodyCls">
                            <div class="">S912000067</div>
                            <div class="">东方红LX1604</div>
                            <div class="">拖拉机</div>
                            <div class="">苏贵宝</div>
                            <div class="">138****2541</div>
                            <div class="">双林农机专业合作社</div>
                            <div class="">2022-11-12 11:48:17</div>
                            <div class="">麻黄梁镇S12榆佳高速</div>
                            <div class="status1"><span class="yuanquanCls"></span>在线</div>
                        </div> -->
                    <!-- </marquee> -->
                </div>

                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="yzcysuifyBCls">
                        <div class="vTitleCls" style="width: 1600px;">全区参与社会化服务的农机统计（2023年度）</div>
                    </div>
                    <!-- <div class="shujutongjiBoxCls linshiTmp1Cls"></div> -->
                    <div class="shujutongjiBoxCls linshiTmp1ClsEcharts">
                        <highecharts1 ref="njType"></highecharts1>
                    </div>
                </div>

                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="yzcysuifyBCls">
                        <div class="vTitleCls" style="width: 1650px;">全区农机社会化服务作业类型统计（2023年度）</div>
                    </div>
                    <!-- <div class="shujutongjiBoxCls linshiTmp2Cls"></div> -->
                    <div class="shujutongjiBoxCls linshiTmp2ClsEcharts">
                        <div class="fs55 cfff mb40 ml20">作业面积/亩</div>
                        <highecharts2 ref="areaType"></highecharts2>
                    </div>
                </div>

                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="yzcysuifyBCls">
                        <div class="vTitleCls" style="width: 1735px;">全区各乡镇农机社会化服务面积统计（2023年度）</div>
                    </div>
                    <div style="display:flex;align-items:center;justify-content:end;">
                        <div style="font-size:74px;">{{northListName?'南部乡镇':'北部乡镇'}}</div>
                        <img @click="checkList" style="display:block;height:80px;margin-left:50px;cursor:pointer;" src="../../../assets/images/dataScreen/jt.png" />                            
                    </div>
                    <!-- <div class="shujutongjiBoxCls linshiTmp3Cls"></div> -->
                    <div class="shujutongjiBoxCls linshiTmp3ClsEcharts">
                        <div class="fs55 cfff mb40 ml20">作业面积/亩</div>
                        <highecharts3 ref="xzArea"></highecharts3>
                    </div>
                    
                </div>
            </div>

            <div class="yzcyBCls">
                <div class="ysStCls"></div>
                <div class="zxStCls"></div>
                <div class="vTitleCls">共享农机订单大厅</div>
                <div class="vgxnjTableCls vgxnjTableHeaderCls">
                    <div>所在乡镇</div>
                    <div>具体地址</div>
                    <div>作业类型</div>
                    <div>作业亩数</div>
                    <div>预约时间</div>
                    <div>订单状态</div>
                </div>

                <list-scroll style="height: 2400px; clear: both" :speed="1" v-if="isDkShow1">
                        <div class="list">
                            <div v-for="(item, i) in datascreen12ShareMachineryOrderStatisticsBOList" :key="i" class="vgxnjTableCls vgxnjTableRowCls">
                                <div class="by1">{{item.townshipName}}</div>
                                <div class="by1">{{item.villageTractName}}</div>
                                <div class="by1">{{item.jobContent}}</div>
                                <div class="by1">{{item.jobArea}}</div>
                                <div class="by1">{{item.appointmentTime}}</div>
                                <div v-if="item.orderStatus+'' == '1'" class="status1Cls">
                                    <span >预约</span>
                                </div>
                                <div v-if="item.orderStatus+'' == '2'">
                                    <span style="color: #A4DF11;">已完成</span>
                                </div>
                            </div>
                        </div>
                    </list-scroll>
                <!-- <div id="njdd12yyp" style="height: 2400px; overflow: hidden; clear: both">
                    <div id="njdd12yyp1">
                        <div v-for="(item, i) in datascreen12ShareMachineryOrderStatisticsBOList" :key="i" class="vgxnjTableCls vgxnjTableRowCls">
                            <div class="by1">{{item.townshipName}}</div>
                            <div class="by1">{{item.villageTractName}}</div>
                            <div class="by1">{{item.jobContent}}</div>
                            <div class="by1">{{item.jobArea}}</div>
                            <div class="by1">{{item.appointmentTime}}</div>
                            <div v-if="item.orderStatus+'' == '1'" class="status1Cls">
                                <span >预约</span>
                            </div>
                            <div v-if="item.orderStatus+'' == '2'">
                                <span style="color: #A4DF11;">已完成</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="isYes2">
                        <div v-for="(item, i) in datascreen12ShareMachineryOrderStatisticsBOList" :key="i" class="vgxnjTableCls vgxnjTableRowCls">
                            <div class="by1">{{item.townshipName}}</div>
                            <div class="by1">{{item.villageTractName}}</div>
                            <div class="by1">{{item.jobContent}}</div>
                            <div class="by1">{{item.jobArea}}</div>
                            <div class="by1">{{item.appointmentTime}}</div>
                            <div v-if="item.orderStatus+'' == '1'" class="status1Cls">
                                <span >预约</span>
                            </div>
                            <div v-if="item.orderStatus+'' == '2'">
                                <span style="color: #A4DF11;">已完成</span>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <marquee style=" height: 2400px;overflow: hidden;" direction="up" behavior="scroll" scrollamount="5"> -->
                    
                   
                <!-- </marquee> -->
            </div>
        </div>
        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">


                    </div>
                    <div class="video-centent">
                        <div id="video111" :attribute="environmentalAttribute2" flv="" />
                        <div class="video-title">{{ vedioTitle }}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
    </div>


</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import highecharts3 from '../highecharts/dataScreen12/highecharts3.vue'
import highecharts2 from '../highecharts/dataScreen12/highecharts2.vue'
import highecharts1 from '../highecharts/dataScreen12/highecharts1.vue'
import ListScroll from "../../../components/listScroll.vue";
import 'animate.css';
export default {
    name: '',
    components: {
        EZUIKitJs,
        highecharts2,
        highecharts3,
        highecharts1,
        ListScroll
    },
    data() {
        return {
            info: {},
            datascreen12TownshipAgriculturalMachineryBOList: [], //乡镇农机基本信息
            datascreen12MachineryTypeList: [], //农机类型统计
            datascreen12AreaOfOperationTypeStatisticsBOList: [], //作业类型面积
            datascreen12SouthTownshipOperationAreaStatisticsBOList: [], //乡镇作业面积统计-南部
            datascreen12ShareMachineryOrderStatisticsBOList: [], //共享农机大厅
            timeNum: 0,
            potatoData: [],
            environmentalAttribute: {
                width: 924,
                height: 710
            },
            townList: [],
            today: null,
            zxcl: 0,
            isYes: false,
            domHeight: 0,
            isYes2: false,
            domHeight2: 0,
            show: false,
            vedioTitle: "",
            labels: [],
            pointList: [],
            player: null,
            isTrue: false,
            isShow: 1,
            northListName: true,
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            timer1: null,
            timer2: null,
            isDkShow: false,
            isDkShow1: false
        }
    },

    mounted() {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        this.today = year + '/' + month + '/' + strDate
        this.getInfo();
        this.doPlay("");
        var timer = setInterval(() => {
            ++this.timeNum
            if (this.timeNum > 4) {
                clearInterval(timer)
            }
        }, 50);
        this.gps();
        setInterval(() => {
            this.gps();
        },60000)
    },
    methods: {
         handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            
            for(let i = 0; i < this.potatoData.length; i++) {
                var myIcon = new BMap.Icon(require(this.potatoData[i].deviceStatus == 1 ? "../../../assets/images/dataScreen/gxnj/online.png" : "../../../assets/images/dataScreen/gxnj/outline.png"), new BMap.Size(185, 153), {   
                    // 指定定位位置。  
                    // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                    // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                    // 图标中央下端的尖角位置。   
                    anchor: new BMap.Size(10, 25),   
                    // 设置图片偏移。  
                    // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                    // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                    // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
                });  
                // if(this.potatoData[i].latitude > 0 && this.potatoData[i].longitude > 0) {
                var point = new BMap.Point(this.potatoData[i].longitude, this.potatoData[i].latitude);
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   

                var content = "<div style='color: #00F6FF; margin-bottom: 20px; display: flex; align-items: center;'><img style='width: 60px; margin-right: 20px' src='http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-13/4c0cc92a825643e3a119da7b03137bf39c744086a76817ca24f9a345b1d6301f.png' /><span>农机编号："+ this.potatoData[i].agriculturalCode+"</span></div>"+
                              "<div style='display: flex; margin-bottom: 20px; align-items: center;'><img style='width: 60px; margin-right: 20px' src='http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-13/0be6a1c0d7ec4fdab0f83b82f71d3d9636c55ca1b1a525821a90de4198cbbcfb.png' /><span>车主电话："+ this.potatoData[i].tel+"</span></div>"+
                              "<div style='display: flex; margin-bottom: 20px; align-items: center;'><img style='width: 60px; margin-right: 20px' src='http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-13/0be6a1c0d7ec4fdab0f83b82f71d3d9636c55ca1b1a525821a90de4198cbbcfb.png' /><span>车主姓名："+ this.potatoData[i].machineryOwnerName+"</span></div>"+
                              "<div style='display: flex; align-items: center;'><img style='width: 60px; margin-right: 20px' src='http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-13/ca7df1e9fdb14883891612ced0e747e7f54a7d1d2454aa3a9286ed8faf0efca8.png' /><span>"+ this.potatoData[i].subjectionEnterprise+"</span></div>"
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(60, -440)
                })  
                this.pointList.push(marker);
                this.labels.push(label);
                map.addOverlay(label);
                this.clickBox(this.potatoData[i], i);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    display: 'none',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    padding: '30px 35px 120px',
                    backgroundSize: '100% 100%'
                })
                // var geoc = new BMap.Geocoder();
                // geoc.getLocation(point, function(rs){
                //     console.log(rs)
                //     this.potatoData[i].addr = rs.address;
                // })
                // this.potatoData[i].addr = 
                // }
            }

            for(let i = 0; i < this.townList.length; i++) {
                // if(this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) {
                var point = new BMap.Point(this.townList[i].mapX, this.townList[i].mapY);   
                var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.townList[i].townshipName + '</div><div>'+this.townList[i].yuliu1 +'</div>'+'<div>'+this.townList[i].yuliu2+'</div>'+'<div>'+this.townList[i].yuliu3+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-200, -368)
                })  
                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: (this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")' : '',
                    backgroundColor: 'transparent',
                    height: '348px',
                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
                
                // }
            }
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            map.enableScrollWheelZoom(true);
        },
        clickBox(row, index) {
          var that = this;
          this.pointList[index].addEventListener('click', function(){
              for(let j = 0; j < that.labels.length; j++) {
                  if(j == index) {
                      that.labels[j].setStyle({
                        display: 'block'
                    })
                  } else {
                      that.labels[j].setStyle({
                        display: 'none'
                    })
                  }
              }
              

          })  
        },
        gps(){
            this.qa.iotDevGpsqueryForList({}).then(res => {
                var count = 0;
                var stime = null;
                for(let i = 0; i < res.data.length; i++) {
                    var point = new BMap.Point(res.data[i].longitude, res.data[i].latitude);
                    var geoc = new BMap.Geocoder();
                    var that = this;
                    geoc.getLocation(point, function(rs){
                        res.data[i].address = rs.address ? rs.address : '--';
                        that.potatoData.push(res.data[i]);
                        setTimeout(() => {
                            that.isDkShow = true;
                        },5000)
                        that.$nextTick(() => {
                            count++;
                            
                        })
                        
                    })
                }
                // this.potatoData = res.data;

                // console.log(this.potatoData)
                
                stime = setInterval(() => { 
                    if(count == res.data.length) {
                        this.isTrue = true;
                        this.autoplay();
                        clearInterval(stime)
                    }
                    
                },100)
            })
            this.qa.queryCountByOnlineMachineryTotal({}).then(res => {
                this.zxcl = res.data;
            })
        },
        autoplay(){
            var dom = document.getElementById("gxnj12yyp");
            var dom1 = document.getElementById("gxnj12yyp1");
            var that = this;
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes = true;
                if(this.timer1) {
                    clearInterval(this.timer1);
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer1 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight = 0;
                        dom.scrollTop = that.domHeight;
         
                    } else {
                        that.domHeight++;
                        dom.scrollTop = that.domHeight;
         
                    }
                }, 20)
            }
        },
        autoplay2(){
            var dom = document.getElementById("njdd12yyp");
            var dom1 = document.getElementById("njdd12yyp1");
            var that = this;
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes2 = true;
                if(this.timer2) {
                    clearInterval(this.timer2);
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer2 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight2 = 0;
                        dom.scrollTop = that.domHeight2;
                    } else {
                        that.domHeight2++;
                        dom.scrollTop = that.domHeight2;
                    }
                }, 20)
            }
        },
        getClickInfo (e) {
            this.center.lng = e.point.lng
            this.center.lat = e.point.lat
        },
        clickDetailCls (num) {
            if (this.isShow == num) {
                this.isShow = 0;
            } else {
                this.isShow = num;                
            }
        },
        checkList () {
            this.northListName = !this.northListName; // true南部，false北部
            let xData = [];
            let yData = [];
            if (this.northListName) {
                for (var i=0; i<this.info.datascreen12SouthTownshipOperationAreaStatisticsBOList.length; i++) {
                    xData.push(this.info.datascreen12SouthTownshipOperationAreaStatisticsBOList[i].townshipName);
                    yData.push(this.info.datascreen12SouthTownshipOperationAreaStatisticsBOList[i].operationArea);
                }
                this.$refs.xzArea.xData = xData
                this.$refs.xzArea.yData = yData
                this.$refs.xzArea.actualCount()
            } else {
                for (var i=0; i<this.info.datascreen12NorthTownshipOperationAreaStatisticsBOList.length; i++) {
                    xData.push(this.info.datascreen12NorthTownshipOperationAreaStatisticsBOList[i].townshipName);
                    yData.push(this.info.datascreen12NorthTownshipOperationAreaStatisticsBOList[i].operationArea);
                }
                this.$refs.xzArea.xData = xData
                this.$refs.xzArea.yData = yData
                this.$refs.xzArea.actualCount()
            }
        },
        getInfo() {
            this.qa.queryDatascreen12ById({}).then(res => {
                if(res.respCode == '0000') {
                    this.info = res.data
                    if(res.data.datascreen12TownshipAgriculturalMachineryBOList != null) {
                        this.datascreen12TownshipAgriculturalMachineryBOList = res.data.datascreen12TownshipAgriculturalMachineryBOList
                    }
                    if(res.data.datascreen12AgriculturalStatisticsBOS != null) {
                        this.datascreen12MachineryTypeList = res.data.datascreen12AgriculturalStatisticsBOS;
                        var arr = [];
                        // var sum = 0;
                        // for(var i=0; i<this.datascreen12MachineryTypeList.length; i++) {
                        //     sum += this.datascreen12MachineryTypeList[i].machineryQuantity;
                        // }
                        for(var i=0; i<this.datascreen12MachineryTypeList.length; i++) {
                            var obj = {
                                value : this.datascreen12MachineryTypeList[i].machineryNum,
                                name: this.datascreen12MachineryTypeList[i].machineryType + ' ' + this.datascreen12MachineryTypeList[i].machineryNum + '台',
                            }
                            arr.push(obj)
                        }
                        console.log(arr)
                        this.$nextTick(function () {
                            this.$refs.njType.echartsData = arr
                            this.$refs.njType.actualCount()
                        })
                    }
                    if(res.data.datascreen12AreaOfOperationTypeStatisticsBOList != null) {
                        this.datascreen12AreaOfOperationTypeStatisticsBOList = res.data.datascreen12AreaOfOperationTypeStatisticsBOList;
                        var xData=[];
                        var yData=[];
                        for (var i=0; i<res.data.datascreen12AreaOfOperationTypeStatisticsBOList.length; i++) {
                            xData.push(res.data.datascreen12AreaOfOperationTypeStatisticsBOList[i].operationTypeName);
                            yData.push(res.data.datascreen12AreaOfOperationTypeStatisticsBOList[i].operationArea);
                        }
                        this.$nextTick(function () {
                            this.$refs.areaType.xData = xData
                            this.$refs.areaType.yData = yData
                            this.$refs.areaType.actualCount()
                        })
                    }
                    if(res.data.datascreen12SouthTownshipOperationAreaStatisticsBOList != null) {
                        this.datascreen12SouthTownshipOperationAreaStatisticsBOList = res.data.datascreen12SouthTownshipOperationAreaStatisticsBOList;
                        var xData1=[];
                        var yData1=[];
                        for (var i=0; i<this.datascreen12SouthTownshipOperationAreaStatisticsBOList.length; i++) {
                            xData1.push(this.datascreen12SouthTownshipOperationAreaStatisticsBOList[i].townshipName);
                            yData1.push(this.datascreen12SouthTownshipOperationAreaStatisticsBOList[i].operationArea);
                        }
                        this.$nextTick(function () {
                            this.$refs.xzArea.xData = xData1
                            this.$refs.xzArea.yData = yData1
                            this.$refs.xzArea.actualCount()
                        })
                    }

                    if(res.data.datascreen12ShareMachineryOrderStatisticsBOList != null) {
                        this.datascreen12ShareMachineryOrderStatisticsBOList = res.data.datascreen12ShareMachineryOrderStatisticsBOList;
                        this.isDkShow1 = true;
                    }
                    this.$nextTick(() => {
                        this.autoplay2();
                    })
                }
                console.log(res.respCode)
            })

            this.qa.datascreen12TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
            })
        },
        goDataScreen2() {
            this.$emit('tabsTypeClick', '2');
        },
        doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },
        transitionFun(_url, _title) {
            this.doPlay(_url);
            this.show = !this.show;
            this.vedioTitle = _title;
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
    transform: translateX(100px);
    opacity: 0;
}

.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videdo-box {
    display: flex;
    align-items: center;
}

.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}

.video-title {
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}

.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;
}

.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.dataScreenContent {
    color: #ccc;
    font-size: 100px;
}

div.dsBgbox {
    background-image: url("../../../assets/images/dataScreen/ds1_bg_box.png");
    width: 809px;
    height: 320px;
    background-size: 100% 100%;
    position: relative;
    font-size: 101px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #23D1FF;
}

div.dsBgbox>div.dsIcon1Cls {
    background-image: url("../../../assets/images/dataScreen/ds_icon3.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.dsIcon2Cls {
    background-image: url("../../../assets/images/dataScreen/fse2.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.dsIcon3Cls {
    background-image: url("../../../assets/images/dataScreen/fse1.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.numCls {
    width: 100%;
    margin-left: 260px;
    padding-top: 40px;
}

div.dsBgbox>div.titleCls {
    width: 100%;
    height: 228px;
    margin-left: 270px;
    font-size: 51px;
    color: #FFFFFF;
}

div.numCls>span.dwCls {
    font-size: 40px;
}

div.tjCls,
div.mapCls {
    float: left;
}

div.mapCls>div.nongjicheCls {
    background-image: url("../../../assets/images/dataScreen/nongjiche_s1.png");
    width: 193px;
    height: 160px;
    display: flex;
    align-items: self-end;
    position: absolute;
    background-size: cover;
}

div.nongjiDetailCls {
    background-image: url("../../../assets/images/dataScreen/nongjidetail.png");
    width: 1201px;
    height: 841px;
    background-size: cover;
    position: absolute;
    z-index: 2;
    padding-left: 160px;
    padding-top: 30px;
}

div.nongjiDetailCls>div {
    font-size: 64px;
    height: 130px;
    line-height: 130px;
    background-size: 93px 93px;
    background-position: left center;
    background-repeat: no-repeat;
    text-indent: 120px;

}

div.nongjiDetailCls>div:nth-child(1) {
    background-image: url("../../../assets/images/dataScreen/nongjidetailicon1.png");

    color: #00F6FF;
}

div.nongjiDetailCls>div:nth-child(2) {

    background-image: url("../../../assets/images/dataScreen/nongjidetailicon2.png");
}

div.nongjiDetailCls>div:nth-child(3) {

    background-image: url("../../../assets/images/dataScreen/nongjidetailicon2.png");
}

div.nongjiDetailCls>div:nth-child(4) {
    background-image: url("../../../assets/images/dataScreen/nongjidetailicon4.png");

}

div.mapCls>div.nongjicheCls.downCls {
    background-image: url("../../../assets/images/dataScreen/nongjiche_s2.png");
}

div.tjCls>div:nth-child(n+1) {
    margin-top: 135px;
    position: relative;
    z-index: 999;
}

div.mapCls {
    margin-top: 0;
    width: 3887px;
    height: 3010px;
    z-index: 100;
    margin-left: -800px;
    position: relative;
}

div.mapCls>div.yangCls {
    position: absolute;
    top: 1000px;
    left: 1800px;
    background-image: url("../../../assets/images/dataScreen/yang1.png");
    width: 1341px;
    height: 618px;
    padding-top: 65px;
    font-weight: bolder;
    padding-left: 150px;

}

div.mapCls>div.yangCls>div:nth-child(1) {
    font-size: 75px;

}

div.mapCls>div.yangCls>div:nth-child(2) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 100px;
}

div.mapCls>div.yangCls>div:nth-child(3) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 20px;
}

div.dsBox3 {
    margin-left: 200px;
    float: left;
    width: 6096px;
    height: 3000px;
}

div.dsBox3>div.yzcyCls:nth-child(n+2) {
    margin-top: 50px;
}

div.dsBox3>div.yzcyCls {
    float: left;
    width: 1940px;
    height: 1481px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
    margin-left: 50px;
}

div.dsBox3>div.yzcyCls>div.qiyeTitleBoxCls {
    position: relative;
    font-weight: bolder;
    font-size: 75px;
    background-image: url("../../../assets/images/dataScreen/zhizhi.png");
    background-size: 1650px 71px;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
}

div.dsBox3>div.yzcyCls>div.qiyeTitleBoxCls>div.fnt {
    text-align: center !important;
}


div.ysStCls {
    background-image: url("../../../assets/images/dataScreen/ysSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    top: -8px;
    left: -8px;

}

div.zxStCls {
    background-image: url("../../../assets/images/dataScreen/zxSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    bottom: -8px;
    right: -8px;

}

div.yzcyCls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzcy2Cls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzTableHeaderCls {
    margin-top: 40px;
}

div.yzTableHeaderCls>div {
    float: left;
    color: #A4DF11;
    font-size: 59px;
}

div.yzTableHeaderCls>div:nth-child(1),
div.yzTableRowCls>div:nth-child(1) {
    width: 300px;
}

div.yzTableHeaderCls>div:nth-child(2),
div.yzTableRowCls>div:nth-child(2) {
    width: 250px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(3),
div.yzTableRowCls>div:nth-child(3) {
    width: 275px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(4),
div.yzTableRowCls>div:nth-child(4) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(5),
div.yzTableRowCls>div:nth-child(5) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(6),
div.yzTableRowCls>div:nth-child(6) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(7),
div.yzTableRowCls>div:nth-child(7) {
    width: 300px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(8),
div.yzTableRowCls>div:nth-child(8) {
    width: 275px;
    text-align: right;
}

div.yzTableRowCls>div {
    height: 120px;
    line-height: 120px;
    float: left;
    color: #00FB40;
    font-size: 59px;
}


div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls>div {
    float: left;
    color: #3de2d6;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(3),
div.bodyCls>div.rowCls>div:nth-child(3) {
    width: 300px;
    text-align: center;
}

div.yzcyBCls {
    position: relative;
    width: 3300px;
    height: 3010px;
    float: left;
    background: #0E1639;
    border: 5px solid #123171;
    padding: 85px;
}

div.yzcyBCls>div.vTitleCls {
    font-size: 74px;
    font-weight: bolder;
    height: 147px;
    line-height: 147px;
    background-image: url("../../../assets/images/dataScreen/malinshu-title.png");
    background-repeat: no-repeat;
    background-size: 1163px 147px;
    text-indent: 60px;

}

div.yzcysuifyBCls {
    height: 147px;
}

div.yzcysuifyBCls>div.vTitleCls {
    font-size: 74px;
    font-weight: bolder;
    width: 1163px;
    height: 147px;
    line-height: 147px;
    background-image: url("../../../assets/images/dataScreen/malinshu-title.png");
    background-repeat: no-repeat;
    background-size: 1163px 147px;
    text-indent: 60px;
    float: left;

}

div.yzcysuifyBCls>div.updateDataTimeCls {
    float: right;
    font-size: 59px;
    color: #47D6FF;
}

div.vBoxCls {
    width: 100%;
    height: 400px;
    margin-top: 60px;
}

div.vBoxCls>div.vconBoxCls {
    width: 995px;
    height: 800px;
    background: #0a284e;
    border: 5px solid #123354;
    float: left;
    margin-top: 60px;
    margin-right: 60px;
}

div.vBoxCls>div.vconBoxCls:nth-child(3n) {
    margin-right: 0px;
}

span.yuanquanCls {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 40px;
    margin-top: -10px;
}

div.dataTblCls.bodyCls>div.status1 {
    color: #00da3d;
}

div.dataTblCls.bodyCls>div.status2 {
    color: #B5B5B5;
}

div.dataTblCls.bodyCls>div.status3 {
    color: #f00;
}

div.dataTblCls.bodyCls>div.status1>span.yuanquanCls {
    background-color: #00da3d;
}

div.dataTblCls.bodyCls>div.status2>span.yuanquanCls {
    background-color: #B5B5B5;
}

div.dataTblCls.bodyCls>div.status3>span.yuanquanCls {
    background-color: #f00;
}

div.dataTblCls.headCls {
    height: 300px;
    line-height: 300px;
    color: #87b712;
}

div.dataTblCls.bodyCls {
    height: 120px;
    line-height: 120px;
    color: #00da3d;
}

div.dataTblCls>div {
    float: left;
    font-size: 59px;
}

div.dataTblCls>div:nth-child(1) {
    width: 600px;
    text-indent: 60px;
}

div.dataTblCls>div:nth-child(2) {
    width: 700px;
}

div.dataTblCls>div:nth-child(3) {
    width: 500px;
}

div.dataTblCls>div:nth-child(4) {
    width: 400px;
}

div.dataTblCls>div:nth-child(5) {
    width: 500px;
}

div.dataTblCls>div:nth-child(6) {
    width: 900px;
}

div.dataTblCls>div:nth-child(7) {
    width: 800px;
}

div.dataTblCls>div:nth-child(8) {
    width: 900px;
}

div.dataTblCls>div:nth-child(9) {
    width: 400px;
}

div.shujutongjiBoxCls {
    width: 100%;
    margin-top: 60px;
    height: 1100px;
}

div.linshiTmp1Cls {
    background-image: url("../../../assets/images/dataScreen/tmp1.png");
    background-size: 1671px 1015px;
    background-position: center;
    background-repeat: no-repeat;

}
.linshiTmp1ClsEcharts {
    width: 1671px ;
    height:  1015px;
}
div.linshiTmp2Cls {
    background-image: url("../../../assets/images/dataScreen/tmp2.png");
    background-size: 1671px 1015px;
    background-position: center;
    background-repeat: no-repeat;

}
.linshiTmp2ClsEcharts {
    width:1671px ;
    height: 1015px;
}
div.linshiTmp3Cls {
    background-image: url("../../../assets/images/dataScreen/tmp2.png");
    background-size: 1671px 1015px;
    background-position: center;
    background-repeat: no-repeat;

}
.linshiTmp3ClsEcharts {
    width:1671px ;
    height: 1015px;
}
div.vgxnjTableHeaderCls {
    margin-top: 90px;
    background: #112346;
    border: 5px solid #16284e;
    height: 164px;
    line-height: 164px;
}

div.vgxnjTableCls>div {
    font-size: 60px;
    float: left;
    color: #9dd50e;
}

div.vgxnjTableCls>div:nth-child(1) {
    width: 500px;
    text-indent: 80px;
}

div.vgxnjTableCls>div:nth-child(2) {
    width: 800px;
}

div.vgxnjTableCls>div:nth-child(3) {
    width: 600px;
}

div.vgxnjTableCls>div:nth-child(4) {
    width: 300px;
}

div.vgxnjTableCls>div:nth-child(5) {
    width: 600px;
}

div.vgxnjTableCls>div:nth-child(6) {
    width: 300px;
}

div.vgxnjTableRowCls{
    height: 200px;
    line-height: 200px;
}
div.vgxnjTableRowCls>div{

    color: #fff;
}
div.vgxnjTableRowCls>div.status1Cls{
    color: #00b4e9;
}
div.vgxnjTableRowCls:nth-child(2n){
    background-color: #0f1d3d;
}
</style>
