<template>
    <div class="box flex">
        <div class="map-box">
            <div class="map-left">
                <div :class="['map-left-item', 'animate__animated', timeNum >= 0? 'animate__bounceInDown': '']">
                    <div class="map-left-icon map-left-icon-1"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{smallObj.yearFeedingQuantity || 0}}<span class="dwCls" style="font-size: 40px">万</span></div>
                        <div class="fs50 cfff mt10">年饲养量（只）</div>
                    </div>
                </div>
                <div :class="['map-left-item', 'animate__animated', timeNum > 0?  'animate__bounceInDown': '']">
                    <div class="map-left-icon map-left-icon-2"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{smallObj.yearAnnualSales || 0}}<span class="dwCls" style="font-size: 40px">万</span></div>
                        <div class="fs50 cfff mt10">年存栏量（只）</div>
                    </div>
                </div>
                <div :class="['map-left-item', 'animate__animated', timeNum > 1? 'animate__bounceInDown': '']">
                    <div class="map-left-icon map-left-icon-3"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{smallObj.iotEquipmentCount || 0}}<span class="dwCls" style="font-size: 40px">万</span></div>
                        <div class="fs50 cfff mt10">年出栏量（只）</div>
                    </div>
                </div>
            </div>
            <div class="map-img">
                <baidu-map v-if="isShow" :center="center" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" :scroll-wheel-zoom='true'>
                </baidu-map>
                <!-- <div class="map-text-box animate__animated animate__fadeInUp">
                    <div class="map-text-box-title mb31">上河湖羊阿里云智慧养殖调度中心</div>
                    <div class="map-text-box-title-2 mt80 mb16" >饲养量：12.3万只</div>
                    <div class="map-text-box-title-2">地址：榆阳区牛家梁镇常乐堡村</div>
                </div> -->
            </div>
        </div>

        <div class="right">
            <div class="header-text animate__animated animate__zoomInDown">上河集团15万只榆阳湖羊阿里云智慧养殖调度中心</div> 
            <div class="header"></div>
            <div class="flex animate__animated animate__fadeInRight">
                <div class="content_left">
                    <div class="content_left_top item-box">
                        <div class="left_title">
                            <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon1.png" alt="">
                            <h3 class="fs61">全场养殖数据｜数字化养殖数据 <span class="fs46">(单位: 只)</span> </h3>
                            <div class="title-line"></div>
                        </div>
                        <div class="flex f_jc_a pt57">
                            <div class="ml100">
                                <div class="fs66 ta_c mb39">种公羊</div>
                                <div class="flex f_ai_c fs76">
                                    <span style="color: #FED400;">{{smallObj.allBreedMaleCount || 0}}</span>
                                    <span class="ml40 mr40">|</span>
                                    <span style="color: #24E4E6;">{{smallObj.iotBreedMaleCount || 0}}</span>
                                </div>
                            </div>
                            <div class="">
                                <div class="fs66 ta_c mb39">基础母羊</div>
                                <div class="flex f_ai_c fs76">
                                    <span style="color: #FED400;">{{smallObj.allBreedFemaleCount || 0}}</span>
                                    <span class="ml40 mr40">|</span>
                                    <span style="color: #24E4E6;">{{smallObj.iotBreedFemaleCount || 0}}</span>
                                </div>
                            </div>
                            <div class="">
                                <div class="fs66 ta_c mb39">育成羊</div>
                                <div class="flex f_ai_c fs76">
                                    <span style="color: #FED400;">{{smallObj.allBredBreedCount || 0}}</span>
                                    <span class="ml40 mr40">|</span>
                                    <span style="color: #24E4E6;">{{smallObj.iotBredBreedCount || 0}}</span>
                                </div>
                            </div>
                            <div class="mr100">
                                <div class="fs66 ta_c mb39">哺乳羔羊</div>
                                <div class="flex f_ai_c fs76">
                                    <span style="color: #FED400;">{{smallObj.allLambBreedCount || 0}}</span>
                                    <span class="ml40 mr40">|</span>
                                    <span style="color: #24E4E6;">{{smallObj.iotLambBreedCount || 0}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="title-line-sub-box">
                                <div class="sheep-title fs54 ml95 mt58 mb55">
                                    <div>羊场基本信息</div> 
                                    <div class="title-line-sub mt18"></div>
                                </div>
                                <div class="flex ml95">
                                    <div class="flex mr50">
                                        <div class="sheep-m "></div>
                                        <div>
                                            <div class="">
                                               <span class="fs69">{{smallObj.sheepFarmBaseInfoTotalArea || 0}}</span> 
                                               <span class="fs46 cfff">平方米</span>
                                            </div>
                                            <div class="sheep-m-line mt12 mb12"></div>
                                            <div class="fs54">总面积</div>
                                        </div>
                                    </div>
                                    <div class="flex mr50">
                                        <div class="sheep-m "></div>
                                        <div>
                                            <div class="">
                                               <span class="fs69">{{smallObj.sheepFarmBaseInfoShedCount || 0}}</span> 
                                               <span class="fs46 cfff">栋</span>
                                            </div>
                                            <div class="sheep-m-line sheep-m-line-2 mt12 mb12"></div>
                                            <div class="fs54">羊舍</div>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="sheep-m "></div>
                                        <div>
                                            <div class="">
                                               <span class="fs69">{{smallObj.sheepFarmBaseInfoIotCount || 0}}</span> 
                                               <span class="fs46 cfff">个</span>
                                            </div>
                                            <div class="sheep-m-line sheep-m-line-3 mt12 mb12"></div>
                                            <div class="fs54">物联网设备</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sheep-line-s"></div>
                            <div class="title-line-sub-box">
                                <div class="sheep-title fs54 mt58 mb15" style="margin-left: 160px;">
                                    <div>羊子饲养品种</div> 
                                    <div class="title-line-sub mt18"></div>
                                </div>
                                <marquee
                                    class="marquee-list"
                                    direction="up"
                                    behavior="scroll"
                                    scrollamount="2"
                                >
                                <div class="flex fs46 mt5" style="color: #fff;" v-for="(item,index) in smallObj.datascreen2SpeciesBOList" :key="index">
                                    <div class="fs46" style="width: 500px;">{{item.speciesName}}：</div>
                                    <div style="color: #FED400;">{{item.speciesCount}}</div>
                                </div>
                 
                                </marquee>
                            </div>
                        </div>
                        <div class="box_j lt_"></div>
                        <div class="box_j lb_"></div>
                        <div class="box_j rt_"></div>
                        <div class="box_j rb_"></div>
                    </div>

                    <div class="content_left_center flex f_jc_b">
                        <div class="content_left_center_left  item-box">
                            <div class="left_title">
                                <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon2.png" alt="">
                                <div class="flex f_jc_b w100">
                                    <h3 class="fs61">人员比例</h3>
                                    <div class="fs46 mr78" style="color: #FED400;">
                                        <span>人效：</span>
                                        <span>1/{{renxiao}}</span>
                                    </div>
                                </div>
                                <div class="title-line"></div>
                            </div>
                            <div class="ta_c mt30 echarts1">
                                <div class="echarts-num">{{renCount}}</div>
                                <!-- <img class="echarts1" src="../../../assets/images/dataScreen/sheep/echarts1.png" alt=""> -->
                                <Highecharts1 ref="renyuan"></Highecharts1>
                            </div>
                            <div class="box_j lt_"></div>
                            <div class="box_j lb_"></div>
                            <div class="box_j rt_"></div>
                            <div class="box_j rb_"></div>
                        </div>
                        <div class="content_left_center_right  item-box">
                            <div class="left_title">
                                <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon3.png" alt="">
                                <div class="flex f_jc_b w100">
                                    <h3 class="fs61">
                                       <span>销售记录</span>
                                       <span class="fs46"> (单位：只)</span>
                                    </h3>
                                    <div class="fs46 mr78 flex f_ai_c">
                                        <div style="background: #00D6FB;" class="line-jl mr20"></div>
                                        <div>育肥</div>
                                        <div style="background: #FED400;" class="line-jl mr20 ml38"></div>
                                        <div>扩繁</div>
                                    </div>
                                </div>
                                <div class="title-line"></div>
                            </div>
                            <div class="jl-box fs46">
                                <span>扩繁出厂均价：{{smallObj.sellMultiplicationOutPrice || 0}}元/只</span>
                                <span class="ml20">育肥出厂均价：{{smallObj.sellFattenOutPrice || 0}}元/只</span>
                            </div>
                            <div class="echarts2">
                                <Highecharts2 ref="xiaoshou"></Highecharts2>
                            </div>
                            <!-- <img class="echarts2" src="../../../assets/images/dataScreen/sheep/echarts2.png" alt=""> -->
                            <div class="box_j lt_"></div>
                            <div class="box_j lb_"></div>
                            <div class="box_j rt_"></div>
                            <div class="box_j rb_"></div>
                        </div>
                    </div>
                     <div class="content_left_bottom item-box" style="margin-top: 110px;">
                        <div class="left_title">
                            <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon4.png" alt="">
                            <div class="flex f_jc_b w100">
                                <h3 class="fs61">
                                    <span>2023年度主要饲草消耗量统计</span>
                                    <span class="fs46"> (单位：千克)</span>
                                </h3>
                            </div>
                            <div class="title-line"></div>
                        </div>
                        <div class="flex">
                            <div class="left_bottom_left fs46">
                                <div class="ta_r mb80 mt40">(消耗量统计)</div>
                                <div class="flex f_jc_b f_ai_c mb80" v-for="(item,index) in smallObj.datascreen2FeedBOList" :key="index">
                                    <div class="flex f_ai_c">
                                        <div class="ta_r mr50" style="width: 269px;">{{item.feedName}}</div>
                                        <div class="heng" :style="{width: (item.feedConsumption / lengthAll) * 1600+'px'}"></div>
                                    </div>
                                    <div>{{item.feedConsumption}}</div>
                                </div>
                     
                                
                            </div>
                            <div class="xh-line mt80 ml80 mr80"></div>
                            <div class="left_bottom_left fs46 flex1 mr78">
                                <div class="ta_r mb70 mt40">(剩余库存量)</div>
                                <div class="flex f_ai_c f_jc_b mb40" v-for="(item,index) in smallObj.datascreen2FeedBOList" :key="index">
                                    <el-progress type="circle" :show-text="false" :status="index == 0 ? 'status' : (index == 1 ? 'warning' : 'exception')" :percentage="item.feedStore" stroke-width="16" width="100"></el-progress>
                                    <!-- <img class="icon-left-y" src="../../../assets/images/dataScreen/sheep/y1.png" alt=""> -->
                                    <span>{{item.feedStore}}%</span>
                                </div>
                             
                            </div>
                        </div>
                        <div class="box_j lt_"></div>
                        <div class="box_j lb_"></div>
                        <div class="box_j rt_"></div>
                        <div class="box_j rb_"></div>
                    </div>
                </div>
                <div class="content_center item-box ml90">
                    <div class="fs64 cfff ta_c flex f_jc_c tabs_btn_box">
                        <div class="tabs_btn " @click="tabsClick">
                           <div>基地调度中心</div> 
                           <div v-if="tabsType == '0'" class="tabs_line"></div>
                        </div>
                        <div class="ml30 mr30">|</div>
                        <div class="tabs_btn"  @click="tabsClick">
                            <div>基地实时画面</div>
                            <div v-if="tabsType == '1'" class="tabs_line"></div>
                        </div>
                    </div>
                    <video v-if="tabsType == '0'" style="width: 100%; height: 100%; object-fit: fill" muted src="../../../assets/images/dataScreen/sheep/data.mp4" autoplay loop></video>
                    <div v-else>
                        <div class="left_title"></div>
                        <div class="flex f_jc_a flex_wrap mt50" v-if="isTrue">
                            <div v-for="(item, i) in smallObj.deviceEnterpriseRelationBOS" :key="i">
                                <EZUIKitJs class="mb100" v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="environmentalAttribute" :flv="item.deviceUrl" />
                                <EZUIKitJs2 class="mb100" v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="environmentalAttribute" :flv="item.deviceUrl"/>
                            </div>
                            <!-- <EZUIKitJs class="mb100" msg="video1" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio1Url" /> -->
                            <!-- <EZUIKitJs class="mb100" msg="video2" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio2Url" />
                            <EZUIKitJs class="mb100" msg="video3" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio3Url" />
                            <EZUIKitJs class="mb100" msg="video4" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio4Url" />
                            <EZUIKitJs class="mb100" msg="video5" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio5Url" />
                            <EZUIKitJs class="mb100" msg="video6" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio6Url" />
                            <EZUIKitJs class="mb100" msg="video7" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio7Url" />
                            <EZUIKitJs class="mb100" msg="video8" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio8Url" />
                            <EZUIKitJs class="mb100" msg="video9" :attribute="environmentalAttribute" :flv="smallObj.jidiVedio9Url" /> -->
                        </div>
                    </div>
                    <div class="box_j lt_"></div>
                    <div class="box_j lb_"></div>
                    <div class="box_j rt_"></div>
                    <div class="box_j rb_"></div>
                </div>
                <div class="content_right item-box ml90">
                    <div class="left_title">
                        <img class="icon-left" src="../../../assets/images/dataScreen/sheep/icon4.png" alt="">
                        <div class="flex f_jc_b w100">
                            <h3 class="fs61">
                                <span>智能羊舍IOT监测数据</span>
                            </h3>
                        </div>
                        <div class="title-line"></div>
                    </div>
                    <div class="flex f_jc_a">
                        <div class="ta_c">
                            <div class="meteorological-icon meteorological-icon-1 mb20"></div>
                            <div>
                                <span class="fs77" style="color: #1CFA1B;">{{qxList.temperature1 || 0}}</span>
                                <span class="fs50">℃</span>
                            </div>
                        </div>
                        <div class="ta_c">
                            <div class="meteorological-icon meteorological-icon-2 mb20"></div>
                            <div>
                                <span class="fs77" style="color: #1CFA1B;">{{qxList.humidity1 || 0}}</span>
                                <span class="fs50">%RH</span>
                            </div>
                        </div>
                        <div class="ta_c">
                            <div class="meteorological-icon  meteorological-icon-3 mb20"></div>
                            <div>
                                <span class="fs77" style="color: #F96FB4;">{{qxList.co21 || 0}}</span>
                                <span class="fs50">ppm</span>
                            </div>
                        </div>
                        <div class="ta_c">
                            <div class="meteorological-icon meteorological-icon-4 mb20"></div>
                            <div>
                                <span class="fs77" style="color: #F96FB4;">{{qxList.h2s1 || 0}}</span>
                                <span class="fs50">ppm</span>
                            </div>
                        </div>
                        <div class="ta_c">
                            <div class="meteorological-icon meteorological-icon-5 mb20"></div>
                            <div>
                                <span class="fs77" style="color: #F96FB4;">{{qxList.nh31 || 0}}</span>
                                <span class="fs50">ppm</span>
                            </div>
                        </div>
                        <div class="ta_c">
                            <div class="meteorological-icon meteorological-icon-6 mb20"></div>
                            <div>
                                <span class="fs77" style="color: #3BE3E5;">{{qxList.lightIntensity || 0}}</span>
                                <span class="fs50">Lux</span>
                            </div>
                        </div>
                        <div class="ta_c">
                            <div class="meteorological-icon meteorological-icon-7 mb20"></div>
                            <div>
                                <span class="fs77" style="color: #3BE3E5;">{{qxList.windSpeed || 0}}</span>
                                <span class="fs50">m/s</span>
                            </div>
                        </div>
                        <div class="ta_c">
                            <div class="meteorological-icon meteorological-icon-8 mb20"></div>
                            <div>
                                <span class="fs77" style="color: #3BE3E5;">{{waterDeep || 0}}</span>
                                <span class="fs50">m</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex">
                        <div class="task_box">
                            <div class="sheep-title fs54 ml95 mt58 mb55">
                                <div>任务派遣统计 </div> 
                                <div class="title-line-sub mt18"></div>
                            </div>
                            <div class="flex flex_wrap ml90 w100">
                                <div class="task_item flex f_ai_c f_jc_b">
                                    <div class="flex f_ai_c fs51">
                                        <img class="task_icon mr20" src="../../../assets/images/dataScreen/sheep/task1.png" alt="">
                                        <div>防疫(件)</div>
                                    </div>
                                    <div class="fs77" style="color: #24E4E6;">{{smallObj.taskEpidemicPreventionCount || 0}}</div>
                                </div>
                                <div class="task_item flex f_ai_c f_jc_b">
                                    <div class="flex f_ai_c fs51">
                                        <img class="task_icon mr20" src="../../../assets/images/dataScreen/sheep/task2.png" alt="">
                                        <div>清粪(次)</div>
                                    </div>
                                    <div class="fs77" style="color: #1BFA1A;">{{smallObj.taskDefecationCount || 0}}</div>
                                </div>
                                <div class="task_item flex f_ai_c f_jc_b">
                                    <div class="flex f_ai_c fs51">
                                        <img class="task_icon mr20" src="../../../assets/images/dataScreen/sheep/task3.png" alt="">
                                        <div>消毒(次)</div>
                                    </div>
                                    <div class="fs77" style="color: #FED400;">{{smallObj.taskDisinfectCount || 0}}</div>
                                </div>
                                <div class="task_item flex f_ai_c f_jc_b">
                                    <div class="flex f_ai_c fs51">
                                        <img class="task_icon mr20" src="../../../assets/images/dataScreen/sheep/task4.png" alt="">
                                        <div>采购(次)</div>
                                    </div>
                                    <div class="fs77" style="color: #F96FB5;">{{smallObj.taskPurchaseCount || 0}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="task_line"></div>
                        <div class="task_box flex1">
                            <div class="sheep-title fs54 ml95 mt58 mb25">
                                <div>
                                    <span>本月智能预警（  </span> 
                                    <span class="fs86" style="color: #FED400;">{{smallObj.datascreen2WarnBOList.length}}</span> 
                                    <span>条）</span> 
                                </div> 
                                <div class="title-line-sub mt18"></div>
                            </div>
                            <marquee
                                class="s-title-cls-list fs51 ml95"
                                direction="up"
                                behavior="scroll"
                                scrollamount="2"
                            >
                                <div class="warn-title-cls flex" v-for="(item,index) in smallObj.datascreen2WarnBOList" :key="index">
                                    <div class="mr20" :class="item.warnStatus == 1 ? 'w1-cls' : 'w2-cls'"></div>
                                    <div>{{item.warnName}}</div>
                                </div>
                                
                            </marquee>
                            <div class="deal-with fs51 ta_c ml95">
                                <span>已处理：</span>
                                <span class="fs77" style="color: #E33B3B;">{{yujing}}</span>
                                <span>条</span>
                            </div> 
                        </div>
                    </div>
                    <div class="echarts-box flex">
                        <div>
                            <div class="sheep-title fs54 ml95 mt28 mb25">
                                <div>基础母羊存栏量 （单位：只） </div> 
                                <div class="title-line-sub mt18"></div>
                            </div>
                            <div class="echarts3 ml90">
                                <Highecharts3 ref="jcmy"></Highecharts3>
                            </div>
                            <!-- <img class="echarts3 ml90" src="../../../assets/images/dataScreen/sheep/echarts3.png" alt=""> -->
                        </div>
                        <div class="ml80">
                            <div class="sheep-title fs54 ml95 mt28 mb25">
                                <div>育肥羊预估出栏量  （单位：只）</div> 
                                <div class="title-line-sub mt18"></div>
                            </div>
                            <div class="echarts3 ml90">
                                <Highecharts4 ref="yfy"></Highecharts4>
                            </div>
                            <!-- <img class="echarts3 ml90" src="../../../assets/images/dataScreen/sheep/ehcarts4.png" alt=""> -->
                        </div>
                    </div>
                    <div class="echarts-box flex">
                        <div class="w100">
                            <div class="sheep-title fs54 ml95 mt58 mb15">
                                <div>新生羔羊月度统计动态  （单位：只）</div> 
                                <div class="title-line-sub mt18"></div>
                            </div>
                            <div class="echarts5 ml90">
                                <Highecharts5 ref="xsgy"></Highecharts5>
                            </div>
                            <!-- <img class="echarts5 ml90" src="../../../assets/images/dataScreen/sheep/echarts5.png" alt=""> -->
                        </div>
                    </div>
                    <div class="box_j lt_"></div>
                    <div class="box_j lb_"></div>
                    <div class="box_j rt_"></div>
                    <div class="box_j rb_"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import Highecharts1 from '../highecharts/dataScreen2/highecharts1.vue'
import Highecharts2 from '../highecharts/dataScreen2/highecharts2.vue'
import Highecharts3 from '../highecharts/dataScreen2/highecharts3.vue'
import Highecharts4 from '../highecharts/dataScreen2/highecharts4.vue'
import Highecharts5 from '../highecharts/dataScreen2/highecharts5.vue'
import 'animate.css';
export default {
    name: '',
    data(){
        return{
            waterDeep: null,
            renxiao: 0,
            yangObj: {},
            smallObj: {},
            qxList: {},
            yujing: 0,
            lengthAll: 0,
            isTrue: false,
            timeNum: 0,
            renCount: 0,
            tabsType: '0',
            iot: {},
            isShow: false,
            environmentalAttribute: {
                width: 773,
                height: 727
            },
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12
            
        }
    },
    components: {
        EZUIKitJs,
        EZUIKitJs2,
        Highecharts1,
        Highecharts2,
        Highecharts3,
        Highecharts4,
        Highecharts5
    },
    mounted(){
        this.init();
        var timer = setInterval(() => {
            ++this.timeNum
            console.log(this.timeNum)
            if(this.timeNum > 4) {
                clearInterval(timer)
            }
        },50)
        
    },
    methods:{
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var row = JSON.parse(sessionStorage.getItem("chooseSheep"));
            // map.setTextSize({
            //     textSize: 'BMAP_POI_TYPE_LARGE'
            // });
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/sheep/sheep.gif"), new BMap.Size(86, 86), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(86, 86),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].xAxis, this.potatoData[i].yAxis);   
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   
                var content = '<div style="font-size: 60px; font-weight: 500; padding-bottom: 47px; border-bottom: 3px solid #47D6FF;">'+this.potatoData[i].baseName+'</div><div style="font-size: 40px; margin-top: 34px">面积：'+this.potatoData[i].baseArea+'</div><div style="font-size: 40px;margin-top: 38px">地址：'+this.potatoData[i].baseAddr+'</div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-60, -500)
                })  

                
                map.addOverlay(label);

                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: this.potatoData[i].id == row.id ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-07/2533a5d0ddaf4c41a19a77af74d9c9b8cbba9c3c4ccf8592d32452e823767362.png")' : 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    // height: '500px',
                    padding: '52px 56px 100px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            for(let i = 0; i < this.yangObj.datascreen1IndustrialTownshipBOList.length; i++) {
                var point = new BMap.Point(this.yangObj.datascreen1IndustrialTownshipBOList[i].mapX, this.yangObj.datascreen1IndustrialTownshipBOList[i].mapY); 
                var content = '<div style="position: relative; height: 100%;"><div style="font-size: 50px; line-height: 106px; position: absolute; bottom: -166px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.yangObj.datascreen1IndustrialTownshipBOList[i].township+'</div><div>年饲养量：'+(parseFloat(this.yangObj.datascreen1IndustrialTownshipBOList[i].sheepStock)+parseFloat(this.yangObj.datascreen1IndustrialTownshipBOList[i].sheepOutStock))+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-280, -180)
                })  

                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")',
                    backgroundColor: 'transparent',
                    padding: '30px 56px 70px',
                    backgroundSize: '100% 100%'
                })
            }
            var userIcon = new BMap.Icon(require("../../../assets/images/dataScreen/sheep/user.png"), new BMap.Size(86, 104), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(86, 104),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            }); 
            for(let i = 0; i < this.yangObj.datascreen1WhirlyBOList.length; i++) {
                var point = new BMap.Point(this.yangObj.datascreen1WhirlyBOList[i].mapX, this.yangObj.datascreen1WhirlyBOList[i].mapY);   
                var marker = new BMap.Marker(point, {icon: userIcon});   
                map.addOverlay(marker);   
            }

            
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            // var marker = new BMap.Marker(point) // 创建标注
            // map.addOverlay(marker) // 将标注添加到地图中
            // var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            // map.addOverlay(circle)
            map.enableScrollWheelZoom(true);
        },
        init() {
            this.qa.withOutQueryRedisByIotEmData({houseIds: 169}).then(res => {
                this.iot = res.data.list[0];
            })
            this.qa.withOutqueryRedisByWaterDeep({}).then(res => {
                this.waterDeep = res.data.waterDeep;
            })
            this.qa.queryDatascreen1ById({}).then(res => {
                this.yangObj = res.data;
                this.potatoData = res.data.datascreen1BaseBOList;
                this.isShow = true;
                
            })
            this.qa.withOutQueryRedisByIotEmData2({houseIds: "521"}).then(res => {
                if(res.data.list.length > 0) {
                    this.qxList = res.data.list[0];
                }
                
            })

            this.qa.queryDatascreen2ById({}).then(res => {
                this.smallObj = res.data;
                this.isTrue = true;
                let count = parseInt(this.smallObj.allBreedMaleCount) + parseInt(this.smallObj.allBreedFemaleCount) + parseInt(this.smallObj.allBredBreedCount) + parseInt(this.smallObj.allLambBreedCount);
                for(let i = 0; i < this.smallObj.datascreen2WarnBOList.length; i++) {
                    if(this.smallObj.datascreen2WarnBOList[i].warnStatus == 2) {
                        this.yujing++;
                    }
                }
                for(let i = 0; i < this.smallObj.datascreen2FeedBOList.length; i++) {
                    this.lengthAll += parseFloat(this.smallObj.datascreen2FeedBOList[i].feedConsumption);
                }
                //人员比例echarts
                let list = [];
                for(let i = 0; i < this.smallObj.datascreen2JobBOList.length; i++) {
                    this.renCount += parseInt(this.smallObj.datascreen2JobBOList[i].jobPersonCount);
                    let obj = {};
                    obj.value = this.smallObj.datascreen2JobBOList[i].jobPersonCount;
                    obj.name = this.smallObj.datascreen2JobBOList[i].jobName + ' ' + this.smallObj.datascreen2JobBOList[i].jobPersonCount;
                    obj.itemStyle = {
                        color: `rgb(${parseInt(Math.random()*255)},${parseInt(Math.random()*255)},${parseInt(Math.random()*255)})`
                    }
                    list.push(obj)
                }
                this.renxiao = (count / this.renCount).toFixed(2);
                this.$nextTick(function () {
                    this.$refs.renyuan.Rydata = list;
                    this.$refs.renyuan.actualCount();
                })
                //销售记录
                let monthList = [];
                let yufList = [];
                let kuofList = [];
                for(let i = 0; i < this.smallObj.datascreen2SellBOList.length; i++) {
                    monthList.push(this.smallObj.datascreen2SellBOList[i].yearMonthDate);
                    yufList.push(this.smallObj.datascreen2SellBOList[i].fattenCount);
                    kuofList.push(this.smallObj.datascreen2SellBOList[i].multiplicationCount);
                }
                this.$nextTick(function () {
                    this.$refs.xiaoshou.xList = monthList;
                    this.$refs.xiaoshou.yfList = yufList;
                    this.$refs.xiaoshou.kfList = kuofList;
                    this.$refs.xiaoshou.man();
                 })

                //基础母羊存栏量
                let jcyf = [];
                let mysl = [];
                for(let i = 0; i < this.smallObj.datascreen2BaseFemaleStatisticsBOList.length; i++) {
                    jcyf.push(this.smallObj.datascreen2BaseFemaleStatisticsBOList[i].yearMonthDate+'月');
                    mysl.push(parseInt(this.smallObj.datascreen2BaseFemaleStatisticsBOList[i].count));
                }
                
                this.$nextTick(function () {
                    this.$refs.jcmy.yfLists = jcyf;
                    this.$refs.jcmy.zhiLists1 = mysl;
                    this.$refs.jcmy.man();
                })

                //育肥羊存栏量
                let yfyf = [];
                let yfsl = [];
                for(let i = 0; i < this.smallObj.datascreen2FatteningSheepStatisticsBOList.length; i++) {
                    yfyf.push(this.smallObj.datascreen2FatteningSheepStatisticsBOList[i].yearMonthDate+'月');
                    yfsl.push(parseInt(this.smallObj.datascreen2FatteningSheepStatisticsBOList[i].count));
                }
                this.$nextTick(function () {
                    this.$refs.yfy.yfyf = yfyf;
                    this.$refs.yfy.yfsl = yfsl;
                    this.$refs.yfy.man();
                })

                //新生羔羊
                let xsyf = [];
                let xssl = [];
                for(let i = 0; i < this.smallObj.datascreen2NewbornLambStatisticsBOList.length; i++) {
                    xsyf.push(this.smallObj.datascreen2NewbornLambStatisticsBOList[i].yearMonthDate);
                    xssl.push(parseInt(this.smallObj.datascreen2NewbornLambStatisticsBOList[i].count));
                }
                this.$nextTick(function () {
                    this.$refs.xsgy.xsyf = xsyf;
                    this.$refs.xsgy.xssl = xssl;
                    this.$refs.xsgy.moreChart();
                })

            })
        },
        tabsClick() {
            if(this.tabsType == '1') {
                this.tabsType = '0'
            }else {
                this.tabsType = '1'
            }
        }
    }
}
</script>

<style scoped>
    .box {
        color: #fff;
    }
    .item-box {
      position: relative;  
    }
    .box_j {
        width: 61px;
        height: 61px;
        position: absolute;
    }
    .lt_ {
        background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        left: -5px;
    }
    .rt_ {
        background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        right: -5px;
    }
    .lb_ {
        background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
        background-size: 100% 100%;
        left: -5px;
        bottom: -5px;
    }
    .rb_ {
        background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
        background-size: 100% 100%;
        right: -5px;
        bottom: -5px;
    }
    .map-box {
        position: relative;
    }
    .map-left {
        margin-top: 247px;
        position: relative;
        z-index: 999;
    }
    .map-left-item {
        width: 798px;
        height: 311px;
        display: flex;
        align-items: center;
        margin-bottom: 135px;
        background: url(../../../assets/images/dataScreen/ds1_bg_box.png) no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding-left: 68px;
    }
    .map-left-icon {
        width: 261px;
        height: 228px;
        background: url(../../../assets/images/dataScreen/ds_icon2.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-2 {
        background: url(../../../assets/images/dataScreen/ds_icon1.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-3 {
        background: url(../../../assets/images/dataScreen/ds_icon6.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-img {
        width: 5218px;
        height: 2943px;
        background: url(../../../assets/images/dataScreen/map1.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0px;
        z-index: 100;
        left: 0px;
    }
    .map-text-box {
        box-sizing: border-box;
        padding: 70px 0 0 170px;
        width: 1341px;
        height: 618px;
        position: relative;
        left: 2000px;
        top: 822px;
        background: url(../../../assets/images/dataScreen/map_text_box.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-text-box-title {
        color: #fff;
        font-size: 70px;
    }
    .map-text-box-title-2 {
        color: #fff;
        opacity: .8;
        font-size: 53px;
    }
    .right {
        width: 8173px;
        margin-left: 4582px;
    }
    .header { 
        width: 7920px;
        height: 69px;
        background: url(../../../assets/images/dataScreen/sheep_title.png) no-repeat;
        background-size: 100% 100%;
        
        text-align: center;
        
        margin: 30px auto 100px;
    }
    .header-text {
        width: 7920px;
        height: 69px;
        color: #fff;
        font-size: 90px;
        margin: 40px auto 0;
        text-align: center;
    }
    .content_left {
        width: 2519px;
    }
    .content_left_top {
        width: 100%;
        height: 937px;
        background: rgba(6, 58, 141, .1);
    }
    .content_left_bottom {
        width: 100%;
        height: 800px;
        background: rgba(6, 58, 141, .1);
    }
    .content_left_center {
        width: 100%;
        height: 710px;
        margin-top: 110px;
    }
    .content_left_center_left {
        width: 1117px;
        height: 710px;
        background: rgba(6, 58, 141, .1);
    }
    .content_left_center_right {
        width: 1321px;
        height: 710px;
        position: relative;
        background: rgba(6, 58, 141, .1);
    }
    .left_title {
        display: flex;
        align-items: center;
        height: 176px;
        line-height: 176px;
        position: relative;
        background: rgba(6, 58, 141, .13);
        color: #fff;
        box-sizing: border-box;
        padding-left: 76px;
    }
    .icon-left {
        width: 61px;
        height: 61px;
        margin-right: 39px;
    }
    .title-line {
        width: 311px;
        height: 30px;
        background:url(../../../assets/images/dataScreen/sheep/title_line.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 177px;
        bottom: 8px;
    }
    .title-line-sub {
        width: 311px;
        height: 30px;
        background:url(../../../assets/images/dataScreen/sheep/line-sheep.png) no-repeat;
        background-size: 100% 100%;
    }
    .sheep-m {
        width: 124px;
        height: 134px;
        background:url(../../../assets/images/dataScreen/sheep/sheep-m.png) no-repeat;
        background-size: 100% 100%;
        margin-right: 38px;
    }
    .sheep-m-line {
        width: 385px;
        height: 16px;
        background:url(../../../assets/images/dataScreen/sheep/sheep-m-icon.png) no-repeat;
        background-size: 100% 100%;
    }
    .sheep-m-line-2 {
        width: 307px;
        height: 16px;
    }
    .sheep-m-line-3 {
        width: 288px;
        height: 16px;
    }
    .sheep-line-s {
        width: 8px;
        height: 295px;
        background:url(../../../assets/images/dataScreen/sheep/line-s.png) no-repeat;
        background-size: 100% 100%;
        margin: 100px 54px 0 73px;
    }
    .marquee-list {
        height: 260px;
        overflow: hidden;
    }
    .echarts1 {
        width:100%;
        height: 449px;
        position: relative;
    }
    .echarts-num {
        position: absolute;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 90px;
        font-weight: 600;
        color: #fff;
        z-index: 500;
        height: 90px;
        text-align: center;
    }
    .echarts2 {
        width: 100%;
        height: 489px;
    }
    .line-jl {
        width: 40px;
        height: 12px;
    }
    .jl-box {
        width: 1213px;
        height: 104px;
        line-height: 104px;
        margin: 0 auto;
        text-align: center;
        background: #1D51A4;
    }
    .left_bottom_left {
        width: 1815px;
    }

    .heng {
        background-image: linear-gradient(to right, #01d7fc , #0c7794);
        height: 55px;
        border-radius: 30px;
    }
    .xh-line1 {

        height: 55px;
        background:url(../../../assets/images/dataScreen/sheep/xh-line1.png) no-repeat;
        background-size: 100% 100%;
    }
    .xh-line2 {
        width: 1176px;
        height: 55px;
        background:url(../../../assets/images/dataScreen/sheep/xh-line2.png) no-repeat;
        background-size: 100% 100%;
    }
    .xh-line3 {
        width: 804px;
        height: 55px;
        background:url(../../../assets/images/dataScreen/sheep/xh-line.png) no-repeat;
        background-size: 100% 100%;
    }
    .xh-line {
        width: 2px;
        height: 403px;
        background: #FFFFFF;
        opacity: 0.29;
    }
    .icon-left-y {
        width: 97px;
        height: 97px;
    }
    .content_center {
        width: 2511px;
        height: 2673px;
        position: relative;
    }
    .tabs_btn_box {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 5555;
    }
    
    .tabs_btn {
        cursor: pointer;
       
    }
    .tabs_line {
        width: 358px;
        height: 60px;
        margin-top: 20px;        
        background:url(../../../assets/images/dataScreen/sheep/title_line.png) no-repeat;
        background-size: 100% 100%;
    }
    .content_right {
        width: 2919px;
        height: 2673px;
        position: relative;
        background: rgba(6, 58, 141, .1)
    }
    .meteorological-icon {
        width: 174px;
        height: 174px;
        margin: 40px 0 30px;
    }
    .meteorological-icon-1 {
        background:url(../../../assets/images/dataScreen/sheep/meteorological1.png) no-repeat;
        background-size: 100% 100%;
    }
    .meteorological-icon-2 {
        background:url(../../../assets/images/dataScreen/sheep/meteorological2.png) no-repeat;
        background-size: 100% 100%;
    }
    .meteorological-icon-3 {
         background:url(../../../assets/images/dataScreen/sheep/meteorological3.png) no-repeat;
        background-size: 100% 100%;
    }
    .meteorological-icon-4 {
         background:url(../../../assets/images/dataScreen/sheep/meteorological4.png) no-repeat;
        background-size: 100% 100%;
    }
    .meteorological-icon-5 {
         background:url(../../../assets/images/dataScreen/sheep/meteorological5.png) no-repeat;
        background-size: 100% 100%;
    }
    .meteorological-icon-6 {
         background:url(../../../assets/images/dataScreen/sheep/meteorological6.png) no-repeat;
        background-size: 100% 100%;
    }
    .meteorological-icon-7 {
         background:url(../../../assets/images/dataScreen/sheep/meteorological7.png) no-repeat;
        background-size: 100% 100%;
    }
    .meteorological-icon-8 {
         background:url(../../../assets/images/dataScreen/sheep/meteorological8.png) no-repeat;
        background-size: 100% 100%;
    }
    .left {
        width: 1600px;
    }
    .task_item {
        width: 730px;
        height: 164px;
        background: linear-gradient(180deg, rgba(6,58,141,0) 0%, #134DA9 100%);
        border-radius: 22px;
        box-sizing: border-box;
        padding: 0 50px;
        opacity: .9;
        margin-right: 43px;
        margin-bottom: 43px;
    }
    .task_icon {
        width: 43px;
        height: 51px;
    }
    .task_box {
        width: 1740px;
    }
    .task_line {
        width: 4px;
        height: 376px;
        margin-top: 80px;
        border: 2px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.05), rgba(0, 171, 254, 1), rgba(255, 255, 255, 0.05)) 2 2;
    }
    .deal-with {
        width: 950px;
        height: 124px;
        line-height: 124px;
        background: rgba(135, 89, 233, .1);
    }
    .s-title-cls-list {
        margin-top: 10px;
        height: 230px;
        overflow: hidden;
    }
    .w1-cls{
        width: 47px;
        height: 47px;
        background-image: url(../../../assets/images/dataScreen/sheep/yj1.png);
    }
    .w2-cls{
        width: 47px;
        height: 47px;
        background-image: url(../../../assets/images/dataScreen/sheep/yj2.png);
    }
    .warn-title-cls {
        margin-bottom: 20px;
    }
    .echarts3 {
        width: 1291px;
        height: 562px;
    }
    .echarts5 {
        width: 2670px;
        height: 562px;
        margin-left: 100px;
    }
</style>