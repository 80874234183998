<template>
  <div>
        <div id="echarts4" class="echarts4"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
// @ is an alias to /src
export default {
  name: 'sheepHose',
  data(){
      return{
          xData: [],
          yData: [],
      }
  },
  props: {
    // echartsMonth: {
    //     type: Array
    // }
    //   echartsDetermination: {
    //       type: Object
    //   },
    //   eweProduce: {
    //       type: Object
    //   },
    //   echartsSex: {
    //       type: String
    //   } 
  },
  mounted(){
      //this.actualCount()
  },
  watch: {

  },
  methods:{
      actualCount() {
        var chartDom = document.getElementById('echarts4');
        var myChart = echarts.init(chartDom);
        var option;
        // var xData = ['深松作业','旋耕作业','收获作业','玉米青贮作业','播种作业','翻地作业']
        
        // var y1 = [193779, 131599, 88919, 56260, 50218, 31005]; //生产
        option = {
            // title: {
            //     text: '配种图表(单位: 只)',
            //     //subtext: '纯属虚构'
            //      textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
            //         fontFamily: 'Arial, Verdana, sans...',
            //         fontSize: 14,
            //         color: '#0B1947',
            //         fontWeight: 'normal',
            //     },
            // },
            grid:{
                top: 50,
                left: 220,
                right: 40,
                bottom:  70,
                borderWidth:1
            },
            // tooltip: {
            //     trigger: 'axis'
            // },
            // legend: {
            //     icon: 'rectangle',
            //     data: ['  冷库个数（个）'],
            //     right: '4%',
            //     textStyle: { //改变刻度字体样式
            //         color: '#6FB6FF',
            //         fontSize: 50
            //     }
            // },
            // toolbox: {
            //     show: false,
            //     feature: {
            //         dataView: {show: true, readOnly: false},
            //         magicType: {show: true, type: ['line', 'bar']},
            //         restore: {show: true},
            //         saveAsImage: {show: true}
            //     }
            // },
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    data: this.xData,
                    splitLine:{
                        show:false
                    },
                    axisLabel : {
                        interval: 0,
                        textStyle: { //改变刻度字体样式
                            color: '#fff',
                            fontSize: 40
                        }
                    },
                    // splitArea: {
                    //     show: true,
                    //     interval: 0,
                    //     areaStyle: {
                    //         color: [
                    //             new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                    //                 offset: 0, color: '#1F1461' // 0% 处的颜色
                    //             }, {
                    //                 offset: 1, color: '#0A182A' // 100% 处的颜色
                    //             }]),
                    //             new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                    //                 offset: 0, color: '#0A182A' // 0% 处的颜色
                    //             },  {
                    //                 offset: 1, color: '#1F1461' // 100% 处的颜色
                    //             }]),
                    //         ],
                    //     },
                    // },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
                    offset: 5,
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color: '#142D4A',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    axisLabel : {
                        textStyle: { //改变刻度字体样式
                            color: '#fff',
                            fontSize: 50
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
               }
            ],
            series: [
                {
                    name: '  冷库个数（个）',
                    type: 'bar',
                    data: this.yData,
                    showBackground: false,

                    backgroundStyle: {
                        color: 'rgba(255, 255, 255, 0.05)',

                    },
                    barWidth : 150,//柱图宽度
                    color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                        offset: 0, color: '#143079' // 0% 处的颜色
                    },{
                        offset: 1, color: '#3ED5FE' // 100% 处的颜色
                    }]),

                    emphasis: {
                        focus: "series",
                    },
                    itemStyle: {
                        
                        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        // emphasis: {
                        //     barBorderRadius: 20
                        // },
                        normal: {
                            label: {
                                show: true, //开启显示
                                position: 'top', //在上方显示
                                textStyle: { //数值样式
                                    color: '#38BEE8',
                                    fontSize:  40
                                }
                            },
                        },
                        color:new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                            offset: 0, color: '#01D7FC' // 0% 处的颜色
                        }, {
                            offset: 0.8, color: '#248DA7' // 100% 处的颜色
                        },{
                            offset: 1, color: '#217182' // 100% 处的颜色
                        }]),
                    },
                },
            ]
        };
        option && myChart.setOption(option);
      }
  }
}
</script>

<style scoped>
    .echarts4 {
        width: 100%;
        height: 915px;
    }
</style>