<template>
  <div class="echarts-box">
    <div id="container"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: "HelloWorld",
  data(){
    return {
        xData: [],
        yData1: [],
        yData2: [],
        year1: '',
        year2: '',
    }
  },
  props: {
    msg: String
  },
  mounted() {
    // this.moreChart();
  },
  methods: {
    moreChart() {
      var chartDom = document.getElementById('container');
        var myChart = echarts.init(chartDom);
        var option;
        var xData = this.xData;
        var yStandard1 = this.yData1; //标准
        var yStandard2 = this.yData2; //标准
        var lineName1 = this.year1;
        var lineName2 = this.year2;
        option = {
                // title: {
                //     text: '体重测定图表(单位: 公斤)',
                //     // subtext: '纯属虚构'
                //     textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                //         fontFamily: 'Arial, Verdana, sans...',
                //         fontSize: 14,
                //         color: '#0B1947',
                //         fontWeight: 'normal',
                //     },
                // },
                tooltip: {
                    show: false,
                    trigger: 'axis',
                    textStyle: {
                        fontSize: 50
                    },
                    // formatter: function(params) {
                    //     var result = ''
                    //     var dotHtml = 
                    //         '<span style="display:inline-block;margin-right:5px;border-radius:50px;width: 30px;height:30px;background-color:#3de2d6"></span>'
                    //     params.forEach(function (item) {
                    //         console.log(item)
                    //         result += item.axisValue + "</br>"  + dotHtml+ ' ' + item.seriesName + ' ' + item.data
                    //     })
                    //     return result
                    // }
                },
                grid: {
                    left: 100,
                    top: 100,
                    bottom: 100,
                    right: 50
                },
                // legend: {
                //     icon: 'rectangle',
                //     data: ['标准', '测定'],
                //     right: '4%'
                // },
                

                toolbox: {
                    show: false,
                    // textStyle: {
                    //     color: '#fff',
                    //     fontSize: 50
                    // },
                    
                    // feature: {
                    //     dataZoom: {
                    //         yAxisIndex: 'none'
                    //     },
                    //     dataView: {readOnly: false},
                    //     magicType: {type: ['line', 'bar']},
                    //     restore: {},
                    //     saveAsImage: {}
                    // }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    data: xData,
                    
                    nameTextStyle :{
					    fontSize: 50
				    },
                    axisLabel: {
                        margin: 40,
                        textStyle: {
                            color: '#CCCCCC',
                            fontSize: 50
                        },
                    },
                    axisLine: {
                        lineStyle: {
                        type: 'solid',
                        color: '#3de2d6',
                        width: 6
                        }
                    },
                    grid: {
                        show: true,
                        bottom: 100
                    }

                },
                yAxis: {
                    min: 'dataMin',
                    type: 'value',
                    splitLine: {
                        show: false,
                        lineStyle:{
                            color: '#CCCCCC',
                            width: 10,
                            type: 'solid'
                        }
                     },
                    axisLabel: {
                        textStyle: {
                            color: '#CCCCCC',
                            fontSize: 50
                        },
                    },
                    axisLine: {
                        lineStyle: {
                        type: 'solid',
                        color: '#3de2d6',
                            width: 6
                        }
                    },
                },
                series: [
                    {
                        name: lineName1,
                        type: 'line',
                        data: yStandard1,
                        smooth: true,
                        symbolSize: 25,
                        itemStyle:{
                            normal:{
                                label:{
                                    show:true,
                                    position:'top',
                                    fontSize: 45,
                                    textStyle:{
                                        color:'#3de2d6'
                                    }
                                }
                            }
                        },

                        symbol:'circle',
                        color: '#3de2d6',

                        showSymbol: true,
                        lineStyle:{
                            width: 8,
                            color: '#3de2d6'
                        },
                        // areaStyle: {
                        //     opacity: 0.8,
                        //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //         offset: 0,
                        //         color: 'rgba(246, 214, 97)'
                        //     }, {
                        //         offset: 1,
                        //         color: 'rgba(246, 214, 97, 0.1)'
                        //     }])
                        // },
                    },
                    {
                        name: lineName2,
                        type: 'line',
                        data: yStandard2,
                        smooth: true,
                        symbolSize: 25,
                        itemStyle:{
                            normal:{
                                label:{
                                    show:true,
                                    position:'top',
                                    fontSize: 45,
                                    textStyle:{
                                        color:'#0F84FF'
                                    }
                                }
                            }
                        },
                        symbol:'circle',
                        color: '#0F84FF',

                        showSymbol: true,
                        lineStyle:{
                            width: 8,
                            color: '#0F84FF'
                        },
                        // areaStyle: {
                        //     opacity: 0.8,
                        //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //         offset: 0,
                        //         color: 'rgba(246, 214, 97)'
                        //     }, {
                        //         offset: 1,
                        //         color: 'rgba(246, 214, 97, 0.1)'
                        //     }])
                        // },
                    },
                ]
        };
        option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
    .charts-box, #container {
        width: 100%;
        height: 100%;
    }
</style>