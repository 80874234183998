<template>
    <div class="box flex">
        <div class="map-box">
            <div class="map-left">
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-1"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ddObj.serviceArea || 0}}</div>
                        <div class="fs50 cfff mt10">服务面积（万亩）</div>
                    </div>
                </div>
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-2"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ddObj.serviceEnterprise || 0}}</div>
                        <div class="fs50 cfff mt10">服务企业（家）</div>
                    </div>
                </div>
                <div class="map-left-item animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-3"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ddObj.iotEquipmentCount || 0}}</div>
                        <div class="fs50 cfff mt10">物联网设备（个）</div>
                    </div>
                </div>
            </div>
            <div class="map-img">
                <div class="map-text-box animate__animated animate__fadeInUp">
                    <div class="map-text-box-title mb31">榆林市明杰农业开发有限公司</div>
                    <div class="map-text-box-title-2 mt80 mb16" >亩数：4.25万亩</div>
                    <div class="map-text-box-title-2">地址：榆林市榆阳区岔河则乡明杰马铃薯基地</div>
                </div>
                <div class="map-text-boxs">
                    <div class="map-text-boxs-title">企业：{{ddObj.mapEnterpriseCount || 0}}家</div>
                </div>
            </div>
        </div>

        <div class="right animate__animated animate__fadeInRight">
            <div class="header-text">榆林市明杰农业开发有限公司</div> 
            <div class="header"></div>
            <div style="display:flex;">
                <div class="content_left item-box">
                    <div class="content-top-left-corner"></div>
                    <div class="content-bottom-right-corner"></div>
                    <div class="content-top-title">基地实时画面</div>
                    <div style="display:flex;flex-wrap: wrap;">
                        <div class="content-item item-box" style="margin-right:59px;">
                            <div class="content-item-img" v-if="isTrue">
                                <EZUIKitJs :msg="'video1'" :title="video1Title" :attribute="webcamAttribute" :flv="ddObj.jidiBigVedio1Url"/>
                            </div>
                            <div class="content-item-title" @click="transitionFun(item.deviceUrl, item.installArea)">{{ddObj.jidiBigVedio1Title}}</div>
                        </div>
                        <!-- <div class="content-item item-box">
                            <div class="content-item-img">
                                <EZUIKitJs msg="video2" :attribute="environmentalAttribute"
                                flv="ezopen://open.ys7.com/K44770580/1.live" />
                            </div>
                            <div class="content-item-title">第二农场7号圈</div>
                        </div>
                        <div class="content-item item-box" style="margin-right:59px;">
                            <div class="content-item-img">
                                <EZUIKitJs2 :msg="'video3'" :title="video3Title" :attribute="webcamAttribute" flv="https://cmgw-vpc.lechange.com:8890/LCO/8B0572DPAJ82BFC/0/1/20220713T075132/83ecc7d37c3f9abeafb675cb3a0f2675.m3u8?proto=https"/>
                            </div>
                            <div class="content-item-title">第二农场7号圈</div>
                        </div>
                        <div class="content-item item-box">
                            <div class="content-item-img">
                                <EZUIKitJs2 :msg="'video4'" :title="video4Title" :attribute="webcamAttribute" flv="https://cmgw-vpc.lechange.com:8890/LCO/8B0572DPAJB7FCD/0/1/20220713T083229/a4457bd1af08bd46e064d2b0436cd1ab.m3u8?proto=https"/>
                            </div>
                            <div class="content-item-title">第二农场7号圈</div>
                        </div> -->
                    </div>
                </div>
                <div class="base-box">
                    <div class="base-title-box">
                        <div class="base-title">基地大数据</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="base-content">
                        <div class="base-content-item" style="padding-left: 75px;">
                            <div style="margin-right: 140px;">
                                <div class="item-title">种植总面积</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataTotalPlantingArea || 0}}</span>
                                    <span class="item-text-company">万亩</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin-left: 150px;margin-right: 120px;">
                            <div style="margin-right:120px;">
                                <div class="item-title">微型薯快繁中心</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataMicroPotato || 0}}</span>
                                    <span class="item-text-company">万平方米</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item">
                            <div>
                                <div class="item-title">脱毒组培育中心</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataDetoxificationGroup || 0}}</span>
                                    <span class="item-text-company">平方米</span>
                                </div>
                            </div>
                        </div>
                        <div class="base-content-item" style="margin-top:110px;">
                            <div style="margin-right:85px;">
                                <div class="item-title">全日光智能温室</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataFullSunlight || 0}}</span>
                                    <span class="item-text-company">平方米</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin: 110px 110px 0 120px;">
                            <div style="margin-right:80px;">
                                <div class="item-title">微型薯气调存储库</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataStorageOfPotato || 0}}</span>
                                    <span class="item-text-company">平方米</span>
                                </div>
                            </div>
                            <div class="item-img"></div>
                        </div>
                        <div class="base-content-item" style="margin-top:110px;">
                            <div>
                                <div class="item-title">原种薯气调库</div>
                                <div class="item-text">
                                    <span class="item-text-num">{{ddObj.jidiBigdataOriginalSeedPotato || 0}}</span>
                                    <span class="item-text-company">万平方米</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="display:flex;justify-content:space-between;margin-top:170px;">
                        <div class="planting-area">
                            <div class="base-title-box" style="margin-bottom:100px;">
                                <div class="base-title">基地育种能力</div>
                                <div class="base-title-img"></div>
                            </div>
                            <div class="planting-area-content">
                                <div class="planting-area-item">{{ddObj.abilityBaseAttr1}}</div>
                                <div class="planting-area-item">
                                    <span class="item-text-num" style="font-size:56px;margin-right:30px;">{{ddObj.abilityBaseValue1 || 0}}</span>
                                    <span>{{ddObj.abilityBaseUnit1}}</span>
                                </div>
                            </div>
                            <div class="planting-area-content">
                                <div class="planting-area-item">{{ddObj.abilityBaseAttr2}}</div>
                                <div class="planting-area-item">
                                    <span class="item-text-num" style="font-size:56px;margin-right:30px;">{{ddObj.abilityBaseValue2 || 0}}</span>
                                    <span>{{ddObj.abilityBaseUnit2}}</span>
                                </div>
                            </div>
                            <div class="planting-area-content">
                                <div class="planting-area-item">{{ddObj.abilityBaseAttr3}}</div>
                                <div class="planting-area-item">
                                    <span class="item-text-num" style="font-size:56px;margin-right:30px;">{{ddObj.abilityBaseValue3 || 0}}</span>
                                    <span>{{ddObj.abilityBaseUnit3}}</span>
                                </div>
                            </div>
                            <div class="planting-area-content">
                                <div class="planting-area-item">{{ddObj.abilityBaseAttr4}}</div>
                                <div class="planting-area-item">
                                    <span class="item-text-num" style="font-size:56px;margin-right:30px;">{{ddObj.abilityBaseValue4 || 0}}</span>
                                    <span>{{ddObj.abilityBaseUnit4}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="planting-area">
                            <div class="base-title-box" style="margin-bottom:100px;">
                                <div class="base-title" style="margin-right:30px;">年度总产量|平均亩产</div>
                                <div class="base-title-img"></div>
                            </div>

                            <div class="planting-area-contents" v-for="(item,index) in ddObj.datascreen6AnnualTotalYieldBOList" :key="index">
                                <div class="planting-area-item">{{item.parcelName}}</div>
                                <div class="planting-area-item" style="color: RGBA(255, 217, 0, 1);">{{item.totalOutput || 0}}公斤</div>
                                <div class="item-line"></div>
                                <div class="planting-area-item" style="color: RGBA(255, 217, 0, 1);">{{item.averageYield || 0}}公斤/亩</div>
                            </div>
                        </div>
                    </div>
                    <div class="base-title-box" style="margin-top:170px;margin-bottom:130px;">
                        <div style="margin-right:460px;display:flex;">
                            <div class="base-title">基地人员</div>
                            <div class="base-title-img"></div>
                        </div>
                        <div style="display:flex;">
                            <div class="base-title">基地农机</div>
                            <div class="base-title-img"></div>
                        </div>
                    </div>
                    <div class="base-information-box flex">
                        <div class="charts1">
                            <Highecharts2 ref="jdry"></Highecharts2>
                            <div class="echarts-num">{{renCount}}人</div>
                        </div>
                        <div class="rank-box fs50 ml70">
                            <div v-for="(item,index) in ddObj.datascreen6MachineryBOList" :key="index">
                                <div class="mb21 ta_l">
                                    <span class="">{{item.machineryName}}</span>
                                    <span class="ml21">{{item.machineryCount || 0}}台</span>
                                </div>
                                <div class="supplies-line-box supplies-line-box-blue flex f_ai_c mb39" :style="{width: (item.machineryCount / maxCount) * 820 + 'px'}">
                                    <div class="supplies-line supplies-line-blue"></div>
                                    <div class="supplies-inner-circle supplies-inner-circle-blue"></div>
                                    <div class="supplies-outer-circle supplies-outer-blue"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="farm">
                    <div class="base-title-box">
                        <div class="base-title">地块信息</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="farm-item-box">
                        <div class="farm-item" v-for="(item,index) in ddObj.datascreen6MassifBOList" :key="index">
                            <div class="farm-item-title">{{item.farmName}}</div>
                            <div class="farm-item-subheading">{{item.massifName}}</div>
                            <div class="farm-item-list-box">
                                <div class="list-left">农作物</div>
                                <div class="list-right">{{item.crops}}</div>
                            </div>
                            <div class="farm-item-list-box" style="margin:32px 0;">
                                <div class="list-left">地块面积</div>
                                <div class="list-right">{{item.massifArea || 0}}亩</div>
                            </div>
                            <div class="farm-item-list-box">
                                <div class="list-left">管理员</div>
                                <div class="list-right">{{item.administrators}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="base-title-box" style="margin-top:129px;">
                        <div class="base-title">基地实时画面</div>
                        <div class="base-title-img"></div>
                      
                    </div>
                    <div class="base-information-boxs" v-if="isTrue">
                        <div class="base-img">
                                <EZUIKitJs :msg="'video5'" :title="video5Title" :attribute="webcamAttribute2" :flv="ddObj.jidiSmallVedio1Url"/>
                           
                            <div class="base-text">{{ddObj.jidiSmallVedio1Title}}</div>
                        </div>
                        <div class="base-img">
                            <EZUIKitJs :msg="'video6'" :title="video6Title" :attribute="webcamAttribute2" :flv="ddObj.jidiSmallVedio2Url"/>
                           
                            <div class="base-text">{{ddObj.jidiSmallVedio2Title}}</div>                            
                        </div>
                    </div>
                </div>
                <div class="weather-station">
                    <div class="base-title-box" style="margin-bottom:50px;">
                        <div class="base-title" style="margin-right:0;">基地气象站（东区内、西区内、基地外）</div>
                        <div class="base-title-img"></div>
                    </div>
                    <div class="weather-dashboard item-box">
                        <div class="weather-left">
                            9.2
                        </div>
                        <div class="weather-right">
                            <div>
                                <div class="qxzKeyCls qxzIndex1Cls">东北风</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">2.7m/s</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex2Cls">湿度</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">28%Rh</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex3Cls">雨量</div>
                                <div>
                                    <div class="qxzStatusCls s2Cls"></div>
                                    <div class="qxzValCls">0mm</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex4Cls">CO2</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">428ppm</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex5Cls">光照</div>
                                <div>
                                    <div class="qxzStatusCls s3Cls"></div>
                                    <div class="qxzValCls">32.7Lux</div>
                                </div>
                            </div>
                            <div>
                                <div class="qxzKeyCls qxzIndex6Cls">大气压</div>
                                <div>
                                    <div class="qxzStatusCls s1Cls"></div>
                                    <div class="qxzValCls">88.1hPa</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="base-title-box" style="margin-bottom:100px;margin-top: 50px;">
                        <div class="base-title" style="margin-right:0;">基地土壤指标监测</div>
                        <div class="base-title-img"></div>
                    </div>

                    <div style="height:840px">
                        <div class="jttrzbjcColumnCls jttrzbjcHeaderCls">
                            <div>地块</div>
                            <div>土壤PH值</div>
                            <div>土壤温度</div>
                            <div>土壤水分</div>
                            <div>土壤盐分</div>
                            <div>土壤电导率</div>
                        </div>

                        <div class="jttrzbjcColumnCls jttrzbjcRowCls">
                            <div>第一农场7号圈北</div>
                            <div>7.3</div>
                            <div>5℃</div>
                            <div>68.3%</div>
                            <div>0.6</div>
                            <div>0.6</div>
                        </div>

                        <div class="jttrzbjcColumnCls jttrzbjcRowCls">
                            <div>第二农场7号圈中</div>
                            <div>7.2</div>
                            <div>6℃</div>
                            <div>15.7%</div>
                            <div>0.1</div>
                            <div>0.4</div>
                        </div>

                        <div class="jttrzbjcColumnCls jttrzbjcRowCls">
                            <div>第二农场7号圈南</div>
                            <div>6.9</div>
                            <div>6.4℃</div>
                            <div>19.8%</div>
                            <div>0.4</div>
                            <div>0.3</div>
                        </div>
                    </div>
                    <div class="rewuyujinCls">
                        <div class="rwssjdCls">
                            <div class="base-title-box" style="">
                                <div class="base-title" style="margin-right:0;">任务实时进度</div>
                                <div class="base-title-img"></div>
                            </div>
                            <div v-for="(item,index) in ddObj.datascreen6TaskBOList" :key="index">
                                <div class="rwmcCls">
                                    <div>{{item.taskName}}</div>
                                    <div>{{Number(item.finishCount) + Number(item.unFinishCount)}}</div>
                                </div>
                                <div class="renwuBoxCls">
                                    <div style="width: 50%">已完成 {{item.finishCount}}</div>
                                    <div>未完成 {{item.unFinishCount}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="nsyjCls">
                            <div class="base-title-box" style="">
                                <div class="base-title" style="margin-right:0;">农事预警</div>
                                <div class="base-title-img"></div>
                            </div>
                            <marquee class="marquee-list" direction="up" style="height: 680px" behavior="scroll" scrollamount="5">
                                
                                <div class="nsyjBoxCls" v-for="(item,index) in ddObj.datascreen6WarnBOList" :key="index">
                                    <div>{{item.warnName}}</div>
                                    <div>{{item.exceedanceName}}</div>
                                </div>
                     
                            </marquee>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKit from "ezuikit-js";
import Highecharts2 from '../highecharts/dataScreen3/highecharts2.vue'
export default {
    name: '',
    components: {
        EZUIKitJs2,
        EZUIKitJs,
        Highecharts2
    },
    data(){
        return{  
            ddObj: {},
            renCount: 0,
            maxCount: 0,
            environmentalAttribute: {
            width: 1450,
            height: 1120
            }, 
            townList: [],
            isTrue: false,
            webcamAttribute: {
                width: 3092,
                height: 2502
            },  
            webcamAttribute2: {
                width: 807,
                height: 507
            },
            video1Title: '1',
            video2Title: '2',
            video3Title: '3',
            video4Title: '4',
            video5Title: '5',
            video6Title: '6'

        }
    },

    mounted(){
        this.init();
        this.doPlay("");
    },
    methods:{
        init(){
            this.qa.datascreen3TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
            })
            this.qa.queryDatascreen6ById({}).then(res => {
                this.ddObj = res.data;
                this.isTrue = true;
                for(let i = 0; i < this.ddObj.datascreen6MachineryBOList.length; i++) {
                    if(this.ddObj.datascreen6MachineryBOList[i].machineryCount > this.maxCount) {
                        this.maxCount = this.ddObj.datascreen6MachineryBOList[i].machineryCount;
                    }
                }
                //基地人员
                let list = [];
                for(let i = 0; i < this.ddObj.datascreen6JobBOList.length; i++) {
                    this.renCount += parseInt(this.ddObj.datascreen6JobBOList[i].jobPersonCount);
                    let obj = {};
                    obj.value = this.ddObj.datascreen6JobBOList[i].jobPersonCount;
                    obj.name = this.ddObj.datascreen6JobBOList[i].jobName + ' ' + this.ddObj.datascreen6JobBOList[i].jobPersonCount + '人';
                    obj.itemStyle = {
                        color: `rgb(${parseInt(Math.random()*255)},${parseInt(Math.random()*255)},${parseInt(Math.random()*255)})`
                    }
                    list.push(obj)
                }

                this.$nextTick(function () {
                    this.$refs.jdry.Rydata = list;
                    this.$refs.jdry.actualCount();
                })
            })
        },
        doPlay(_url){
            this.qa.getAccessToken({}).then(res => {
            this.player =  new EZUIKit.EZUIKitPlayer({
                autoplay: true,
                id: "video111",
                accessToken: res.data.accessToken,
                url: _url,
                template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                // 视频上方头部控件
                //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                //plugin: ['talk'],                       // 加载插件，talk-对讲
                // 视频下方底部控件
                // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                // openSoundCallBack: data => console.log("开启声音回调", data),
                // closeSoundCallBack: data => console.log("关闭声音回调", data),
                // startSaveCallBack: data => console.log("开始录像回调", data),
                // stopSaveCallBack: data => console.log("录像回调", data),
                // capturePictureCallBack: data => console.log("截图成功回调", data),
                // fullScreenCallBack: data => console.log("全屏回调", data),
                // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                width: 3080,
                height: 1560
            });
            });
        },
        transitionFun(_url,_title) {

           
            this.doPlay(_url);

            this.show = !this.show;
            this.vedioTitle = _title;
        }
    }
}
</script>

<style scoped>
    .item-box {
      position: relative;  
    }
    .box_j {
        width: 61px;
        height: 61px;
        position: absolute;
    }
    .lt_ {
        background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        left: -5px;
    }
    .rt_ {
        background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
        background-size: 100% 100%;
        top: -5px;
        right: -5px;
    }
    .lb_ {
        background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
        background-size: 100% 100%;
        left: -5px;
        bottom: -5px;
    }
    .rb_ {
        background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
        background-size: 100% 100%;
        right: -5px;
        bottom: -5px;
    }
    .test {
        color: #ccc;
        font-size: 100px;
    }
    .map-box {
        position: relative;
    }
    .map-left {
        margin-top: 247px;
        position: relative;
        z-index: 999;
    }
    .map-left-item {
        width: 798px;
        height: 311px;
        display: flex;
        align-items: center;
        margin-bottom: 135px;
        background: url(../../../assets/images/dataScreen/ds1_bg_box.png) no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding-left: 68px;
    }
    .map-left-icon {
        width: 261px;
        height: 228px;
        background: url(../../../assets/images/dataScreen/ds_icon2.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-2 {
        background: url(../../../assets/images/dataScreen/ds_icon3.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-left-icon-3 {
        background: url(../../../assets/images/dataScreen/ds_icon8.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-img {
        width: 3518px;
        height: 3243px;
        background: url(../../../assets/images/dataScreen/map1.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -80px;
        left: 540px;
        z-index: 100;
    }
    .map-text-box {
        box-sizing: border-box;
        padding: 70px 0 0 170px;
        width: 1302px;
        height: 678px;
        position: relative;
        left: 909px;
        top: 474px;
        background: url(../../../assets/images/dataScreen/malinshu-map-text.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-text-boxs {
        box-sizing: border-box;
        position: relative;
        width: 519px;
        height: 200px;
        left: 670px;
        top: 1445px;
        background: url(../../../assets/images/dataScreen/4-qiye.png) no-repeat;
        background-size: 100% 100%;
    }
    .map-text-boxs-title {
        font-size: 49px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #2CFFFF;
        text-align: right;
        padding-top: 15px;
        padding-right: 25px;
    }
    .map-text-box-title {
        color: #fff;
        font-size: 70px;
    }
    .map-text-box-title-2 {
        color: #fff;
        opacity: .8;
        font-size: 53px;
    }
    .right {
        /*width: 8173px;*/
        margin-left: 3400px;
    }
    .header {
        width: 7920px;
        height: 72px;
        background: url(../../../assets/images/dataScreen/s_title.png) no-repeat;
        background-size: 100% 100%;
        
        text-align: center;
        
        margin: 30px auto 0;
    }
    .header-text {
        width: 7920px;
        height: 72px;
        color: #fff;
        font-size: 90px;
        margin: 40px auto 0;
        text-align: center;
    }
    .content_left {
        width: 3157px;
        height: 2779px;
        background: rgba(14, 22, 57, 0.78);
        border: 5px solid rgba(18, 49, 113, 0.78);
        /*opacity: 0.78;*/
        margin-top: 86px;
        padding-left: 39px;
    }
    .content-top-left-corner {
        position: absolute;
        top: -4px;
        left: -4px;
        width: 59px;
        height: 59px;
        background: url(../../../assets/images/dataScreen/ysSt-jiao.png) no-repeat;
    }
    .content-bottom-right-corner {
        position: absolute;
        right: -4px;
        bottom: -4px;
        width: 59px;
        height: 59px;
        background: url(../../../assets/images/dataScreen/zxSt-jiao.png) no-repeat;
    }
    .content-top-title {
        width: 1043px;
        height: 147px;
        line-height: 147px;       
        padding-left: 60px; 
        margin-top: 45px;
        background: url(../../../assets/images/dataScreen/title-left.png) no-repeat;
        font-size: 75px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
    }
    .content-item {
        width: 3092px;
        height: 2502px;
        background: rgba(3, 115, 201, 0.18);
        border: 5px solid rgba(71, 214, 255, 0.18);
        /*opacity: 0.18;*/
        margin-top: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content-item-img {
        width: 3092px;
        height: 2502px;
        background: red;
    }
    .content-item-title {
        width: 1440px;
        height: 164px;
        line-height: 164px;
        text-align: right;
        padding-right: 56px;
        position: absolute;
        bottom: 35px;
        right: 29px;
        background: url(../../../assets/images/dataScreen/title-right.png) no-repeat;
        background-size: 100%;
        font-size: 64px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
    }
    .base-box {
        width: 2076px;
        height: 2779px;
        background: rgba(14, 22, 57, 0.78);
        border: 5px solid rgba(71, 214, 255, 0.18);
        margin-top: 86px;
        margin-left: 83px;
        padding: 105px 84px 65px 82px;
    }
    .base-title-box {
        display: flex;
        align-items: center;
    }
    .base-title {
        /*width: 392px;*/
        /*height: 61px;*/
        font-size: 66px;
        font-family: FZZDHJW;
        font-weight: 600;
        color: #FFFFFF;
        margin-right: 70px;
    }
    .base-title-img {
        width: 235px;
        height: 84px;
        background: url(../../../assets/images/dataScreen/tit-bg.png) no-repeat;
        background-size: 200px 49px;
        background-position: 30px 25px;
    }
    .base-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 110px;
    }
    .base-content-item {
        min-width: 475px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .item-title {
        /*width: 392px;*/
        font-size: 66px;
        font-family: FZZDHJW;
        white-space: nowrap;
        font-weight: normal;
        color: #FFFFFF;
        text-align: center;
    }
    .item-text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
    }
    .item-text-num {
        display: inline-block;
        font-size: 86px;
        font-weight: 600;
        color: RGBA(35, 209, 255, 1);
        margin-right: 10px;
    }
    .item-text-company {
        display: inline-block;
        font-size: 66px;
        color: #fff;
    }
    .item-img {
        width: 7px;
        height: 132px;
        background: url(../../../assets/images/dataScreen/border.png) no-repeat;
    }
    .planting-area-content {
        width: 810px;
        height: 100px;
        background: RGBA(32, 63, 118, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 45px;
        padding-right: 45px;
        color: #FFFFFF;
        margin-top: 45px;        
    }
    .planting-area-contents {
        height: 100px;
        background: RGBA(32, 63, 118, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 45px;
        padding-right: 45px;
        color: #FFFFFF;
        margin-top: 45px;  
    }
    .planting-area-item {
        min-width: 154px;
        font-size: 46px;
    }
    .item-line {
        width: 5px;
        height: 76px;
        background: #FFFFFF;
    }
    .base-information-box {
        width: 1938px;
        height: 690px;
       /* background: url(../../../assets/images/dataScreen/renyuan.png) no-repeat;*/
        background-size: 100% 100%;
    }
    .charts1 {
        width: 50%;
        height: 100%;
        position: relative;
    }
    .base-information-boxs {
        width: 100%;
        height: 690px;
        display: flex;
        justify-content: space-between;
        padding-right: 61px;
        margin-top: 107px;
    }
    .base-img {
        width: 816px;
        height: 514px;
        border: 5px solid rgba(8, 110, 148, 1);
        background: red;
        position: relative;
    }
    .base-text {
        font-size: 53px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #FFFFFF;
        position: absolute;
        bottom: 38px;
        right: 47px;
    }
    .farm {
        width: 1852px;
        height: 2779px;
        background: rgba(14, 22, 57, 0.78);
        border: 5px solid #123171;
        margin-left: 91px;
        margin-top: 86px;
        padding: 105px 0 0 87px;
    }
    .farm-item-box {
        padding-left: 31px;
        display: flex;
        flex-wrap: wrap;
    }
    .farm-item {
        width: 790px;
        height: 442px;
        background: url(../../../assets/images/dataScreen/nongchang.png) no-repeat;
        background-size: 100% 100%;
        padding: 10px 90px 0 81px;
        margin-right: 36px;
        margin-top: 111px;        
    }
    .farm-item-title {
        height: 62px;
        font-size: 67px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #EEC915;
        line-height: 43px;
        text-align: center;     
        margin-top: -30px;   
    }
    .farm-item-subheading {
        height: 63px;
        font-size: 68px;
        font-family: Swiss721BT;
        font-weight: 800;
        color: #EEC915;
        line-height: 75px;
        text-align: center;
        margin-top: 58px;
    }
    .farm-item-list-box {
        display: flex;
        justify-content: space-between;
        font-size: 46px;
        font-family: NotoSansHans;
        font-weight: 400;
        color: #FFFFFF;
    }
    .list-right {
        font-weight: 500;
        color: #0291C9;
    }

    .weather-dashboard {
        height: 674px;
    }
    .weather-left {
        width: 672px;
        height: 674px;
        line-height: 604px;
        background: url(../../../assets/images/dataScreen/ybp.png) no-repeat;
        background-size: 100%;
        font-size: 200px;
        text-align: center;
        color: #FFFFFF;
    }
    .rank-box {
        width: 618px;
        height: 398px;
        color: #ccc;
    } 
    .supplies-item {
        width: 230px;
        text-align: left;
    }

    .supplies-line-box {
        width: 403px;
        height: 35px;
        position: relative;
    }

    .supplies-line {
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, rgba(45, 233, 180, 0.1), #2DE9B4);
    }

    .supplies-line-blue {
        width: 100%;
        height: 8px;
        background: linear-gradient(90deg, rgba(0, 90, 238, 0.1), #005AEE);
    }
    
    .supplies-inner-circle {
        width: 17px;
        height: 17px;
        background: #77E9D8;
        border-radius: 50%;
        position: absolute;
        right: 0;
    }
    .supplies-inner-circle-blue {
        background: #28BEFD;
    }
    .supplies-outer-circle {
        width: 50px;
        height: 50px;
        border: 6px solid rgba(45, 233, 180, 0.3);
        box-shadow: 0px 2px 10px 0px rgba(45, 233, 180, 0.8);
        border-radius: 50%;
        position: absolute;
        right: -17px;
    }
    .supplies-outer-circle-blue {
        box-shadow: 0px 2px 10px 0px rgba(0, 90, 238, 0.8);
    }

    
.weather-station {
    width: 2076px;
    height: 2779px;
    /*background: rgba(14, 22, 57, 1);*/
    border: 5px solid rgba(18, 49, 113, 1);
    margin-top: 86px;
    margin-left: 84px;
    padding: 79px 47px 73px 66px;
}

.weather-dashboard {
    height: 674px;
}

.rank-box {
    width: 618px;
    height: 398px;
    color: #ccc;
}

.supplies-item {
    width: 230px;
    text-align: left;
}

.supplies-line-box {
    width: 403px;
    height: 35px;
    position: relative;
}

.supplies-line {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, rgba(45, 233, 180, 0.1), #2DE9B4);
}

.supplies-line-blue {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, rgba(0, 90, 238, 0.1), #005AEE);
}

.supplies-inner-circle {
    width: 17px;
    height: 17px;
    background: #77E9D8;
    border-radius: 50%;
    position: absolute;
    right: 0;
}

.supplies-inner-circle-blue {
    background: #28BEFD;
}

.supplies-outer-circle {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(45, 233, 180, 0.3);
    box-shadow: 0px 2px 10px 0px rgba(45, 233, 180, 0.8);
    border-radius: 50%;
    position: absolute;
    right: -17px;
}

.supplies-outer-circle-blue {
    box-shadow: 0px 2px 10px 0px rgba(0, 90, 238, 0.8);
}

.weather-left {
    width: 672px;
    height: 674px;
    line-height: 604px;
    background: url(../../../assets/images/dataScreen/ybp.png) no-repeat;
    background-size: 100%;
    font-size: 200px;
    text-align: center;
    color: #FFFFFF;
    float: left;
}

.weather-right {
    float: left;
    width: 1100px;
    height: 600px;
    margin-left: 100px;
    margin-top: 50px;

}

.weather-right>div {
    height: 110px;
    font-size: 50px;
}

div.qxzKeyCls {
    float: left;
    color: #00de1e;
}

div.qxzKeyCls.qxzIndex1Cls {
    background: url(../../../assets/images/dataScreen/wlsb1.png) no-repeat;
    background-size: 72px 67px;
    background-position: left 10px;
    text-indent: 100px;
}

div.qxzKeyCls.qxzIndex2Cls {
    background: url(../../../assets/images/dataScreen/wlsb2.png) no-repeat;
    background-size: 52px 52px;
    background-position: 100px 10px;
    text-indent: 200px;
}

div.qxzKeyCls.qxzIndex3Cls {
    background: url(../../../assets/images/dataScreen/wlsb3.png) no-repeat;
    background-size: 52px 52px;
    background-position: 200px 10px;
    text-indent: 300px;
}

div.qxzKeyCls.qxzIndex4Cls {
    background: url(../../../assets/images/dataScreen/wlsb4.png) no-repeat;
    background-size: 52px 52px;
    background-position: 200px 10px;
    text-indent: 300px;
}

div.qxzKeyCls.qxzIndex5Cls {
    background: url(../../../assets/images/dataScreen/wlsb5.png) no-repeat;
    background-size: 52px 52px;
    background-position: 100px 10px;
    text-indent: 200px;
}

div.qxzKeyCls.qxzIndex6Cls {
    background: url(../../../assets/images/dataScreen/wlsb6.png) no-repeat;
    background-size: 52px 52px;
    background-position: left 10px;
    text-indent: 100px;
}

.weather-right>div>div:nth-child(2) {
    float: right;
    color: #fff;

}

div.qxzStatusCls {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    float: left;
    margin-top: 20px;
    margin-right: 20px;
}

div.qxzValCls {
    float: left;

}

div.qxzStatusCls.s1Cls {
    background-color: #00de1e;

}

div.qxzStatusCls.s2Cls {
    background-color: red;

}

div.qxzStatusCls.s3Cls {
    background-color: #ffcb00;

}

div.jttrzbjcRowCls>div {
    font-size: 45px;
    float: left;
    text-align: center;
    height: 150px;
}

div.jttrzbjcHeaderCls>div {
    font-size: 45px;
    color: #fff;
    float: left;
    background-size: 206px 185px;
    background-repeat: no-repeat;
    background-position: top center;
    height: 330px;
    padding-top: 220px;
    text-align: center;
}

div.jttrzbjcHeaderCls>div:nth-child(1) {
    background-image: url(../../../assets/images/dataScreen/turanzb1.png);

}

div.jttrzbjcHeaderCls>div:nth-child(2) {
    background-image: url(../../../assets/images/dataScreen/turanzb2.png);

}

div.jttrzbjcHeaderCls>div:nth-child(3) {
    background-image: url(../../../assets/images/dataScreen/turanzb3.png);

}

div.jttrzbjcHeaderCls>div:nth-child(4) {
    background-image: url(../../../assets/images/dataScreen/turanzb4.png);

}

div.jttrzbjcHeaderCls>div:nth-child(5) {
    background-image: url(../../../assets/images/dataScreen/turanzb5.png);

}

div.jttrzbjcHeaderCls>div:nth-child(6) {
    background-image: url(../../../assets/images/dataScreen/turanzb6.png);

}

div.jttrzbjcColumnCls>div:nth-child(1) {
    width: 400px;
}

div.jttrzbjcColumnCls>div:nth-child(2) {
    width: 300px;
}

div.jttrzbjcColumnCls>div:nth-child(3) {
    width: 300px;
}

div.jttrzbjcColumnCls>div:nth-child(4) {
    width: 300px;
}

div.jttrzbjcColumnCls>div:nth-child(5) {
    width: 300px;
}

div.jttrzbjcColumnCls>div:nth-child(6) {
    width: 300px;
}

div.jttrzbjcRowCls>div:nth-child(1) {
    color: #9fd80d;
}

div.jttrzbjcRowCls>div:nth-child(2) {
    color: #e5d500;
}

div.jttrzbjcRowCls>div:nth-child(3) {
    color: #00e73c;
}

div.jttrzbjcRowCls>div:nth-child(4) {
    color: #e5d500;
}

div.jttrzbjcRowCls>div:nth-child(5) {
    color: #00ecef;
}

div.jttrzbjcRowCls>div:nth-child(6) {
    color: #c155ed;
}

div.rewuyujinCls {
    width: 100%;
    height: 710px;
}
div.rewuyujinCls>div.rwssjdCls{
    height: 100%;
    width: 50%;
    float: left;
}
div.rewuyujinCls>div.nsyjCls{
    height: 100%;
    width: 50%;
    float: left;

}
div.rwmcCls{
    margin-top: 50px;
    background-size: 593px 98px;
    width: 593px;
    height: 98px;
    line-height: 98px;
    background-image: url(../../../assets/images/dataScreen/rwbg1.png);
}
div.rwmcCls>div:nth-child(1){
    float: left;
    color: #fff;
    font-size: 40px;
    margin-left: 70px;
    background-image: url(../../../assets/images/dataScreen/fwd1.png);
    background-size: 50px 50px;
    background-position: center left;
    background-repeat: no-repeat;
    text-indent: 70px;
}
div.rwmcCls>div:nth-child(2){
    color: #eddd03;
    float: right;
    font-size: 50px;
    margin-right: 100px;
}
div.renwuBoxCls{
    border: 5px solid #25a1ba;
    margin-top: 50px;
    background-size: 593px 98px;
    width: 593px;
    height: 98px;
    line-height: 98px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
}
div.renwuBoxCls>div:nth-child(1){
    float: left;
    background-color: #365acb;
    color: #f4f17d;
    font-size: 30px;
    text-indent: 50px;
    padding-right: 40px;
}
div.renwuBoxCls>div:nth-child(2){
    float: left;
    background-color: #c2be6c;
    color: #0160fc;
    font-size: 30px;
    text-indent: 50px;
    flex: 1;
}
div.nsyjBoxCls{
    margin-top: 80px;
    margin-bottom: 80px;
    background-size: 694px 61px;
    width: 694px;
    height: 61px;
    line-height: 61px;
    background-image: url(../../../assets/images/dataScreen/rwbg2.png);

}

div.nsyjBoxCls>div:nth-child(1){
    background-image: url(../../../assets/images/dataScreen/baojin.png);
    background-size: 30px 30px;
    background-position: 30px center;
    background-repeat: no-repeat;
    text-indent: 100px;
    font-size: 32px;
    color: #fff;
    float: left;
    
}

div.nsyjBoxCls>div:nth-child(2){
    font-size: 25px;
    color: #fff;
    float: right;
    margin-right: 40px;
    
}
</style>