<template>
    <div class="dataScreenContent">
        <div class="tjCls">
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon1Cls"></div>
                <div class="numCls">{{jiagongObj.processingEnterprisesCount || 0}}</div>
                <div class="titleCls">入园涉农加工企业（家）</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon2Cls"></div>
                <div class="numCls">{{jiagongObj.iotEquipmentCount || 0}}</div>
                <div class="titleCls">年度总产值（万元）</div>
            </div>
        </div>
        <div class="mapCls" style="position: relative">
            <baidu-map :center="center" v-if="isTrue" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
            </baidu-map>
            <!-- <div class="flex" v-for="(item,index) in jiagongObj.datascreen15CompanyPOList" :key="index" style="position: absolute; align-items: center" :style="{left: item.xAxis+'px', top: item.yAxis+'px'}">
                 <div v-if="item.position == 1" style="background: rgba(71, 214, 255, 0.5); height: 100px; font-size: 48px; color: #fff; line-height: 100px; padding: 0 30px">{{item.companyName}}</div>
                 <img v-if="item.position == 1" src="../../../assets/images/dataScreen/15left.png" alt="" style="width: 36px; height: 43px">
                 
                <img src="../../../assets/images/dataScreen/15biao.png" alt="" style="width: 58px; height: 68px; margin: 0 30px">
                <img v-if="item.position == 2" src="../../../assets/images/dataScreen/15right.png" alt="" style="width: 36px; height: 43px">
                <div v-if="item.position == 2" style="background: rgba(71, 214, 255, 0.5); height: 100px; font-size: 48px; color: #fff; line-height: 100px; padding: 0 30px">{{item.companyName}}</div>
            </div> -->
        </div>
        <div class="animate__animated animate__fadeInRight">
            <div class="dsBox3">
                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">{{jiagongObj.enterprises1Name}}</div>
                    </div>
                    <div class="qiyetongjiBoxCls">
                        <div>
                            <div>{{jiagongObj.enterprises1Value1}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises1Unit1}}</span></div>
                            <div>{{jiagongObj.enterprises1Attr1}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises1Value2}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises1Unit2}}</span></div>
                            <div>{{jiagongObj.enterprises1Attr2}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises1Value3}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises1Unit3}}</span></div>
                            <div>{{jiagongObj.enterprises1Attr3}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises1Value4}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises1Unit4}}</span></div>
                            <div>{{jiagongObj.enterprises1Attr4}}</div>
                        </div>
                    </div>
                    <!-- <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5" v-if="isScroll1"> -->
                        <!-- <div class="qiyeContentCls">
                            <div class="qiyeContentClsBox">{{jiagongObj.enterprises1Desc}}</div>
                        </div> -->
                    <!-- </marquee> -->
                    <div class="qiyeContentClsShow">
                        <div>{{jiagongObj.enterprises1Desc}}</div>
                    </div>
                </div>

                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">{{jiagongObj.enterprises2Name}}</div>
                    </div>
                    <div class="qiyetongjiBoxCls">
                        <div>
                            <div>{{jiagongObj.enterprises2Value1}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises2Unit1}}</span></div>
                            <div>{{jiagongObj.enterprises2Attr1}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises2Value2}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises2Unit2}}</span></div>
                            <div>{{jiagongObj.enterprises2Attr2}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises2Value3}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises2Unit3}}</span></div>
                            <div>{{jiagongObj.enterprises2Attr3}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises2Value4}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises2Unit4}}</span></div>
                            <div>{{jiagongObj.enterprises2Attr4}}</div>
                        </div>
                    </div>
                    <!-- <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5" v-if="isScroll2"> -->
                        <!-- <div class="qiyeContentCls">
                            <div class="qiyeContentClsBox">{{jiagongObj.enterprises2Desc}}</div>
                        </div> -->
                    <!-- </marquee> -->
                    <div class="qiyeContentClsShow" >
                        <div>{{jiagongObj.enterprises2Desc}}</div>
                    </div>
                </div>



                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">{{jiagongObj.enterprises3Name}}</div>
                    </div>
                    <div class="qiyetongjiBoxCls">
                        <div>
                            <div>{{jiagongObj.enterprises3Value1}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises3Unit1}}</span></div>
                            <div>{{jiagongObj.enterprises3Attr1}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises3Value2}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises3Unit2}}</span></div>
                            <div>{{jiagongObj.enterprises3Attr2}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises3Value3}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises3Unit3}}</span></div>
                            <div>{{jiagongObj.enterprises3Attr3}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises3Value4}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises3Unit4}}</span></div>
                            <div>{{jiagongObj.enterprises3Attr4}}</div>
                        </div>
                    </div>
                    <!-- <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5" v-if="isScroll3"> -->
                        <!-- <div class="qiyeContentCls">
                            <div class="qiyeContentClsBox">{{jiagongObj.enterprises3Desc}}</div>
                        </div> -->
                    <!-- </marquee> -->
                    <div class="qiyeContentClsShow">
                        <div>{{jiagongObj.enterprises3Desc}}</div>
                    </div>
                </div>

                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">{{jiagongObj.enterprises4Name}}</div>
                    </div>
                    <div class="qiyetongjiBoxCls">
                        <div>
                            <div>{{jiagongObj.enterprises4Value1}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises4Unit1}}</span></div>
                            <div>{{jiagongObj.enterprises4Attr1}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises4Value2}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises4Unit2}}</span></div>
                            <div>{{jiagongObj.enterprises4Attr2}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises4Value3}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises4Unit3}}</span></div>
                            <div>{{jiagongObj.enterprises4Attr3}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises4Value4}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises4Unit4}}</span></div>
                            <div>{{jiagongObj.enterprises4Attr4}}</div>
                        </div>
                    </div>
                    <!-- <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5" v-if="isScroll4"> -->
                        <!-- <div class="qiyeContentCls">
                            <div class="qiyeContentClsBox">{{jiagongObj.enterprises4Desc}}</div>
                        </div> -->
                    <!-- </marquee> -->
                    <div class="qiyeContentClsShow" >
                        <div>{{jiagongObj.enterprises4Desc}}</div>
                    </div>
                </div>

                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">{{jiagongObj.enterprises5Name}}</div>
                    </div>
                    <div class="qiyetongjiBoxCls">
                        <div>
                            <div>{{jiagongObj.enterprises5Value1}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises5Unit1}}</span></div>
                            <div>{{jiagongObj.enterprises5Attr1}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises5Value2}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises5Unit2}}</span></div>
                            <div>{{jiagongObj.enterprises5Attr2}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises5Value3}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises5Unit3}}</span></div>
                            <div>{{jiagongObj.enterprises5Attr3}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises5Value4}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises5Unit4}}</span></div>
                            <div>{{jiagongObj.enterprises5Attr4}}</div>
                        </div>
                    </div>
                    <!-- <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5" v-if="isScroll5"> -->
                        <!-- <div class="qiyeContentCls">
                            <div class="qiyeContentClsBox">{{jiagongObj.enterprises5Desc}}</div>
                        </div> -->
                    <!-- </marquee> -->
                    <div class="qiyeContentClsShow">
                        <div>{{jiagongObj.enterprises5Desc}}</div>
                    </div>
                </div>

                <div class="yzcyCls">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">{{jiagongObj.enterprises6Name}}</div>
                    </div>
                    <div class="qiyetongjiBoxCls">
                        <div>
                            <div>{{jiagongObj.enterprises6Value1}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises6Unit1}}</span></div>
                            <div>{{jiagongObj.enterprises6Attr1}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises6Value2}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises6Unit2}}</span></div>
                            <div>{{jiagongObj.enterprises6Attr2}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises6Value3}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises6Unit3}}</span></div>
                            <div>{{jiagongObj.enterprises6Attr3}}</div>
                        </div>
                        <div>
                            <div>{{jiagongObj.enterprises6Value4}}<span style="font-size:48px;font-weight: normal;">{{jiagongObj.enterprises6Unit4}}</span></div>
                            <div>{{jiagongObj.enterprises6Attr4}}</div>
                        </div>
                    </div>
                    <!-- <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5" v-if="isScroll6">
                        <div class="qiyeContentCls">
                            <div class="qiyeContentClsBox">{{jiagongObj.enterprises6Desc}}</div>
                        </div>
                    </marquee> -->
                    <div class="qiyeContentClsShow">
                        <div>{{jiagongObj.enterprises6Desc}}</div>
                    </div>
                </div>
            </div>

            <div class="yzcyBCls">
                <div class="ysStCls"></div>
                <div class="zxStCls"></div>
                <div class="vTitleCls">农业产业园区入驻的部分加工企业</div>
                <div class="vBoxCls" v-if="isTrue">
                    <div class="vconBoxCls" v-for="(item, i) in jiagongObj.deviceEnterpriseRelationBOS" :key="i">
                        <div class="vedioCls" >
                            <!-- <EZUIKitJs msg="video3" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio1Url" /> -->
                            <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="environmentalAttribute" :flv="item.deviceUrl" />
                            <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="environmentalAttribute" :flv="item.deviceUrl"/>
                            <div class="vedioTitleCls"
                                @click="transitionFun(item.deviceUrl, item.installArea)">
                                {{item.installArea}}</div>
                        </div>
                    </div>
                     <!-- <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video1" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio2Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(jiagongObj.jidiVedio2Url, jiagongObj.jidiVedio2Title)">{{jiagongObj.jidiVedio2Title}}
                            </div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video2" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio3Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(jiagongObj.jidiVedio3Url, jiagongObj.jidiVedio3Title)">
                                {{jiagongObj.jidiVedio3Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">

                        <div class="vedioCls">
                            <EZUIKitJs msg="video4" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio4Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(jiagongObj.jidiVedio4Url, jiagongObj.jidiVedio4Title)">
                                {{jiagongObj.jidiVedio4Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video5" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio5Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(jiagongObj.jidiVedio5Url, jiagongObj.jidiVedio5Title)">
                                {{jiagongObj.jidiVedio5Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video6" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio6Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(jiagongObj.jidiVedio6Url, jiagongObj.jidiVedio6Title)">
                                {{jiagongObj.jidiVedio6Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video7" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio7Url" />
                            <div style="font-size:50px" class="vedioTitleCls"
                                @click="transitionFun(jiagongObj.jidiVedio7Url, jiagongObj.jidiVedio7Title)">
                                {{jiagongObj.jidiVedio7Title}}</div>
                        </div>
                    </div>
                   <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video8" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio8Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(jiagongObj.jidiVedio8Url, jiagongObj.jidiVedio8Title)">
                                {{jiagongObj.jidiVedio8Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video9" :attribute="environmentalAttribute"
                                :flv="jiagongObj.jidiVedio9Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(jiagongObj.jidiVedio9Url, jiagongObj.jidiVedio9Title)">
                                {{jiagongObj.jidiVedio9Title}}</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">


                    </div>
                    <div class="video-centent">
                        <div id="video111" :attribute="environmentalAttribute2" flv="" />
                        <div class="video-title">{{ vedioTitle }}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import Highecharts1 from '../highecharts/dataScreen1/highecharts1.vue'
import 'animate.css';
export default {
    name: '',
    components: {
        EZUIKitJs,
        EZUIKitJs2,
        Highecharts1
    },
    data() {
        return {
            timeNum: 0,
            environmentalAttribute: {
                width: 924,
                height: 710
            },
            tableData: [],
            townList: [],
            potatoData:[],
            isTrue: false,
            jiagongObj: {},
            show: false,
            vedioTitle: "",
            player: null,
            isScroll1: true,
            isScroll2: true,
            isScroll3: true,
            isScroll4: true,
            isScroll5: true,
            isScroll6: true,
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12
        }
    },

    mounted() {
        this.init();
        this.doPlay("");
        var timer = setInterval(() => {
            ++this.timeNum
            console.log(this.timeNum)
            if (this.timeNum > 4) {
                clearInterval(timer)
            }
        }, 50)
    },
    methods: {
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/15biao.png"), new BMap.Size(60, 86), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(10, 25),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].xAxis, this.potatoData[i].yAxis);   
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   
                var content = '<div style="text-align: center">'+this.potatoData[i].companyName+'</div><div style="text-align: center">'+this.potatoData[i].remark+'</div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-120, -200)
                })  
 
                
                map.addOverlay(label);

                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")',
                    backgroundColor: 'transparent',

                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
                
                
            }
            for(let i = 0; i < this.townList.length; i++) {
                // if(this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) {
                var point = new BMap.Point(this.townList[i].mapX, this.townList[i].mapY);   
                var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.townList[i].townshipName + '</div><div>'+this.townList[i].yuliu1 +'</div>'+'<div>'+this.townList[i].yuliu2+'</div>'+'<div>'+this.townList[i].yuliu3+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-200, -368)
                })  
                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: (this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")' : '',
                    backgroundColor: 'transparent',
                    height: '348px',
                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
                
                // }
            }

            var yhPoint = new BMap.Point(this.mlsObj.yhList[0].mapX,this.mlsObj.yhList[0].mapY);
            var yhMaker = new BMap.Marker(yhPoint, {icon: myIcon});
            map.addOverlay(yhMaker);   
             var yhContent = this.mlsObj.yhList[0].enterpriseName + '<br />'+ this.mlsObj.yhList[0].enterpriseValue + '亩';
                var yhLabel = new BMap.Label(yhContent, {       // 创建文本标注
                    position: yhPoint,
                    offset: new BMap.Size(20, -240)
                })  

                map.addOverlay(yhLabel);
                var that = this;
            yhLabel.addEventListener('click', function(){
                that.$parent.tabsTypeClick('4');
            })
            yhLabel.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    height: '208px',
                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            // var marker = new BMap.Marker(point) // 创建标注
            // map.addOverlay(marker) // 将标注添加到地图中
            // var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            // map.addOverlay(circle)
            map.enableScrollWheelZoom(true);
        },
        init(){
            this.qa.datascreen15TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
            })
            this.qa.queryDatascreen15ById({}).then(res => {
                this.jiagongObj = res.data;
                this.potatoData = res.data.datascreen15CompanyPOList;
                this.isTrue = true;
                this.$nextTick(() => {
                    let dom = document.getElementsByClassName('qiyeContentCls');
                    let dom1 = document.getElementsByClassName('qiyeContentClsBox');
                    if (dom1[0].offsetHeight < dom[0].offsetHeight) {
                        this.isScroll1 = false;
                    }
                    if (dom1[1].offsetHeight < dom[1].offsetHeight) {
                        this.isScroll2 = false;
                    }
                    if (dom1[2].offsetHeight < dom[2].offsetHeight) {
                        this.isScroll3 = false;
                    }
                    if (dom1[3].offsetHeight < dom[3].offsetHeight) {
                        this.isScroll4 = false;
                    }
                    if (dom1[4].offsetHeight < dom[4].offsetHeight) {
                        this.isScroll5 = false;
                    }
                    if (dom1[5].offsetHeight < dom[5].offsetHeight) {
                        this.isScroll6 = false;
                    }
                })
            })
            // this.qa.datascreen15queryForList({}).then(res => {
            //     this.tableData = res.data;
            // })
        },
        goDataScreen2() {
            this.$emit('tabsTypeClick', '2');
        },
        doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },
        transitionFun(_url, _title) {


            this.doPlay(_url);

            this.show = !this.show;
            this.vedioTitle = _title;
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
    transform: translateX(100px);
    opacity: 0;
}

.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videdo-box {
    display: flex;
    align-items: center;
}

.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}

.video-title {
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}

.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;
}

.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.dataScreenContent {
    color: #ccc;
    font-size: 100px;
}

div.dsBgbox {
    background-image: url("../../../assets/images/dataScreen/ds1_bg_box.png");
    width: 809px;
    height: 320px;
    background-size: 100% 100%;
    position: relative;
    font-size: 101px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #23D1FF;
}

div.dsBgbox>div.dsIcon1Cls {
    background-image: url("../../../assets/images/dataScreen/ds_icon3.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.dsIcon2Cls {
    background-image: url("../../../assets/images/dataScreen/ds_icon8.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.numCls {
    width: 100%;
    margin-left: 260px;
    padding-top: 40px;
}

div.dsBgbox>div.titleCls {
    width: 100%;
    height: 228px;
    margin-left: 270px;
    font-size: 51px;
    color: #FFFFFF;
}

div.numCls>span.dwCls {
    font-size: 40px;
}

div.tjCls,
div.mapCls {
    float: left;
    position: relative;
    z-index: 999;
}

div.tjCls>div:nth-child(n+1) {
    margin-top: 135px;
}

div.mapCls {
    margin-top: -150px;
    width: 3887px;
    height: 3176px;
    margin-left: -800px;
    position: relative;
    background-image: url("../../../assets/images/dataScreen/map.png");
    z-index: 100;
}

div.mapCls>div.yangCls {
    position: absolute;
    top: 1000px;
    left: 1800px;
    background-image: url("../../../assets/images/dataScreen/yang1.png");
    width: 1341px;
    height: 618px;
    padding-top: 65px;
    font-weight: bolder;
    padding-left: 150px;
   
}

div.mapCls>div.yangCls>div:nth-child(1) {
    font-size: 75px;

}

div.mapCls>div.yangCls>div:nth-child(2) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 100px;
}

div.mapCls>div.yangCls>div:nth-child(3) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 20px;
}

div.dsBox3 {
    margin-left: 200px;
    float: left;
    width: 6096px;
    height: 3000px;
}

div.dsBox3>div.yzcyCls:nth-child(n+4) {
    margin-top: 50px;
}

div.dsBox3>div.yzcyCls {
    float: left;
    width: 1940px;
    height: 1481px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
    margin-left: 50px;
}

div.dsBox3>div.yzcyCls>div.qiyeTitleBoxCls {
    position: relative;
    font-weight: bolder;
    font-size: 75px;
    background-image: url("../../../assets/images/dataScreen/zhizhi.png");
    background-size: 1650px 71px;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
}

div.dsBox3>div.yzcyCls>div.qiyeTitleBoxCls>div.fnt {
    text-align: center !important;
}


div.ysStCls {
    background-image: url("../../../assets/images/dataScreen/ysSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    top: -8px;
    left: -8px;

}

div.zxStCls {
    background-image: url("../../../assets/images/dataScreen/zxSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    bottom: -8px;
    right: -8px;

}

div.yzcyCls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzcy2Cls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzTableHeaderCls {
    margin-top: 40px;
}

div.yzTableHeaderCls>div {
    float: left;
    color: #A4DF11;
    font-size: 59px;
}

div.yzTableHeaderCls>div:nth-child(1),
div.yzTableRowCls>div:nth-child(1) {
    width: 300px;
}

div.yzTableHeaderCls>div:nth-child(2),
div.yzTableRowCls>div:nth-child(2) {
    width: 250px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(3),
div.yzTableRowCls>div:nth-child(3) {
    width: 275px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(4),
div.yzTableRowCls>div:nth-child(4) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(5),
div.yzTableRowCls>div:nth-child(5) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(6),
div.yzTableRowCls>div:nth-child(6) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(7),
div.yzTableRowCls>div:nth-child(7) {
    width: 300px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(8),
div.yzTableRowCls>div:nth-child(8) {
    width: 275px;
    text-align: right;
}

div.yzTableRowCls>div {
    height: 120px;
    line-height: 120px;
    float: left;
    color: #00FB40;
    font-size: 59px;
}

.marquee-list {
    margin-top: 100px;
    height: 550px;
    overflow: hidden;
}


div.vedioCls:nth-child(2n+1) {
    margin-left: 25px;
    margin-right: 0px;
}

div.vedioCls {
    width: 935px;
    height: 717px;
    background: #0E1639;
    border: 5px solid #123171;
    float: left;
    margin-top: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
}

div.vedioCls>div.vedioTitleCls {
    background-image: url("../../../assets/images/dataScreen/vedioTitlebg.png");
    position: absolute;
    font-size: 60px;
    color: #fff;
    bottom: 20px;
    right: 0px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-align: right;
    padding-right: 50px;
}

div.yzcy3Cls {
    width: 5369px;
    height: 2902px;
    margin-left: 100px;
    float: left;
    position: relative;
    background-color: #01003d;
}

div.yzcy3Cls>div.ys1Cls {
    background-image: url("../../../assets/images/dataScreen/jx1.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    left: 0;
}

div.yzcy3Cls>div.ys2Cls {
    background-image: url("../../../assets/images/dataScreen/jx2.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    right: 0;
}

div.yzcy3Cls>div.ys3Cls {
    background-image: url("../../../assets/images/dataScreen/jx3.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    right: 0;
}

div.yzcy3Cls>div.ys4Cls {
    background-image: url("../../../assets/images/dataScreen/jx4.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    left: 0;
}

div.paibianCls {
    background-image: url("../../../assets/images/dataScreen/paibian.png");
    width: 1638px;
    height: 118px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

div.yzcy3Cls>div.mapsCls {
    background-image: url("../../../assets/images/dataScreen/map.png");
    width: 2981px;
    height: 2881px;
    background-size: cover;
    margin-left: 200px;
    float: left;
    position: relative;

}

div.jaigepai1Cls {
    position: absolute;
    top: 350px;
    left: 1100px;
}

div.jaigepai2Cls {
    position: absolute;
    top: 1320px;
    left: 200px;
}

div.jaigepai3Cls {
    position: absolute;
    top: 1220px;
    left: 980px;
}

div.jaigepai4Cls {
    position: absolute;
    top: 1090px;
    left: 1970px;
}

div.jaigepai5Cls {
    position: absolute;
    top: 1550px;
    left: 1470px;
}

div.jaigepai6Cls {
    position: absolute;
    top: 2100px;
    left: 1950px;
}

div.gjiageCls {
    background-image: url("../../../assets/images/dataScreen/g448.png");
    width: 295px;
    height: 105px;
    line-height: 105px;
    text-align: center;
    font-size: 45px;

}

div.gjiageCls>span {
    font-size: 65px;
}

div.yzcy3Cls {

    overflow: hidden;
}

div.textListBoxCls {
    float: left;
    width: 1950px;
    height: 100%;
    margin-top: 200px;
    padding-left: 100px;

}

div.textListBoxCls>div.rouyangTableCls {
    width: 1950px;
    height: 1690px;

}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div {
    float: left;
    background-color: #00c29a;
    color: #fff;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls>div {
    float: left;
    color: #3de2d6;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(1),
div.bodyCls>div.rowCls>div:nth-child(1) {
    width: 400px;
    text-indent: 50px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(2),
div.bodyCls>div.rowCls>div:nth-child(2) {
    width: 650px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(3),
div.bodyCls>div.rowCls>div:nth-child(3) {
    width: 300px;
    text-align: center;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(4),
div.bodyCls>div.rowCls>div:nth-child(4) {
    width: 250px;
    text-align: center;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(5),
div.bodyCls>div.rowCls>div:nth-child(5) {
    width: 340px;
}

div.bodyCls>div.rowCls>div:nth-child(5) {
    padding-right: 40px;
}

div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls:nth-child(2n+1)>div {
    background: rgba(73, 232, 217, 0.2);
}

div.tubiaoCls {
    position: relative;
    margin-top: 50px;
}

.money {
    position: absolute;
    top: 140px;
    left: 110px;
    color: #3de2d6;
}

.days {
    right: -210px;
    bottom: 0px;
    color: #3de2d6;
    position: absolute;
}

div.zhoushititleCls {
    font-size: 74px;
    margin-bottom: 50px;
}

div.zoushituCls {
    /*background-image: url("../../../assets/images/dataScreen/111.png");*/
    width: 1951px;
    height: 674px;
}

div.qiyetongjiBoxCls {
    height: 380px;
    margin-top: 100px;
    display: flex;
    font-size: 48px;
    color: #FFFFFF;
}

div.qiyetongjiBoxCls>div {
    background-size: 283px 168px;
    background-repeat: no-repeat;
    background-position: center bottom;
    text-align: center;
    flex: 1;
}

div.qiyetongjiBoxCls>div>div {
    text-align: center;
}

div.qiyetongjiBoxCls>div>div:nth-child(1) {
    font-size: 64px;
    height: 100px;
    line-height: 100px;
    font-weight: bolder;
}

div.qiyetongjiBoxCls>div>div:nth-child(2) {
    font-size: 48px;
}

div.qiyetongjiBoxCls>div:nth-child(1) {
    background-image: url("../../../assets/images/dataScreen/qqq1.png");
}

div.qiyetongjiBoxCls>div:nth-child(2) {
    background-image: url("../../../assets/images/dataScreen/qqq2.png");
}

div.qiyetongjiBoxCls>div:nth-child(3) {
    background-image: url("../../../assets/images/dataScreen/qqq3.png");
}

div.qiyetongjiBoxCls>div:nth-child(4) {
    background-image: url("../../../assets/images/dataScreen/qqq4.png");
}

div.qiyeContentCls {
    text-indent: 100px;
    width: 100%;
    height: 420px;
    font-size: 50px;
    color: #6FB6FF;
    line-height: 80px;
    text-align: justify;
    text-justify: distribute-all-lines;
}
div.qiyeContentClsShow {
    text-indent: 100px;
    width: 100%;
    height: 420px;
    font-size: 50px;
    color: #6FB6FF;
    line-height: 80px;
    text-align: justify;
    text-justify: distribute-all-lines;
    margin-top: 70px;
}

div.yzcyBCls {
    position: relative;
    width: 3300px;
    height: 3010px;
    float: left;
    background: #0E1639;
    border: 5px solid #123171;
    padding: 85px;
}

div.yzcyBCls>div.vTitleCls {
    font-size: 74px;
    font-weight: bolder;
    height: 147px;
    line-height: 147px;
    background-image: url("../../../assets/images/dataScreen/malinshu-title.png");
    background-repeat: no-repeat;
    background-size: 1163px 147px;
    text-indent: 60px;

}

div.vBoxCls {
    width: 100%;
    height: 400px;
    margin-top: 60px;
}

div.vBoxCls>div.vconBoxCls {
    width: 995px;
    height: 800px;
    background: #0a284e;
    border: 5px solid #123354;
    float: left;
    margin-top: 60px;
    margin-right: 60px;
}

div.vBoxCls>div.vconBoxCls:nth-child(3n) {
    margin-right: 0px;
}
</style>