<template>
  <div>
    <div id="echartsds" class="echarts3"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
// @ is an alias to /src
export default {
  name: 'sheepHose',
  data(){
      return{
        yfyf: [],
        yfsl: []
      }
  },
  props: {
  },
  mounted(){

  },
  watch: {

  },
  methods:{
      man() {
       
        var chartDom = document.getElementById('echartsds');
        var myChart = echarts.init(chartDom);
        var option;
        var xData = this.yfyf
        var data = this.yfsl; //生产
        var base = [];
        var min = [];
        var max = [];
        function format(data) {
          data.forEach(function (item) {
            base.push(item[0])
            min.push({
              value: 0,
              label: {
                show: item[0] ? true : false,
                formatter: '' + item[0] + '',
                color: '#FFFFFF',
                fontSize:"45"
              },
            })
            max.push({
              value: item[1] - item[0],
              label: {
                show: item[1],
                formatter: '' + item[1] + '',
                color: '#FFFFFF',
                fontSize:"45"
              },
              itemStyle: {
                color: item[2]
              },
            })
          })
        }
        format(data);
        option = {
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          // xAxis: {
          //   type: 'category',
          //   splitLine: {
          //     show: false
          //   },
          //   data: xData,
          //   axisLabel : {
          //     textStyle: { //改变刻度字体样式
          //       color: '#ffffff',
          //       fontSize: 53
          //     }
          //   },
          // },
          xAxis: {
            type: 'category',
            splitLine: {
              show: false
            },
            data: xData,
              axisLabel : {
                textStyle: { //改变刻度字体样式
                  color: '#ffffff',
                  fontSize: 40
                }
              },
          },
          yAxis: [
            {
              type: 'value',
              splitLine:{
                show:true,
                lineStyle:{
                  color: '#102A5F',
                  width: 2,
                  type: 'solid'
                }
              },
              axisLabel : {
                textStyle: { //改变刻度字体样式
                  color: '#47D6FF',
                  fontSize: 45
                }
              },
              axisTick:{ //y轴刻度线
                "show":false
              },
            }
          ],
          series: [
            {
              barWidth: 60, // 柱子宽度
              name: '',
              type: 'bar',
              stack: '总量',
              // 透明 空着的部分
              itemStyle: {
                barBorderColor: 'rgba(0,0,0,0)',
                color: 'rgba(0,0,0,0)'
              },
              emphasis: {
                itemStyle: {
                  barBorderColor: 'rgba(0,0,0,0)',
                  color: 'rgba(0,0,0,0)'
                }
              },
              data: base
            },
            {
              name: '',
              type: 'bar',
              stack: '总量',
              label: {
                show: true,
                position: 'bottom',
                color: '#333',
              },
              tooltip: {
                show: false
              },
              data: min
            },
            {
              name: '',
              type: 'bar',
              stack: '总量',
              label: {
                show: true,
                position: 'top',
              },
              itemStyle: {
                color: 'rgba(22, 179, 166, 0.5)',
                barBorderColor:'rgba(0, 255, 234, 0.5)'
              },
              data: max
            }
          ]
        };
        option && myChart.setOption(option);
      }
  }
}
</script>

<style scoped>
    .echarts3 {
        width: 100%;
        height: 762px;
    }
</style>