<template>
    <div class="dataScreenContent">
        <div class="tjCls">
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIconCls"></div>
                <div class="numCls">{{jiData.serviceArea || 0}}<span class="dwCls"></span></div>
                <div class="titleCls">年存栏量（万只）</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon2Cls"></div>
                <div class="numCls">{{jiData.eggCount || 0}}<span class="dwCls"></span></div>
                <div class="titleCls">年出栏量（万只）</div>
            </div>
        </div>
        <div class="mapCls">
            <baidu-map :center="center" v-if="isShow" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
            </baidu-map>
            <div class="yangCls  animate__animated animate__fadeInUp" @click="goDataScreen2">
                <div>栖然数字化散养蛋鸡园</div>
                <div>亩数：4.3亩</div>
                <div>地址：榆阳区巴拉素镇讨讨滩村</div>
            </div>
            <div class="dong">
                <img src="../../../assets/images/dataScreen/egg/shanshuo.gif" alt="">
            </div>

            <!--<div class="mapTips2Cls  animate__animated animate__fadeInUp" @click="goDataScreen2">
                <div>企业：{{jiData.mapEnterpriseCount || 0}}家</div>
            </div>-->
        </div>
        <div class="main3cls">
            <div class="jimain3Cls">
                栖然数字化散养蛋鸡园
            </div>

            <div class="jdsshmCls" v-if="isTrue">
                <div class="jdssJ1Cls"></div>
                <div class="jdssJ2Cls"></div>
                <div class="jdssTitleCls">
                    基地实时画面
                </div>
                <div class="vedioCls" v-for="(item, i) in jiData.deviceEnterpriseRelationBOS" :key="i">
                    <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="environmentalAttribute" :flv="item.deviceUrl" />
                    <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="environmentalAttribute" :flv="item.deviceUrl"/>
                    <!-- <EZUIKitJs msg="video9" :attribute="environmentalAttribute"
                        :flv="jiData.qiranJidiVedio1Url" /> -->
                    <div class="vedioTitleCls"
                        @click="transitionFun(item.deviceUrl, item.installArea)">{{item.installArea}}</div>

                </div>
                <!-- <div class="vedioCls">
                    <EZUIKitJs msg="video10" :attribute="environmentalAttribute"
                        :flv="jiData.qiranJidiVedio2Url" />
                    <div class="vedioTitleCls"
                        @click="transitionFun(jiData.qiranJidiVedio2Url, jiData.qiranJidiVedio2Title)">{{jiData.qiranJidiVedio2Title}}
                    </div>

                </div>
                <div class="vedioCls">
                    <EZUIKitJs msg="video11" :attribute="environmentalAttribute"
                        :flv="jiData.qiranJidiVedio3Url" />
                    <div class="vedioTitleCls"
                        @click="transitionFun(jiData.qiranJidiVedio3Url, jiData.qiranJidiVedio3Title)">{{jiData.qiranJidiVedio3Title}}</div>

                </div>
                <div class="vedioCls">
                    <EZUIKitJs msg="video12" :attribute="environmentalAttribute"
                        :flv="jiData.qiranJidiVedio4Url" />
                    <div class="vedioTitleCls"
                        @click="transitionFun(jiData.qiranJidiVedio4Url, jiData.qiranJidiVedio4Title)">{{jiData.qiranJidiVedio4Title}}</div>

                </div> -->
            </div>
            <div class="jdtj2Cls">
                <div class="jdtj2_1Cls">
                    <div class="jiao1Cls"></div>
                    <div class="jiao2Cls"></div>
                    <div class="jiao3Cls"></div>
                    <div class="jiao4Cls"></div>
                    <div class="jdtjTitleBgCls">蛋鸡产蛋统计数据</div>
                    <div class="echarts1">
                      <Highecharts1 ref="chilkenEgg"></Highecharts1>
                    </div>
                </div>
                <div class="jdtj2_1Cls secCls">
                    <div class="jiao1Cls"></div>
                    <div class="jiao2Cls"></div>
                    <div class="jiao3Cls"></div>
                    <div class="jiao4Cls"></div>
                    <div class="jdtjTitleBgCls">喂养数据统计</div>
                    <div class="qnklxhltjCls">全年口粮消耗量统计</div>
                    <div class="qnklxhltjBoxCls">
                        <div class="zhibiaoBoxCls">
                            <div>{{kouliang.cornnum  ? bllw(kouliang.cornnum) : '0.00'}}<span>公斤</span></div>
                            <div>玉米（63%）</div>
                        </div>
                        <div class="zhibiaoBoxCls">
                            <div>{{kouliang.pulpnum ? bllw(kouliang.pulpnum) : '0.00'}}<span>公斤</span></div>
                            <div>豆粕（19%）</div>
                        </div>
                        <div class="zhibiaoBoxCls">
                            <div>{{kouliang.stonenum ? bllw(kouliang.stonenum) : '0.00'}}<span>公斤</span></div>
                            <div>贝壳粉（8%）</div>
                        </div>
                        <div class="zhibiaoBoxCls zhibiaoBoxCls2">
                            <div style="padding-top:5px;">{{kouliang.pabulumnum ? bllw(kouliang.pabulumnum) : '0.00'}}<span>公斤</span></div>
                            <div style="padding-top:0px;">营养添加（10%）</div>
                            <div style="padding-top:5px;color:#C2C6CD;">棉粕&nbsp;&nbsp;豆油&nbsp;&nbsp;盐&nbsp;&nbsp;氨基酸&nbsp;&nbsp;维生素&nbsp;&nbsp;矿物质元素</div>
                        </div>
                        <div class="zhibiaoBoxCls">
                            <div>适时适量</div>
                            <div>青菜</div>
                        </div>
                        <!--<div class="zhibiaoBoxCls">
                            <div>10<span>Kg</span></div>
                            <div>大豆油</div>
                        </div>
                        <div class="zhibiaoBoxCls">
                            <div>3<span>Kg</span></div>
                            <div>小苏打</div>
                        </div>
                        <div class="zhibiaoBoxCls">
                            <div>3<span>Kg</span></div>
                            <div>食盐</div>
                        </div>
                        <div class="zhibiaoBoxCls">
                            <div>20<span>Kg</span></div>
                            <div>核心料(预混料)</div>
                        </div>
                        <div class="zhibiaoBoxCls">
                            <div>24<span>Kg</span></div>
                            <div>玉米蛋白粉</div>
                        </div>-->
                    </div>
                    <div class="ywsjtjCls">喂养时间统计</div>
                    <div class="ywsjtjBoxCls">
                        <div class="timerBarCls">
                            <span>03:00</span>
                            <span>06:00</span>
                            <span>09:00</span>
                            <span>12:00</span>
                            <span>15:00</span>
                            <span>18:00</span>
                            <span>21:00</span>
                            <span>24:00</span>
                        </div>
                        <template v-for="(itemRd,idx) in this.wyList">
                         <div :style="{'left': weiyangTimeCompute(itemRd.feedAllTime) + 'px'}"  class="weishiRecordCls">已喂食{{itemRd.feedAllTime}}</div>
                        </template>
                    </div>
                    <div class="dayBarCls">
                        <div  v-for="(dayItem,index) in daytimeArr" :key="index" :class="dayItem == feedingCurrDay ?'seled':''" @click="changeWeiyangDay(dayItem, 'feeding' , index)">{{dayItem}}日</div>
                    </div>
                </div>
            </div>
            <div class="jdtj3Cls">
                <el-carousel indicator-position="none" height="600px">
                    <el-carousel-item>
                        <div class="paihangbanCls">
                            <div class="ban1Cls">
                                <div class="hsCls">{{jihuizong.totalarea || 0}}</div>
                                <div>总占地(亩)</div>
                            </div>
                            <div class="ban2Cls">
                                <div class="lanCls">{{parseInt(jihuizong.fedeggs) || 0}}</div>
                                <div>喂养蛋鸡(只)</div>
                            </div>
                            <div class="ban3Cls">
                                <div class="lanCls">{{parseInt(jihuizong.onegg) || 0}}</div>
                                <div>已产鸡蛋(颗)</div>
                            </div>
                            <div class="ban4Cls">
                                <div class="hsCls">{{parseInt(jihuizong.aveegg) || 0}}</div>
                                <div>日均产蛋(颗)</div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="paihangbanCls">
                            <div class="ban1Cls iot1">
                                <div class="hsCls">{{waibuiot.temperature != 0 ? parseInt(waibuiot.temperature / 10) : '--'}}°C</div>
                                <div>温度</div>
                            </div>
                            <div class="ban2Cls iot2">
                                <div class="lanCls">{{waibuiot.humidity != 0 ? (Number(waibuiot.humidity)).toFixed(0) : '--'}}%Rh</div>
                                <div>湿度</div>
                            </div>
                            <div class="ban3Cls iot3">
                                <div class="lanCls">{{waibuiot.carbonDioxide != 0 ? waibuiot.carbonDioxide : '--'}}ppm</div>
                                <div>二氧化碳</div>
                            </div>
                            <div class="ban4Cls iot4">
                                <div class="hsCls">{{waibuiot.ammonia != 0 ? waibuiot.ammonia : '--'}}ppm</div>
                                <div>氨气</div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel> 
                <div class="moxingCls">
                    
                    <video class="video" style=" object-fit: fill" muted :src="jiData.qiranPublicityVideoUrl" autoplay loop></video>
                </div>
                <div class="jdjhtjCls">
                    <div class="jiao1Cls"></div>
                    <div class="jiao2Cls"></div>
                    <div class="jiao3Cls"></div>
                    <div class="jiao4Cls"></div>
                    <div class="jdtjTitleBgCls">蛋鸡实时AIOT智能脚环数据</div>
                    <div class="echarts2">
                        <Highecharts2 ref="jiFoot"></Highecharts2>
                    </div>
                    <div class="dayBarCls"  style="width: 2020px; margin-left: 50px;">
                        <div v-for="(dayItem,index) in daytimeArr" :key="index" :class="dayItem == footerCurrDay ?'seled':''" @click="changeWeiyangDay(dayItem, 'footer', index)">{{dayItem}}日</div>
                    </div>
                </div>
            </div>
            <div class="jdtj4Cls">
                <div class="sikuangBoxCls" style="height: 894px;">
                    <div class="jiao1Cls"></div>
                    <div class="jiao2Cls"></div>
                    <div class="jiao3Cls"></div>
                    <div class="jiao4Cls"></div>
                    <div class="titleBgCls">实时气象监测数据</div>
                    <div class="wulianwangBoxCls">
                        <div class="wenduBoxCls">{{parseInt(qxList.iotDataBO.ambientTemperature)}}</div>
                        <div class="wulianwangValueBoxCls">
                            <div><span>风速</span><span>{{qxList.iotDataBO.windSpeed}}m/s</span></div>
                            <div><span>湿度</span><span>{{(Number(qxList.iotDataBO.ambientHumidity)).toFixed(0)}}%Rh</span></div>
                            <div><span>雨量</span><span>{{qxList.iotDataBO.rainfall}}mm</span></div>
                            <div><span>辐射</span><span>{{qxList.iotDataBO.totalRadiation ? qxList.iotDataBO.totalRadiation : 0}}HW/m²</span></div>
                            <div><span>光照</span><span>{{qxList.iotDataBO.lightIntensity}}kLux</span></div>
                            <div><span>大气压</span><span>{{qxList.iotDataBO.pressure}}hPa</span></div>
                        </div>
                    </div>
                    <div style="height: 70px; width: 100%; float: left;"></div>
                    <div class="dayBarCls">
                        <div  v-for="(dayItem,index) in daytimeArr" :key="index" :class="dayItem == meteorologicalCurrDay ?'seled':''" @click="changeWeiyangDay(dayItem, 'meteorological', index)">{{dayItem}}日</div>
                    </div>
                </div>
                <div class="sikuangBoxCls" style="height: 930px;margin-top: 40px;">
                    <div class="jiao1Cls"></div>
                    <div class="jiao2Cls"></div>
                    <div class="jiao3Cls"></div>
                    <div class="jiao4Cls"></div>
                    <div class="titleBgCls">疾病监测预警</div>
                    <div class="flex f_jc_e f_ai_c mt46">
                        <img class="echarts-icon" src="../../../assets/images/dataScreen/egg/icon1.png" alt="">
                        <div class="fs48 mr74 ml23">已打</div>
                        <img class="echarts-icon" src="../../../assets/images/dataScreen/egg/icon2.png" alt="">
                        <div class="fs48 mr74 ml23">预警</div>
                        <img class="echarts-icon" src="../../../assets/images/dataScreen/egg/icon3.png" alt="">
                        <div class="fs48 mr74 ml23">治疗</div>
                    </div>
                    <div class="flex ml20">
                        <!-- <div>
                            <div class="grid-title">下旬</div>
                            <div class="grid-title">中旬</div>
                            <div class="grid-title">中旬</div>
                        </div> -->
                        <div class="flex mt50">
                            <div v-for="(item, i) in listMonth" :key="i">
                                <div class="grid flex f_ai_c f_jc_c ta_c">
                                    <div>
                                        <div v-for="(subItem, j) in item.children" :key="j">
                                            <div v-if="subItem.type == '1'" v-for="(itm,inx) in subItem.name" :key="inx" class="disease-text disease-text-1">{{itm}}</div>
                                            <div v-if="subItem.type == '3'" class="disease-text disease-text-2">{{subItem.name}}</div>
                                            <img v-if="subItem.type == '2'" class="treatment-icon" src="../../../assets/images/dataScreen/egg/icon3.png" alt="">
                                        </div>
                                    </div>
                                </div>
                
                                <div class="grid-date">{{item.month}}月</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
                <div class="sikuangBoxCls" style="height: 880px;margin-top: 40px;">
                    <div class="jiao1Cls"></div>
                    <div class="jiao2Cls"></div>
                    <div class="jiao3Cls"></div>
                    <div class="jiao4Cls"></div>
                    <div class="titleBgCls">鸡舍内外实时监控</div>
                    <div class="vedioRowCls" v-if="isTrue">
                        <div  v-for="(item, i) in jiData.deviceEnterpriseRelationBOS2" :key="i">
                            <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'smallvideo'+(i+1)" :attribute="jisheEnvironmentalAttribute" :flv="item.deviceUrl" />
                            <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'smallvideo'+(i+1)"  :attribute="jisheEnvironmentalAttribute" :flv="item.deviceUrl"/>
                             
                            <!-- <EZUIKitJs msg="video1" :attribute="jisheEnvironmentalAttribute"
                                :flv="jiData.qiranJidiInoutRoomVedio1Url" /> -->
                            <div class="vedioColTitleCls"
                               @click="transitionFun(item.deviceUrl, item.installArea)">{{item.installArea}}</div>
                        </div>
                        <!-- <div>
                            <EZUIKitJs msg="video2" :attribute="jisheEnvironmentalAttribute"
                                :flv="jiData.qiranJidiInoutRoomVedio2Url" />
                            <div class="vedioColTitleCls"
                                @click="transitionFun(jiData.qiranJidiInoutRoomVedio2Url, '#20220001北面')">{{jiData.qiranJidiInoutRoomVedio2Title}}</div>
                        </div> -->
                    </div>
                    
                </div>

            </div>
            <div class="jdtj5Cls">
                <div class="jdssJ1Cls"></div>
                <div class="jdssJ2Cls"></div>
                 <div class="titleBgCls">一筐蛋</div>
                 <div class="biaotiCls"></div>
                 <div class="zhutiCls">
                   <p>{{jiData.qiranYikuangdanContent}}</p>
                 </div>
                 <div class="welcomeCls">
                    <div class="tuCls" :style="{backgroundImage: 'url('+jiData.qiranYikuangdanMapUrl+')'}" style="background-size: 100%">
                        <!-- <video style="width: 100%; height: 100%" :src="jiData.qiranYikuangdanMapUrl"></video> -->
                    </div>
                 </div>
                 
                 <div class="erweimaCls" :style="{backgroundImage: 'url('+ jiData.qiranYikuangdanQrcodeUrl +')'}">
                 </div>
            </div>
        </div>

        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">


                    </div>
                    <div class="video-centent">
                        <div id="video111" :attribute="environmentalAttribute2" flv="" />
                        <div class="video-title">{{ vedioTitle }}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
    </div>


</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import Highecharts1 from '../highecharts/chicken/highecharts1.vue'
import Highecharts2 from '../highecharts/chicken/highecharts2.vue'
import ListScroll from "../../../components/listScroll.vue";
import axios from 'axios';
import 'animate.css';
export default {
    name: '',
    components: {
        EZUIKitJs,
        Highecharts1,
        Highecharts2,
        ListScroll
    },
    data() {
        return {
            jiData: {},
            jihuizong: {},
            iotObj: {},
            daytimeArr: [],
            dateList: [],
            wyList: [],
            weiyangRecordList:[],
            day7List:[],
            trList: {},
            townList: [],
            isShow: false,
            isTrue: false,
            feedingCurrDay: 0,
            allCount: 0,
            footerCurrDay:0,
            meteorologicalCurrDay:0,
            timeNum: 0,
            environmentalAttribute: {
                width: 1478,
                height: 1195
            },
            jisheEnvironmentalAttribute:{
                width: 715,
                height: 642
            },
            kouliang: {},
            qxList: {},
            show: false,
            vedioTitle: "",
            player: null,
            listMonth: [],
            waibuiot: {},
            diseaseData: [],
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12

        }
    },
    props:{
        _timeFmt:""
    },
    computed:{
        weiyangTimeCompute(){
            return (_timeFmt)=>{
               var rds = _timeFmt.split(":");
               var fenCounts = parseInt(rds[0])*60+parseInt(rds[1]);
              
               //left: 13501px;
               // console.log(fenCounts/1440*1440);
                return fenCounts-104;
            }
        }
    },
    mounted() {
        this.GetTime();
        this.init();
        this.doPlay("");
        var timer = setInterval(() => {
            ++this.timeNum
            if (this.timeNum > 4) {
                clearInterval(timer)
            }
        }, 50);

        //获取喂养时间数据
        this.getWeiyangRecords();
        //获取前7日
        // this.getday7List();
    },
    methods: {
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/mark.png"), new BMap.Size(60, 108), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(10, 25),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
            // for(let i = 0; i < this.potatoData.length; i++) {
            //     var point = new BMap.Point(this.potatoData[i].mapX, this.potatoData[i].mapY);   
            //     var marker = new BMap.Marker(point, {icon: myIcon});   
            //     map.addOverlay(marker);   
            //     var content = this.potatoData[i].enterpriseName +'<br />'+this.potatoData[i].enterpriseValue;
            //     var label = new BMap.Label(content, {       // 创建文本标注
            //         position: point,
            //         offset: new BMap.Size(20, -240)
            //     })  
            //     this.labels.push(label);
                
            //     map.addOverlay(label);
            //     if (this.potatoData[i].keyEnterprises == 'Y') {
            //         this.clickBox(this.potatoData[i], i);                       // 将标注添加到地图中
            //     }
            //     label.setStyle({                              // 设置label的样式
            //         color: '#fff',
            //         fontSize: '50px',
            //         border: '0',
            //         backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
            //         backgroundColor: 'transparent',
            //         height: '208px',
            //         padding: '15px 35px 46px',
            //         backgroundSize: '100% 100%'
            //     })
                
                
            // }
            // alert(JSON.stringify(this.townList))
            for(let i = 0; i < this.townList.length; i++) {
                // if(this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) {
                    var point = new BMap.Point(this.townList[i].mapX, this.townList[i].mapY);   
                    var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.townList[i].townshipName + '</div><div>'+this.townList[i].yuliu1 +'</div>'+'<div>'+this.townList[i].yuliu2+'</div>'+'<div>'+this.townList[i].yuliu3+'</div></div>';
                    var label = new BMap.Label(content, {       // 创建文本标注
                        position: point,
                        offset: new BMap.Size(-200, -368)
                    })  
                    map.addOverlay(label);
                    label.setStyle({                              // 设置label的样式
                        color: '#fff',
                        fontSize: '50px',
                        border: '0',
                        backgroundImage: (this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")':'',
                        backgroundColor: 'transparent',
                        height: '248px',
                        padding: '15px 35px 46px',
                        backgroundSize: '100% 100%'
                    })
                
                // }
            }

            var yhPoint = new BMap.Point(this.mlsObj.yhList[0].mapX,this.mlsObj.yhList[0].mapY);
            var yhMaker = new BMap.Marker(yhPoint, {icon: myIcon});
            map.addOverlay(yhMaker);   
             var yhContent = this.mlsObj.yhList[0].enterpriseName + '<br />'+ this.mlsObj.yhList[0].enterpriseValue + '亩';
                var yhLabel = new BMap.Label(yhContent, {       // 创建文本标注
                    position: yhPoint,
                    offset: new BMap.Size(20, -240)
                })  

                map.addOverlay(yhLabel);
                var that = this;
            yhLabel.addEventListener('click', function(){
                that.$parent.tabsTypeClick('4');
            })
            yhLabel.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
                    backgroundColor: 'transparent',
                    height: '208px',
                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            // var marker = new BMap.Marker(point) // 创建标注
            // map.addOverlay(marker) // 将标注添加到地图中
            // var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            // map.addOverlay(circle)
            map.enableScrollWheelZoom(true);
        },
        baoliu(count) {
            return count.toFixed(4);
        },
        init(){
            this.qa.eggsLookeggListLed({}).then(res => {
                setTimeout(() => {
                    let temperatureArr = res.rows;
                    let dateArr = [];
                    temperatureArr.forEach((item,index) => {
                        dateArr.push(item.lookeggTime.substring(0,10));
                    });
                    console.log(dateArr)
                    this.qa.vervelTjListLed({}).then(res => {
                        console.log(res)
                        if (res.rows && res.rows.length > 0) {
                            temperatureArr.forEach((item,index) => {
                                res.rows.forEach((items,idx) => {
                                    if (item.lookeggTime.substring(0,10) == items.syntime) {
                                        item.avesteps = items.avesteps;
                                    }
                                })
                            })
                        } else {
                            temperatureArr.forEach((item,index) => {
                                item.avesteps = 0;
                            });
                        }
                        console.log(temperatureArr)                        
                        this.qa.queryMaxAndMinTemperatureData(dateArr).then(res => {
                            console.log(res)
                            if (res.data.length && res.data.length > 0) {
                                temperatureArr.forEach((item,index) => {
                                    res.data.forEach((items,idx) => {
                                        if (item.lookeggTime.substring(0,10) == items.date) {
                                            item.minAmbientTemperature = items.minAmbientTemperature;
                                            item.maxAmbientTemperature = items.maxAmbientTemperature;
                                        }
                                    })
                                })
                                this.$refs.chilkenEgg.datalist = temperatureArr;
                                console.log(temperatureArr)                            
                                this.$refs.chilkenEgg.moreChart();
                            } else {
                                temperatureArr.forEach((item,index) => {
                                    item.minAmbientTemperature = '';
                                    item.maxAmbientTemperature = '';
                                });
                                this.$refs.chilkenEgg.datalist = temperatureArr;
                                console.log(temperatureArr)                                                        
                                this.$refs.chilkenEgg.moreChart();
                            }
                        })
                    })
                }, 1000);
                
            })

            //   var dataArr = [];
                var data = new Date();
                var year = data.getFullYear();
                // var day = data.getDay();
                // data.setMonth(data.getMonth()+1, 1)//获取到当前月份,设置月份
                for (var i = 1; i < 13; i++) {
                    data.setMonth(data.getMonth() - 1);//每次循环一次 月份值减1
                    // var m = data.getMonth() + 1;
                    i = i < 10 ? "0" + i : i;
                    this.listMonth.push({date: year + '-' + i, month: parseInt(i), children: []})
              
                }

                // console.log(this.listMonth)

            this.qa.queryDatascreen9ById({}).then(res => {
                this.jiData = res.data;
                this.isTrue = true;

                this.diseaseData = this.jiData.datascreen9HealthyMonitorList;
               

                //鸡脚环散点图
                // let list = [{symbolSize: 20,type: 'scatter',color: '#27ecff', data: []},{symbolSize: 20,type: 'scatter',color: '#27ffa6', data: []},
                // {symbolSize: 20,type: 'scatter',color: '#baff27', data: []},{symbolSize: 20,type: 'scatter',color: '#ffdc33', data: []}];
                // for(let i = 0; i < this.jiData.datascreen9FootRingList.length; i++) {
                //     let count = Math.floor(Math.random()*(1000-1) + 1);
                //     if(this.jiData.datascreen9FootRingList[i] > 0 && this.jiData.datascreen9FootRingList[i] < 5000) {
                //         list[0].data.push([count, this.jiData.datascreen9FootRingList[i]]);
                //     } else if(this.jiData.datascreen9FootRingList[i] >= 5000 && this.jiData.datascreen9FootRingList[i] < 15000){
                //         list[1].data.push([count, this.jiData.datascreen9FootRingList[i]]);
                //     } else if(this.jiData.datascreen9FootRingList[i] >= 15000 && this.jiData.datascreen9FootRingList[i] < 20000) {
                //         list[2].data.push([count, this.jiData.datascreen9FootRingList[i]]);
                //     } else if(this.jiData.datascreen9FootRingList[i] >= 20000) {
                //         list[3].data.push([count, this.jiData.datascreen9FootRingList[i]]);
                //     }
                // }
                // this.$refs.jiFoot.data = list;
                // this.$refs.jiFoot.moreChart();


            })

            

            this.qa.queryEc({}).then(res => {
                this.waibuiot = res.data;
            })

            this.qa.queryIotDataByDate({deviceType: 'SENSOR_TR', datascreenNo: '09'}).then(res => {
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                    }
                    if(res.data.length > 0) {
                        this.trList = res.data[0];
                    }
                    

        
                })
            // this.qa.queryRedisIotDataByLandIds({"landIds":168,"orgType":"DDZY"}).then(res => {
            //     this.iotObj = res.data.list[0];
            // })

            this.qa.datascreen9TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
                this.isShow = true;
            })

            

            this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '09'}).then(res => {
                    let newArr = [];
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                        newArr.push(res.data[i]);
                    }
                    
                    if (localStorage.getItem('weather9') == null) {
                        localStorage.setItem('weather9', JSON.stringify(res.data));
                        this.qxList = res.data[0];
                    } else {
                        let oldArr = JSON.parse(localStorage.getItem('weather9'));
                        localStorage.setItem('weather9', JSON.stringify(res.data));                    
                        oldArr.forEach((item,index) => {
                            newArr.forEach((items,idx) => {
                                if (item.key == items.key) {
                                    items.iotDataBO.rainfall = (Number(items.iotDataBO.rainfall) - Number(item.iotDataBO.rainfall)).toFixed(2);
                                }
                            })
                        });
                        this.qxList = newArr[0];                                  
                    }
  
                })

            this.qa.getEggsMix({}).then(res => {
                this.allCount = Number(res.data.cornnum) + Number(res.data.pulpnum) + Number(res.data.stonenum) +Number(res.data.pabulumnum)
                this.kouliang = res.data;
            })

            
            this.qa.zwcrListLed({}).then(res => {
                this.jihuizong = res.data;
            })

            this.qa.eggsAntAndMonthListLed({}).then(res => {
                for(let i = 0; i < this.listMonth.length; i++) {
                    for(let j = 0; j < res.rows.length; j++) {
                        
                        if(this.listMonth[i].date == (res.rows[j].antTime).substring(0,7)) {
                            this.listMonth[i].children.push({
                                type: 1,
                                name: res.rows[j].vacname.split("、")
                            })
                            // this.listMonth[i].type = 1;
                            // this.listMonth[i].name = res.rows[j].vacname.split("、");
                        }
                    }
                }
            })

            this.qa.eggsCureAndMonthListLed({}).then(res => {
                for(let i = 0; i < this.listMonth.length; i++) {
                    for(let j = 0; j < res.rows.length; j++) {
                        
                        if(this.listMonth[i].date == (res.rows[j].cureTime).substring(0,7)) {
                            this.listMonth[i].children.push({
                                type: 2,
                                name: res.rows[j].medname
                            })
                        
                        }
                    }
                }
            })

            this.qa.eggsWarnAndMonthListLed({}).then(res => {
                for(let i = 0; i < this.listMonth.length; i++) {
                    for(let j = 0; j < res.rows.length; j++) {
                        
                        if(this.listMonth[i].date == (res.rows[j].warnTime).substring(0,7)) {
                            this.listMonth[i].children.push({
                                type: 3,
                                name: res.rows[j].name
                            })
                   
                        }
                    }
                }
            })

            
        },
        bllw(num){
            return parseFloat(num).toFixed(2)
        },
        changeWeiyangDay(_day, str, index){

            
            if(str == 'feeding') {
                this.feedingCurrDay = _day;
                this.qa.eggsFeedListLed({feedTime: this.dateList[index]}).then(res => {
                    for(let i = 0; i < res.rows.length; i++) {
                        res.rows[i].feedAllTime = (res.rows[i].feedTime).substring(10, 16);
                    }
                    this.wyList = res.rows;
                })
            }
            if(str == 'footer') {
                this.footerCurrDay = _day;
                this.qa.queryDatascreen9JbqHistory({beginDate: this.dateList[index]+' 00:00:00', endDate: this.dateList[index]+' 23:59:59'}).then(res => {
                    var list = [{symbolSize: 20,type: 'scatter',color: '#27ecff', data: []}];
                   for(let i = 0; i <　res.data.length; i++) {
                       if(res.data[i].sameDaySteps < 100000) {
                           list[0].data.push(res.data[i].sameDaySteps)
                       }
                       if(res.data[i].sameDaySteps == 0) {
                           errList.push(res.data[i])
                       }
                    }
                    // alert(this.$refs.jiFoot)
                   this.$refs.jiFoot.data = list;
                    this.$refs.jiFoot.moreChart();
                })
            }
            if(str == 'meteorological') {
                this.meteorologicalCurrDay = _day;
                this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '09', dateQuery: this.dateList[index]}).then(res => {
                    for(let i = 0; i < res.data.length; i++) {
                        if(!res.data[i].iotDataBO) {
                            res.data[i].iotDataBO = {};
                        }
                    }
                    if(res.data.length > 0) {
                        this.qxList = res.data[0];
                    }
                    
    
    
                })
                
            }
            
        },
        getday7List(){
            //this.currDay = new Date().getDate();
            this.feedingCurrDay = new Date().getDate();
            this.footerCurrDay = new Date().getDate();
            this.meteorologicalCurrDay = new Date().getDate();
            this.day7List = [];
            for(let i=6;i>=0;i--){
                this.day7List.push(this.feedingCurrDay-i);
            }
        },
        getWeiyangRecords(){
            this.weiyangRecordList = ["5:00","12:00","24:00"];
        },
        goDataScreen2() {
            this.$emit('tabsTypeClick', '2');
        },
        doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },
        GetTime() {
            var date = new Date();
            var base = Date.parse(date); // 转换为时间戳
            var year = date.getFullYear(); //获取当前年份
            var mon = date.getMonth() + 1; //获取当前月份
            var day = date.getDate(); //获取当前日
            var oneDay = 0;
                //var daytime = `${year}${mon >= 10 ? mon : '0' + mon}${day >= 10 ? day : '0' + day}`; //今日时间
                //this.$data.daytime = daytime; // 今日时间赋值给变量
                        
            var daytimeArr = []
            var dateArr = [];
                for (var i = 1; i <= 7 ; i++) { //前七天的时间
                    var now = new Date(base -= oneDay);
                    var myear = now.getFullYear();
                    var month = now.getMonth() + 1;
                    var mday = now.getDate()
                    daytimeArr.unshift([mday>=10?mday:'0'+mday].join('-'));
                    oneDay = 24 * 3600 *1000;
                    dateArr.unshift(myear+'-'+(month>=10?month:'0'+month)+'-'+(mday>=10?mday:'0'+mday))
                }
                
                this.daytimeArr = daytimeArr;
                this.dateList = dateArr;
                this.feedingCurrDay = this.daytimeArr[this.daytimeArr.length - 1];
                this.footerCurrDay = this.daytimeArr[this.daytimeArr.length - 1];
                this.meteorologicalCurrDay = this.daytimeArr[this.daytimeArr.length - 1];

                this.qa.eggsFeedListLed({feedTime: this.dateList[this.dateList.length - 1]}).then(res => {
                    for(let i = 0; i < res.rows.length; i++) {
                        res.rows[i].feedAllTime = (res.rows[i].feedTime).substring(10, 16);
                    }
                    this.wyList = res.rows;
                })
                var errList = [];
                this.qa.queryDatascreen9JbqHistory({beginDate: this.dateList[this.dateList.length - 1]+' 00:00:00', endDate: this.dateList[this.dateList.length - 1]+' 23:59:59'}).then(res => {
                   var list = [{symbolSize: 20,type: 'scatter',color: '#27ecff', data: []}];
                   for(let i = 0; i <　res.data.length; i++) {
                       if(res.data[i].sameDaySteps < 100000) {
                           list[0].data.push(res.data[i].sameDaySteps)
                       }
                       if(res.data[i].sameDaySteps == 0) {
                           errList.push(res.data[i])
                       }
                    }
                    // alert(this.$refs.jiFoot)
                   this.$refs.jiFoot.data = list;
                    this.$refs.jiFoot.moreChart();
                })
        },
        transitionFun(_url, _title) {


            this.doPlay(_url);

            this.show = !this.show;
            this.vedioTitle = _title;
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
    transform: translateX(100px);
    opacity: 0;
}

.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videdo-box {
    display: flex;
    align-items: center;
}

.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}

.video-title {
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}

.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;
}

.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.dataScreenContent {
    color: #ccc;
    font-size: 100px;
}

div.dsBgbox {
    background-image: url("../../../assets/images/dataScreen/ds1_bg_box.png");
    width: 809px;
    height: 320px;
    background-size: 100% 100%;
    position: relative;
    font-size: 101px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #23D1FF;
}

div.dsBgbox>div.dsIconCls {
    background-image: url("../../../assets/images/dataScreen/ds_icon2.png");
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.dsIcon2Cls {
    background-image: url("../../../assets/images/dataScreen/ds_icon3.png");
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.numCls {
    width: 100%;
    margin-left: 260px;
    padding-top: 40px;
}

div.dsBgbox>div.titleCls {
    width: 100%;
    height: 228px;
    margin-left: 270px;
    font-size: 51px;
    color: #FFFFFF;
}

div.numCls>span.dwCls {
    font-size: 40px;
}

div.tjCls,
div.mapCls {
    float: left;
    position: relative;
    z-index: 999;
}

div.tjCls>div:nth-child(n+1) {
    margin-top: 135px;
}

div.mapCls {
    /* margin-top: -150px; */
    margin-right: 200px;
    width: 3787px;
    height: 3006px;
    z-index: 100;
    margin-left: -800px;
    position: relative;
    background-image: url("../../../assets/images/dataScreen/map.png");
}

div.mapCls>div.mapTips2Cls {
    position: absolute;
    top: 2060px;
    left: 620px;
    background-image: url("../../../assets/images/dataScreen/ji-2.png");
    width: 518px;
    height: 214px;
    text-align: right;
    font-weight: bolder;

}

div.mapCls>div.mapTips2Cls>div {
    font-size: 36px;
    position: absolute;
    right: 0px;
    width: 280px;
    height: 90px;
    line-height: 90px;
    text-align: left;
    padding-left: 40px;

}

div.mapCls>div.yangCls {
    position: absolute;
    top: 1100px;
    left: 900px;
    background-image: url("../../../assets/images/dataScreen/egg/duihua.png");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 902px;
    height: 563px;
    padding-top: 65px;
    font-weight: bolder;
    padding-left: 90px;

}

div.mapCls>div.dong {
    position: absolute;
    left: 800px;
    top: 1666px;

} 
div.mapCls>div.dong >img {
    width: 100px;
}

div.mapCls>div.yangCls>div:nth-child(1) {
    font-size: 75px;

}

div.mapCls>div.yangCls>div:nth-child(2) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 100px;
}

div.mapCls>div.yangCls>div:nth-child(3) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 20px;
}

div.dsBox3 {
    margin-left: 400px;
    float: left;
    width: 3096px;
    height: 100%;
}

div.dsBox3>div.yzcyCls {
    float: left;
    width: 3096px;
    height: 1037px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
}

div.dsBox3>div.yzcy2Cls {
    margin-top: 60px;
    float: left;
    width: 3096px;
    height: 1816px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
}

div.ysStCls {
    background-image: url("../../../assets/images/dataScreen/ysSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    top: -8px;
    left: -8px;

}

div.zxStCls {
    background-image: url("../../../assets/images/dataScreen/zxSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    bottom: -8px;
    right: -8px;

}

div.yzcyCls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzcy2Cls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzTableHeaderCls {
    margin-top: 40px;
}

div.yzTableHeaderCls>div {
    float: left;
    color: #A4DF11;
    font-size: 59px;
}

div.yzTableHeaderCls>div:nth-child(1),
div.yzTableRowCls>div:nth-child(1) {
    width: 300px;
}

div.yzTableHeaderCls>div:nth-child(2),
div.yzTableRowCls>div:nth-child(2) {
    width: 250px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(3),
div.yzTableRowCls>div:nth-child(3) {
    width: 275px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(4),
div.yzTableRowCls>div:nth-child(4) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(5),
div.yzTableRowCls>div:nth-child(5) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(6),
div.yzTableRowCls>div:nth-child(6) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(7),
div.yzTableRowCls>div:nth-child(7) {
    width: 300px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(8),
div.yzTableRowCls>div:nth-child(8) {
    width: 275px;
    text-align: right;
}

div.yzTableRowCls>div {
    height: 120px;
    line-height: 120px;
    float: left;
    color: #00FB40;
    font-size: 59px;
}

.marquee-list {
    height: 600px;
    overflow: hidden;
}

.marquee-list2 {
    height: 1600px;
    overflow: hidden;
}

div.vedioCls:nth-child(2n+1) {
    margin-left: 25px;
    margin-right: 0px;
}

div.vedioCls {
    width: 1489px;
    height: 1206px;
    background: #0E1639;
    border: 5px solid #123171;
    float: left;
    margin-top: 40px;
    margin-right: 30px;
    margin-bottom: 20px;
    position: relative;
}

div.vedioCls>div.vedioTitleCls {
    background-image: url("../../../assets/images/dataScreen/vedioTitlebg.png");
    position: absolute;
    font-size: 70px;
    color: #fff;
    bottom: 20px;
    right: 0px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-align: right;
    padding-right: 50px;
}

div.yzcy3Cls {
    width: 5369px;
    height: 2902px;
    margin-left: 100px;
    float: left;
    position: relative;
    background-color: #01003d;
}

div.yzcy3Cls>div.ys1Cls {
    background-image: url("../../../assets/images/dataScreen/jx1.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    left: 0;
}

div.yzcy3Cls>div.ys2Cls {
    background-image: url("../../../assets/images/dataScreen/jx2.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    right: 0;
}

div.yzcy3Cls>div.ys3Cls {
    background-image: url("../../../assets/images/dataScreen/jx3.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    right: 0;
}

div.yzcy3Cls>div.ys4Cls {
    background-image: url("../../../assets/images/dataScreen/jx4.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    left: 0;
}

div.paibianCls {
    background-image: url("../../../assets/images/dataScreen/paibian.png");
    width: 1638px;
    height: 118px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

div.yzcy3Cls>div.mapsCls {
    background-image: url("../../../assets/images/dataScreen/map.png");
    width: 2981px;
    height: 2881px;
    background-size: cover;
    margin-left: 200px;
    float: left;
    position: relative;

}

div.jaigepai1Cls {
    position: absolute;
    top: 350px;
    left: 1100px;
}

div.jaigepai2Cls {
    position: absolute;
    top: 1320px;
    left: 200px;
}

div.jaigepai3Cls {
    position: absolute;
    top: 1220px;
    left: 980px;
}

div.jaigepai4Cls {
    position: absolute;
    top: 1090px;
    left: 1970px;
}

div.jaigepai5Cls {
    position: absolute;
    top: 1550px;
    left: 1470px;
}

div.jaigepai6Cls {
    position: absolute;
    top: 2100px;
    left: 1950px;
}

div.gjiageCls {
    background-image: url("../../../assets/images/dataScreen/g448.png");
    width: 295px;
    height: 105px;
    line-height: 105px;
    text-align: center;
    font-size: 45px;

}

div.gjiageCls>span {
    font-size: 65px;
}

div.yzcy3Cls {

    overflow: hidden;
}

div.textListBoxCls {
    float: left;
    width: 1950px;
    height: 100%;
    margin-top: 200px;
    padding-left: 100px;

}

div.textListBoxCls>div.rouyangTableCls {
    width: 1950px;
    height: 1690px;

}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div {
    float: left;
    background-color: #00c29a;
    color: #fff;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls>div {
    float: left;
    color: #3de2d6;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(1),
div.bodyCls>div.rowCls>div:nth-child(1) {
    width: 400px;
    text-indent: 50px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(2),
div.bodyCls>div.rowCls>div:nth-child(2) {
    width: 650px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(3),
div.bodyCls>div.rowCls>div:nth-child(3) {
    width: 300px;
    text-align: center;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(4),
div.bodyCls>div.rowCls>div:nth-child(4) {
    width: 250px;
    text-align: center;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(5),
div.bodyCls>div.rowCls>div:nth-child(5) {
    width: 340px;
}

div.bodyCls>div.rowCls>div:nth-child(5) {
    padding-right: 40px;
}

div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls:nth-child(2n+1)>div {
    background: rgba(73, 232, 217, 0.2);
}

div.tubiaoCls {
    margin-top: 50px;
}

div.zhoushititleCls {
    font-size: 74px;
    margin-bottom: 50px;
}

div.zoushituCls {
    background-image: url("../../../assets/images/dataScreen/111.png");
    width: 1801px;
    height: 674px;
}

div.main3cls {
    width: 9600px;
    height: 100%;
    float: left;
}

div.main3cls>div.jimain3Cls {
    width: 8548px;
    margin-left: 800px;
    margin-bottom: 100px;
    height: 130px;
    font-size: 60px;
    text-align: center;
    background-image: url("../../../assets/images/dataScreen/jimain3.png");
    background-repeat: no-repeat;
    background-position: center bottom;


}

div.jdsshmCls {
    width: 3159px;
    height: 2780px;
    float: left;
    background: #0E1639;
    border: 2px solid #123171;
    position: relative;
    padding: 35px;
}

div.jdssJ1Cls {
    position: absolute;
    background-image: url("../../../assets/images/dataScreen/ysSt-jiao.png");
    width: 59px;
    height: 59px;
    top: -7px;
    left: -7px;
}
div.jdssJ2Cls {
    position: absolute;
    background-image: url("../../../assets/images/dataScreen/zxSt-jiao.png");
    width: 59px;
    height: 59px;
    bottom: -7px;
    right: -7px;

}

div.jdsshmCls>div.jdssTitleCls {
    background-image: url("../../../assets/images/dataScreen/malinshu-title.png");
    width: 1164px;
    height: 148px;
    line-height: 148px;
    font-size: 55px;
    padding-left: 55px;
}
div.main3cls>div.jdtj2Cls{
    width: 1501px;
    height: 100%;
    float: left;
    margin-left: 53px;
}

div.jdtj2Cls>div.jdtj2_1Cls{
    width: 100%;
    height: 1379px;
    position: relative;
    background: #091A38;
    border: 2px solid #1585D5;

}
div.jdtj2Cls>div.jdtj2_1Cls.secCls{
    margin-top: 50px;
    height: 1352px;
}
div.jdtj2Cls>div.jdtj2_1Cls>div.jdtjTitleBgCls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/tjTitleBg.png");
    width: 1497px;
    height: 139px;
    text-align: center;
    font-size: 48px;
    line-height: 139px;
}
div.jdtj2Cls>div.jdtj2_1Cls>div.jiao1Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao1.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    left: -6px;
}
div.jdtj2Cls>div.jdtj2_1Cls>div.jiao2Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao2.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    right: -6px;
}
div.jdtj2Cls>div.jdtj2_1Cls>div.jiao3Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao3.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    right: -6px;
}
div.jdtj2Cls>div.jdtj2_1Cls>div.jiao4Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao4.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    left: -6px;
}
div.qnklxhltjCls{
    width: 100%;
    font-size: 45px;
    text-align: center;
    height: 100px;
    line-height: 100px;
}
div.qnklxhltjBoxCls{
    width: 100%;
    height: 500px;
    padding-left: 40px;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls{
    background-size: 420px 210px;
    background-image: url("../../../assets/images/dataScreen/qiranChickenEggRoom/zhibiao2.png");
    width: 420px;
    height: 210px;
    float: left;
    margin: 20px;
}
div.qnklxhltjBoxCls>div.zhibiaoBoxCls2{
    background-size: 880px 210px;
    background-image: url("../../../assets/images/dataScreen/qiranChickenEggRoom/zhibiao1.png");
    width: 880px;
    height: 210px;
    float: left;
    margin: 20px;
}
div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(1){
    font-size: 60px;
    font-family: DFPLiJinHeiW8-GB;
    font-weight: 400;
    text-align: center;
    padding-top: 20px;
    color: #00D3DB;
}
div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(1)>span{
    font-size: 40px;
}

div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(2){
    font-size: 38px;
    padding-top: 20px;
    text-align: center;
}
div.qnklxhltjBoxCls>div.zhibiaoBoxCls>div:nth-child(3){
    font-size: 36px;
    padding-top: 20px;
    text-align: center;
}
div.ywsjtjCls{
    height: 100px;
    font-size: 40px;
    text-align: center;
}
div.ywsjtjBoxCls{
    height: 300px;
    position: relative;
}
div.ywsjtjBoxCls>div.timerBarCls{
    position:absolute;
    width: 1440px;
    height: 107px;
    background: #0f2f5c;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
}
div.ywsjtjBoxCls>div.timerBarCls>span{
    flex: 1;
    font-size: 30px;
    display: block;
    line-height: 107px;
    text-align: right;
}
div.ywsjtjBoxCls>div.weishiRecordCls{
    margin-top: 30px;
    background-size: 45px 91px;
    background-position: center top;
    background-image: url("../../../assets/images/dataScreen/weishiRecBg.png");
    background-repeat: no-repeat;
    position:absolute;
    color: #dfcc50;
    font-size: 30px;
    height: 200px;
    padding-top: 100px;
    position: absolute;
    
}
div.dayBarCls{
    height: 97px;
    line-height: 97px;
    width: 1440px;
    margin-left: 30px;
    display: flex;
}
div.dayBarCls>div{
    float: left;
    font-size: 30px;
    flex: 1;
    text-align: center;
    border: 3px solid #07375A;
    cursor: pointer;
}
div.dayBarCls>div:nth-child(n+2){
    margin-left: -3px;
}
div.dayBarCls>div.seled{
background: #3DA1FF;
}
div.jdtj3Cls{
    width: 2121px;
    height: 100%;
    margin-left: 50px;
    float: left;
}
.paihangbanCls{
    background-image: url("../../../assets/images/dataScreen/bsyBg.png");
    background-repeat: no-repeat;
    background-size: 2100px 247px;
    background-position: center bottom;
    width: 2121px;
    height: 600px;
    display: flex;

}
.paihangbanCls>div.ban1Cls{
    background-image: url("../../../assets/images/dataScreen/ban1.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}
.paihangbanCls>div.iot1{
    background-image: url("../../../assets/images/dataScreen/eggIot1.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}
.paihangbanCls>div.ban2Cls{
    background-image: url("../../../assets/images/dataScreen/ban2.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}
.paihangbanCls>div.iot2{
    background-image: url("../../../assets/images/dataScreen/eggIot2.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}
.paihangbanCls>div.ban3Cls{
    background-image: url("../../../assets/images/dataScreen/ban3.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}
.paihangbanCls>div.iot3{
    background-image: url("../../../assets/images/dataScreen/eggIot3.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}

.paihangbanCls>div.ban4Cls{
    background-image: url("../../../assets/images/dataScreen/ban4.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}
.paihangbanCls>div.iot4{
    background-image: url("../../../assets/images/dataScreen/eggIot4.png");
    background-size: 419px 468px;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    flex: 1;
}
.paihangbanCls>div>div{
    font-size: 40px;
    text-align: center;
}
.paihangbanCls>div>div:nth-child(1){
    font-size: 80px;
    font-weight: bolder;
    margin-top: 110px;
}
.paihangbanCls>div>div:nth-child(2){
    
}
.paihangbanCls>div>div:nth-child(1).hsCls{
    color: #ffda31;
}
.paihangbanCls>div>div:nth-child(1).lanCls{
    color: #00eafe;
}

div.moxingCls{
    background-color: #091a34;
    width: 2121px;
height: 1229px;
margin-top: 40px;
}
.video {
        width: 2120px; 
        height: 1229px;
    }

    
div.jdtj3Cls>div.jdjhtjCls{
    width: 100%;
    float: left;
    height: 874px;
    margin-top: 40px;
    background: #091a34;
    border: 2px solid #1585D5;
    position: relative;

}

div.jdtj3Cls>div.jdjhtjCls>div.jiao1Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao1.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    left: -6px;
}
div.jdtj3Cls>div.jdjhtjCls>div.jiao2Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao2.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    right: -6px;
}
div.jdtj3Cls>div.jdjhtjCls>div.jiao3Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao3.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    right: -6px;
}
div.jdtj3Cls>div.jdjhtjCls>div.jiao4Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao4.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    left: -6px;
}
div.jdtj3Cls>div.jdjhtjCls>div.jdtjTitleBgCls{

    background-size: cover;
    background-position: center center;
    background-image: url("../../../assets/images/dataScreen/tjTitleBg.png");
    width: 100%;
    height: 139px;
    text-align: center;
    font-size: 48px;
    line-height: 139px;
}

div.sikuangBoxCls{
    width: 100%;
    float: left;
    background: #091a34;
    border: 2px solid #1585D5;
    position: relative;
}
div.titleBgCls{

    background-size: cover;
    background-position: center center;
    background-image: url("../../../assets/images/dataScreen/tjTitleBg.png");
    height: 139px;
    line-height: 139px;
    text-align: center;
    font-size: 48px;
}
div.sikuangBoxCls>div.jiao1Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao1.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    left: -6px;
}
div.sikuangBoxCls>div.jiao2Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao2.png");
    width: 48px;
    height: 48px;
    position: absolute;
    top: -6px;
    right: -6px;
}
div.sikuangBoxCls>div.jiao3Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao3.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    right: -6px;
}
div.sikuangBoxCls>div.jiao4Cls{
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/juxingjiao4.png");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: -6px;
    left: -6px;
}
div.jdtj4Cls{
    width: 1500px;
    height: 100%;
    float: left;
    margin-left: 40px;
}

div.wenduBoxCls{
    
    background-size: cover;
    background-image: url("../../../assets/images/dataScreen/wenduBg.png");
    width: 484px;
    height: 488px;
    margin-top: 40px;
    margin-left: 80px;
    text-align: center;
    padding-top: 140px;
    font-size: 130px;
    font-weight: bolder;
    float: left;
}
div.wulianwangValueBoxCls{
    float: left;
    font-size: 50px;

}
div.wulianwangValueBoxCls>div{
    height: 90px;
    line-height: 90px;
    width: 800px;
    text-indent: 70px;
    background-size: 50px 50px;
    background-repeat: no-repeat;
}
div.wulianwangValueBoxCls>div>span:nth-child(1){
    display:block;
    float: left;
}
div.wulianwangValueBoxCls>div>span:nth-child(2){
    display:block;
    float: right;
}

div.wulianwangValueBoxCls>div:nth-child(1){
    background-position: 0px center;
    background-image: url("../../../assets/images/dataScreen/wu1.png");
    padding-left: 0px;
}
div.wulianwangValueBoxCls>div:nth-child(2){
    background-image: url("../../../assets/images/dataScreen/wu2.png");
    background-position: 50px center;
    padding-left: 50px;
}
div.wulianwangValueBoxCls>div:nth-child(3){
    background-image: url("../../../assets/images/dataScreen/wu3.png");
    background-position: 100px center;
    padding-left: 100px;
}
div.wulianwangValueBoxCls>div:nth-child(4){
    background-image: url("../../../assets/images/dataScreen/wu4.png");
    background-position: 100px center;
    padding-left: 100px;
}
div.wulianwangValueBoxCls>div:nth-child(5){
    background-image: url("../../../assets/images/dataScreen/wu5.png");
    background-position: 50px center;
    padding-left: 50px;
}
div.wulianwangValueBoxCls>div:nth-child(6){
    background-image: url("../../../assets/images/dataScreen/wu6.png");
    background-position: 0px center;
    padding-left: 0px;
}
div.vedioRowCls{
    float: left;
}
div.vedioRowCls>div{
    width: 715px;
    height: 642px;
    float: left;
    margin-left: 20px;
    position: relative;
}
div.vedioColTitleCls{
    font-size: 45px;
    position: absolute;
    bottom: 0px;
    text-align: right;
    width: 100%;
    background-image: url("../../../assets/images/dataScreen/vTitleBc.png");
    height: 102px;
    line-height: 102px;
    padding-right: 20px;
}

div.jdtj5Cls{
    background-color: #091a34;
    width: 1090px;
    height: 2780px;
    float: left;
    margin-left: 40px;
    position: relative;
}
div.biaotiCls{
    background-image: url("../../../assets/images/dataScreen/kouhaoBg.png");
    background-repeat: no-repeat;
    background-size: 804px 58px;
    background-position: center center;
    height: 150px;
    width: 100%;

}
div.zhutiCls{
    font-size: 40px;
    color: #00B7EE;
}

div.zhutiCls>p{
    text-indent: 80px;
    padding-right: 40px;
    padding-left: 40px;
    text-align:justify;
    line-height: 70px;
    text-justify:distribute-all-lines;
}
div.welcomeCls{
    width: 100%;
    height: 852px;
    background-image: url("../../../assets/images/dataScreen/huanyingguanli.png");
    background-size: 985px 752px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
}
div.welcomeCls>div.tuCls{
    /* background-color: #00FB40; */
    background-image: url("../../../assets/images/dataScreen/ttt.png");
    width: 941px;
    height: 508px;
    border-radius: 30px;
    position: absolute;
    bottom: 70px;
    left: 76px;

}
div.erweimaCls{
    background-image: url("../../../assets/images/dataScreen/erweima.png");
    width: 100%;
    height: 507px;
    background-size: 985px 507px;
    background-repeat: no-repeat;
    background-position: center center;

}
.echarts1 {
    width: 100%;
    height: 1200px;
}
.echarts2 {
    width: 100%;
    height: 600px;
}
.echarts-icon {
    width: 54px;
    height: 54px;
    display: block;
}

.grid-title {
    width: 109px;
    height: 154px;
    line-height: 154px;
    text-align: center;
    font-size: 40px;
}
.grid {
    width: 121px;
    height: 516px;
    box-sizing: border-box;
    border: 1px solid #0A284A;
    font-size: 26px;
}
.grid-date {
    width: 109px;
    text-align: center;
    font-size: 40px;
    margin-top: 20px;
    color: #fff;
}
.treatment-icon {
    width: 47px;
    height: 46px;
}
.disease-text-1 {
    color: #00EB35;
 }
 .disease-text-2 {
    color: #E8C418;
 }
</style>