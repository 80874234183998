<template>
  <div>
        <div id="man" class="body-weight"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
// @ is an alias to /src
export default {
  name: 'sheepHose',
  data(){
      return{
          xList: [],
          kfList: [],
          yfList: []
      }
  },
  props: {
    // echartsMonth: {
    //     type: Array
    // }
    //   echartsDetermination: {
    //       type: Object
    //   },
    //   eweProduce: {
    //       type: Object
    //   },
    //   echartsSex: {
    //       type: String
    //   } 
  },
  mounted(){
    //   this.man()
  },
  watch: {

  },
  methods:{
      man() {
        var chartDom = document.getElementById('man');
        var myChart = echarts.init(chartDom);
        var option;
        var xData = this.xList;
        
        var y1 = this.yfList; //生产
        var y2 = this.kfList;
        option = {
            // title: {
            //     text: '配种图表(单位: 只)',
            //     //subtext: '纯属虚构'
            //      textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
            //         fontFamily: 'Arial, Verdana, sans...',
            //         fontSize: 14,
            //         color: '#0B1947',
            //         fontWeight: 'normal',
            //     },
            // },


            grid:{
                top: 50,
                left: 180,
                right: 40,
                bottom:  70,
                borderWidth:1
            },
            // tooltip: {
            //     trigger: 'axis'
            // },
           color: ['#00D4F9', '#FED400'],
            // legend: {
            //     icon: 'rectangle',
            //     data: ['生产', '配种', '怀孕'],
            //     right: '4%'
            // },
            // toolbox: {
            //     show: false,
            //     feature: {
            //         dataView: {show: true, readOnly: false},
            //         magicType: {show: true, type: ['line', 'bar']},
            //         restore: {show: true},
            //         saveAsImage: {show: true}
            //     }
            // },
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    data: xData,
                    splitLine:{
                        show:false
                    },
                    axisLabel : {
                        textStyle: { //改变刻度字体样式
                            color: '#C1C2D0',
                            fontSize: 50
                        }
                    },
                    splitArea: {
                        show: true,
                        interval: 0,
                        areaStyle: {
                            color: [
                                new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                                    offset: 0, color: '#1F1461' // 0% 处的颜色
                                }, {
                                    offset: 1, color: '#0A182A' // 100% 处的颜色
                                }]),
                                new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                                    offset: 0, color: '#0A182A' // 0% 处的颜色
                                },  {
                                    offset: 1, color: '#1F1461' // 100% 处的颜色
                                }]),
                            ],
                        },
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine:{
                　　　　show:false
                　　 },
                    axisLabel : {
                        textStyle: { //改变刻度字体样式
                            color: '#ccc',
                            fontSize: 50
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
               }
            ],
            series: [
                {
                    name: '',
                    type: 'line',
                    data: y2,
                    // smooth: 0.3,
                    // yAxisIndex: 1,// 这里要设置哪个y轴，默认是最左边的是0，然后1，2顺序来
                    symbol: 'circle', // 折线点设置为实心点
                    symbolSize: 25, // 折线点的大小
                    lineStyle:{
                        color:'#FED400',
                        width: 10
                    },
                    

                    // areaStyle: {
                        //     opacity: 0.8,
                        //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //         offset: 0,
                        //         color: 'rgba(246, 214, 97)'
                        //     }, {
                        //         offset: 1,
                        //         color: 'rgba(246, 214, 97, 0.1)'
                        //     }])
                        // },
                    itemStyle: {
                        normal: {
                            color: "#0B46F3"//设置折线点颜色
                        },
                    }
                },
                {
                    name: '',
                    type: 'bar',
                    data: y1,
                    barWidth : 50,//柱图宽度
                    color:'#00D4F9',

                    emphasis: {
                        focus: "series",
                    },
                    itemStyle: {
                        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        emphasis: {
                            barBorderRadius: 50
                        },
                        normal: {
                            barBorderRadius:[50, 50, 50, 50],
                        },
                        color:'#00D4F9',
                        
                    },
                },
            ]
        };
        option && myChart.setOption(option);
      }
  }
}
</script>

<style scoped>
    .body-weight {
        width: 100%;
        height: 400px;
    }
</style>