<template>
  <div>
    <div id="homeActualCount" class="body-weight"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import * as echarts from "echarts";
export default {
  name: "homeActualCount",
  data() {
    return {
      Rydata: []
    };
  },
  mounted() {
    // this.actualCount();
  },
  methods: {
    actualCount() {
      var chartDom = document.getElementById("homeActualCount");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          show: false,
          trigger: "item",
        },
        legend: {
          show: false,
          orient: "vertical",
          left: "right",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["50%", "75%"],
            labelLine: {
              normal: {
                length: 50,
              },
            },

            label: {
              //饼图图形上的文本标签
              normal: {
                show: true,
                textStyle: {
                  fontSize: 50, //文字的字体大小
                  color: "#ccc",
                },
              },
            },
            data: this.Rydata,
          },
        ],
        color: ["#F7C650", "#00D6E8", "#7C00FF", "#007AFF"],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped>
.body-weight {
  width: 100%;
  height: 500px;
}
</style>