<template>
    <div class="dataScreenContent">
        <div class="tjCls">
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon2Cls"></div>
                <div class="numCls">{{info.totalArea}}</div>
                <div class="titleCls">总面积（㎡）</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon4Cls"></div>
                <div class="numCls">{{info.totalVolume}}</div>
                <div class="titleCls">总容积（吨）</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon1Cls"></div>
                <div class="numCls">{{info.totalServiceEnterprise}}</div>
                <div class="titleCls">服务企业（家）</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div class="dsIcon5Cls"></div>
                <div class="numCls">{{info.totalColdStorage}}</div>
                <div class="titleCls">冷库个数（个）</div>
            </div>
            <div :class="['ds2Bgbox', 'animate__animated', timeNum >= 0 ? 'animate__bounceInDown' : '']">
                <div v-for="(item, i) in info.datascreen10CropsBOS" :key="i">{{item.cropsName}}</div>
                <!-- <div>苹果</div>
                <div>辣椒</div>
                <div>山药</div>
                <div>萝卜</div>
                <div>牛肉</div>
                <div>玉米</div>
                <div>红薯</div>
                <div>啥盖菜</div>
                <div>豆腐</div>
                <div>蔬菜</div> -->
            </div>
        </div>
        <div class="mapCls">
            <baidu-map v-if="isXs" :center="center" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
                </baidu-map>
            
            <!-- <div class="nongjicheCls" v-for="(item,index) in datascreen10StorageEnterpriseBOList" :key="item.id" :style="{top: item.mapY+'px',left: item.mapX+'px'}" @click="clickDetailCls(index+1)">
                <div class="nongjiDetailCls" v-show="isShow==index+1">
                    <div>贮藏农产品：{{item.mainStoredAgriculturalProducts}}</div>
                    <div>{{item.enterpriseName}}</div>
                    <div>总面积：{{item.totalArea}}</div>
                    <div>容积：{{item.coldStorageVolume}}</div>
                    <div>地址：{{item.addr}}</div>
                    <div>联系人：{{item.contacts}} {{item.contactNumber}}</div>
                </div>
            </div> -->
        </div>
        <div class="animate__animated animate__fadeInRight">
            <div class="dsBox3">
                <div class="yzcyCls" style="width:2950px">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">仓储企业</div>
                    </div>
                    <div class="qiyeTableCls qiyeTableHeadCls">
                        <div>#</div>
                        <div>企业名称</div>
                        <div>冷库类型</div>
                        <div>冷库单体个数</div>
                        <div>容积</div>
                        <div>主要贮藏农产品</div>
                    </div>

                    <list-scroll style="height: 1000px;" :speed="1" v-if="isDkShow">
                        <div class="list">
                            <div class="qiyeTableCls qiyeTableRowCls" v-for="(item, i) in datascreen10StorageEnterpriseBOList" :key="i">
                                <div>{{i+1}}</div>
                                <div class="by1">{{item.enterpriseName}}</div>
                                <div class="by1">{{item.coldStorageType}}</div>
                                <div class="by1">{{item.coldStorageQuantity}}</div>
                                <div class="by1">{{item.coldStorageVolume}}</div>
                                <div class="by1">{{item.mainStoredAgriculturalProducts}}</div>
                            </div>
                        </div>
                    </list-scroll>
                    <!-- <marquee style=" height: 880px;overflow: hidden;" direction="up" behavior="scroll" scrollamount="5"> -->
                    
                        
                        
         

                    <!-- </marquee> -->
                </div>

                <div class="yzcyCls" style="width:2950px">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls" style="margin-bottom:154px;">
                        <div class="fnt">仓储类型</div>
                    </div>
                    <!-- <div class="cclxBoxCls">
                    </div> -->
                    
                    <div class="cclxBoxClsEcharts flex flex_wrap f_jc_b">
                        <div class="type-item flex f_jc_b"  v-for="(item, i) in info.datascreen10StoretypeBOS" :key="i">
                            <div class="square squarelt"></div>
                            <div class="square squarert"></div>
                            <div class="square squarelb"></div>
                            <div class="square squarerb"></div>
                            <div class="type-title flex f_jc_c f_ai_c ">
                                <div class="type-title-text">{{item.typeName}}</div>
                            </div>
                            <div class="type-main">
                                <div class="type-main-title">数量</div>
                                <div class="type-main-count">
                                    <span class="fs100">{{item.typeNum}}</span>
                                    <span class="fs40 ml15">{{item.typeNumUnit}}</span>
                                </div>
                            </div>
                            <div class="type-main">
                                <div class="type-main-title">容量</div>
                                <div class="type-main-count">
                                    <span class="fs100">{{item.typeVolume}}</span>
                                    <span class="fs40 ml15">{{item.typeVolumeUnit}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- <Highecharts1 ref="storageType"></Highecharts1> -->
                    </div>
                </div>
                <div class="yzcyCls" style="width:2950px">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">各乡镇冷链设施基础信息</div>
                    </div>
                    <div class="qiyeTableCls qiyeTableCls1 qiyeTableHeadCls">
                        <div>#</div>
                        <div>乡镇</div>
                        <div>冷库单体个数</div>
                        <div>冷库总面积(㎡)</div>
                        <div>冷库内容积(m³)</div>
                        <div>冷库容积(吨)</div>
                    </div>

                    <!-- <marquee style="height: 880px;overflow: hidden;" direction="up" behavior="scroll" scrollamount="5"> -->

                    <list-scroll style="height: 1000px;" :speed="1" v-if="isDkShow1">
                        <div class="list">
                            <div class="qiyeTableCls qiyeTableCls1 qiyeTableRowCls"  v-for="(item, i) in info.datascreen10TownshipColdchainInfoBOS" :key="i">
                                <div>{{i+1}}</div>
                                <div class="by1">{{item.townshipName}}</div>
                                <div class="by1">{{item.coldStorageNum}}</div>
                                <div class="by1">{{item.coldStorageArea}}</div>
                                <div class="by1">{{item.coldStorageInVolume}}</div>
                                <div class="by1">{{item.coldStorageVolume}}</div>
                            </div>
                        </div>
                    </list-scroll>
                    <!-- <div id="llsb10yyp" style="height: 1000px; overflow: hidden;">
                        <div id="llsb10yyp1">
                            <div class="qiyeTableCls qiyeTableCls1 qiyeTableRowCls"  v-for="(item, i) in info.datascreen10TownshipColdchainInfoBOS" :key="i">
                                <div>{{i+1}}</div>
                                <div class="by1">{{item.townshipName}}</div>
                                <div class="by1">{{item.coldStorageNum}}</div>
                                <div class="by1">{{item.coldStorageArea}}</div>
                                <div class="by1">{{item.coldStorageInVolume}}</div>
                                <div class="by1">{{item.coldStorageVolume}}</div>
                            </div>
                        </div>
                        <div v-if="isYes2">
                            <div class="qiyeTableCls qiyeTableCls1 qiyeTableRowCls"  v-for="(item, i) in info.datascreen10TownshipColdchainInfoBOS" :key="i">
                                <div>{{i+1}}</div>
                                <div class="by1">{{item.townshipName}}</div>
                                <div class="by1">{{item.coldStorageNum}}</div>
                                <div class="by1">{{item.coldStorageArea}}</div>
                                <div class="by1">{{item.coldStorageInVolume}}</div>
                                <div class="by1">{{item.coldStorageVolume}}</div>
                            </div>
                        </div>
                    </div> -->
                    
                        
                

                    <!-- </marquee> -->
                </div>
                <div class="yzcyCls" style="width:2950px">
                    <div class="ysStCls"></div>
                    <div class="zxStCls"></div>
                    <div class="qiyeTitleBoxCls">
                        <div class="fnt">仓储贮藏主要农产品能力统计</div>
                    </div>
                    <!-- <div class="cczcylBoxCls">
                  
                    </div> -->
                    <div class="cczcylBoxClsEcarts">
                        <Highecharts2 ref="zcyl"></Highecharts2>
                    </div>
                </div>
            </div>

            <div class="yzcyBCls" v-if="isTrue">
                <div class="ysStCls"></div>
                <div class="zxStCls"></div>
                <div class="vTitleCls">冷库实时画面</div>
                <div class="vBoxCls">
                <div class="vconBoxCls" :key="i" v-for="(item, i) in info.deviceEnterpriseRelationBOS"  >
                    <div class="vedioCls"  >
                        <!-- <EZUIKitJs msg="video1" :attribute="environmentalAttribute"
                            :flv="info.jidiVedio1Url" /> -->
                            <div class="video-title-top flex f_jc_b">
                                <div class="video-title-item by1 flex f_ai_c">
                                    <div class="video-circle"></div>
                                    <div class="fs40">{{item.warehousingType}}</div>
                                </div>
                                <div class="video-title-item by1 flex f_ai_c">
                                    <div class="video-circle"></div>
                                    <div style="width: 300px;" class="fs40 by1">{{item.storeCropsType}}</div>
                                </div>
                            </div>
                            <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="environmentalAttribute" :flv="item.deviceUrl" />
                            <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="environmentalAttribute" :flv="item.deviceUrl"/>
                        <div class="vedioTitleCls" @click="transitionFun(item.deviceUrl, item.installArea)">{{item.installArea}}</div>
                    </div>
                </div>
                    <!-- <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video2" :attribute="environmentalAttribute"
                                :flv="jidiVedio2Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(info.jidiVedio2Url, info.jidiVedio2Title)">
                                {{info.jidiVedio2Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video3" :attribute="environmentalAttribute"
                                :flv="info.jidiVedio3Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(info.jidiVedio3Url, info.jidiVedio3Title)">
                                {{info.jidiVedio3Title}}</div>
                        </div>

                    </div>
                    
                    <div class="vconBoxCls">

                        <div class="vedioCls">
                            <EZUIKitJs msg="video4" :attribute="environmentalAttribute"
                                :flv="info.jidiVedio4Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(info.jidiVedio4Url,  info.jidiVedio4Title)">
                                 {{info.jidiVedio4Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video5" :attribute="environmentalAttribute"
                                :flv="info.jidiVedio5Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(info.jidiVedio5Url,  info.jidiVedio5Title)">
                                 {{info.jidiVedio5Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video6" :attribute="environmentalAttribute"
                                :flv="info.jidiVedio6Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(info.jidiVedio6Url,  info.jidiVedio6Title)">
                                {{info.jidiVedio6Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video6" :attribute="environmentalAttribute"
                                :flv="info.jidiVedio7Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(info.jidiVedio7Url,  info.jidiVedio7Title)">
                                {{info.jidiVedio7Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video8" :attribute="environmentalAttribute"
                                :flv="info.jidiVedio8Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(info.jidiVedio8Url,  info.jidiVedio8Title)">
                                {{info.jidiVedio8Title}}</div>
                        </div>
                    </div>
                    <div class="vconBoxCls">
                        <div class="vedioCls">
                            <EZUIKitJs msg="video9" :attribute="environmentalAttribute"
                                :flv="info.jidiVedio9Url" />
                            <div class="vedioTitleCls"
                                @click="transitionFun(info.jidiVedio9Url,  info.jidiVedio9Title)">
                                {{info.jidiVedio9Title}}</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">


                    </div>
                    <div class="video-centent">
                        <div id="video111" :attribute="environmentalAttribute2" flv="" />
                        <div class="video-title">{{ vedioTitle }}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import Highecharts1 from '../highecharts/dataScreen10/highecharts1.vue'
import Highecharts2 from '../highecharts/dataScreen10/highecharts2.vue'
import ListScroll from "../../../components/listScroll.vue";
import 'animate.css';
export default {
    name: '',
    components: {
        EZUIKitJs,
        EZUIKitJs2,
        Highecharts1,
        Highecharts2,
        ListScroll
    },
    data() {
        return {
            info: {},
            datascreen10StorageEnterpriseBOList: [], //企业仓储
            timeNum: 0,
            environmentalAttribute: {
                width: 924,
                height: 710
            },
            timer1: null,
            timer2: null,
            isTrue: false,
            show: false,
            vedioTitle: "",
            player: null,
            townList: [],
            labels: [],
            pointList: [],
            isShow: 1,
            domHeight: 0,
            domHeight2: 0,
            isXs: false,
            isYes: false,
            isYes2: false,
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            potatoData: [],
            isDkShow: false,
            isDkShow1: false
        }
    },

    mounted() {
        this.getInfo()
        this.doPlay("");
        var timer = setInterval(() => {
            ++this.timeNum
            console.log(this.timeNum)
            if (this.timeNum > 4) {
                clearInterval(timer)
            }
        }, 50)
    },
    methods: {
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/quanquan.png"), new BMap.Size(1, 1), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(10, 25),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
                // 创建标注对象并添加到地图  
                console.log(this.potatoData)
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].mapX, this.potatoData[i].mapY);   
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);  
                var content = '<div class="nongjiDetailCls" v-show="isShow==index+1"><div style="line-height: 140px">'+this.potatoData[i].mainStoredAgriculturalProducts+'</div><div style="margin-top: 50px; font-size: 75px; line-height: 140px">'+this.potatoData[i].enterpriseName+'</div><div>总面积：'+this.potatoData[i].totalArea+'</div><div>容积：'+this.potatoData[i].coldStorageVolume+'</div><div>地址：'+this.potatoData[i].addr+'</div><div>联系人：'+this.potatoData[i].contacts+' '+ this.potatoData[i].contactNumber+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(80, -260)
                })  
                this.pointList.push(marker);
                this.labels.push(label);

                map.addOverlay(label);
                this.clickBox(this.potatoData[i], i);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    display: 'none',
                    fontSize: '59px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/2bfaa176c0c042e7904b85f9129696f7ef85623c7b74fd6836b22d9cba3aba53.png")',
                    backgroundColor: 'transparent',
                    height: '751px',
                    paddingLeft: '80px',
                    paddingRight: '40px',
                    backgroundSize: '100% 100%'
                }) 
                
                
            }
            for(let i = 0; i < this.townList.length; i++) {
                // if(this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) {
                    var point = new BMap.Point(this.townList[i].mapX, this.townList[i].mapY);   
                    var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.townList[i].townshipName + '</div><div>'+this.townList[i].yuliu1 +'</div>'+'<div>'+this.townList[i].yuliu2+'</div>'+'<div>'+this.townList[i].yuliu3+'</div></div>';
                    var label = new BMap.Label(content, {       // 创建文本标注
                        position: point,
                        offset: new BMap.Size(-200, -368)
                    })  
                    map.addOverlay(label);
                    label.setStyle({                              // 设置label的样式
                        color: '#fff',
                        fontSize: '50px',
                        border: '0',
                        backgroundImage: (this.townList[i].yuliu1 || this.townList[i].yuliu2 || this.townList[i].yuliu3) ? 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")' : '',
                        backgroundColor: 'transparent',
                        height: '348px',
                        padding: '15px 35px 46px',
                        backgroundSize: '100% 100%'
                    })
                // }
                
            }

            // var yhPoint = new BMap.Point(this.mlsObj.yhList[0].mapX,this.mlsObj.yhList[0].mapY);
            // var yhMaker = new BMap.Marker(yhPoint, {icon: myIcon});
            // map.addOverlay(yhMaker);   
            //  var yhContent = this.mlsObj.yhList[0].enterpriseName + '<br />'+ this.mlsObj.yhList[0].enterpriseValue + '亩';
            //     var yhLabel = new BMap.Label(yhContent, {      
            //         position: yhPoint,
            //         offset: new BMap.Size(20, -240)
            //     })  

            //     map.addOverlay(yhLabel);
            //     var that = this;
            // yhLabel.addEventListener('click', function(){
            //     that.$parent.tabsTypeClick('4');
            // })
            // yhLabel.setStyle({                              
            //         color: '#fff',
            //         fontSize: '50px',
            //         border: '0',
            //         backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
            //         backgroundColor: 'transparent',
            //         height: '208px',
            //         padding: '15px 35px 46px',
            //         backgroundSize: '100% 100%'
            //     })
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            map.enableScrollWheelZoom(true);
        },
        clickBox(row, index) {
          var that = this;
          this.pointList[index].addEventListener('click', function(){
              for(let j = 0; j < that.labels.length; j++) {
                  if(j == index) {
                      that.labels[j].setStyle({
                        display: 'block'
                    })
                  } else {
                      that.labels[j].setStyle({
                        display: 'none'
                    })
                  }
              }
              

          })  
        },
        clickDetailCls (num) {
            if (this.isShow == num) {
                this.isShow = 0;
            } else {
                this.isShow = num;                
            }
        },
        getInfo() {
            this.qa.queryDatascreen10ById({}).then(res => {
                if(res.respCode == '0000') {
                    this.info = res.data
                    this.isDkShow1 = true;
                    this.isTrue = true;
                    if(res.data.datascreen10StorageEnterpriseBOList != null) {
                        this.datascreen10StorageEnterpriseBOList = res.data.datascreen10StorageEnterpriseBOList;
                        this.isDkShow = true;
                        this.potatoData = res.data.datascreen10StorageEnterpriseBOList;
                    }
                    this.isXs = true;
                    if(res.data.datascreen10StorageTypeList != null) {
                        var xData=[];
                        var yData=[];
                        for (var i=0; i<res.data.datascreen10StorageTypeList.length; i++) {
                            xData.push(res.data.datascreen10StorageTypeList[i].coldStorageType);
                            yData.push(res.data.datascreen10StorageTypeList[i].coldStorageQuantity);
                        }
                        this.$nextTick(function () {
                            this.$refs.storageType.xData = xData
                            this.$refs.storageType.yData = yData
                            this.$refs.storageType.actualCount()
                        })
                    }

                    if(res.data.datascreen10ConsumptionStatisticsBOList != null) {
                        var xData1=[];
                        var yData1=[];
                        for (var i=0; i<res.data.datascreen10ConsumptionStatisticsBOList.length; i++) {
                            xData1.push(res.data.datascreen10ConsumptionStatisticsBOList[i].storedProductName);
                            yData1.push(res.data.datascreen10ConsumptionStatisticsBOList[i].storedProductValue);
                        }
                        this.$nextTick(function () {
                            this.$refs.zcyl.xData = xData1
                            this.$refs.zcyl.yData = yData1
                            this.$refs.zcyl.actualCount()
                        })
                    }

                    // if(res.data.datascreen11WarnBOList != null) {
                    //     this.datascreen11WarnBOList = res.data.datascreen11WarnBOList;
                    // }
                    this.$nextTick(() => {
                        this.autoplay();
                        this.autoplay2();
                    })
                }
                
            })

            this.qa.datascreen10TownshipMapConfqueryForList({}).then(res => {
                this.townList = res.data;
            })
            
        },
        autoplay(){
            var dom = document.getElementById("ccqy10yyp");
            var dom1 = document.getElementById("ccqy10yyp1");

            var that = this;
            console.log(dom1.offsetHeight)
            console.log(dom.offsetHeight)
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes = true;
                if(this.timer1) {
                    clearInterval(this.timer1);
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer1 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight = 0;
                        dom.scrollTop = that.domHeight;
                    } else {
                        that.domHeight++;
                        dom.scrollTop = that.domHeight;
                    }
                }, 20)
            }
        },
        autoplay2(){
            var dom = document.getElementById("llsb10yyp");
            var dom1 = document.getElementById("llsb10yyp1");

            var that = this;

            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes2 = true;
                if(this.timer2) {
                    clearInterval(this.timer2);
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer2 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight2 = 0;
                        dom.scrollTop = that.domHeight2;
                    } else {
                        that.domHeight2++;
                        dom.scrollTop = that.domHeight2;
                    }
                }, 20)
            }
        },
        goDataScreen2() {
            this.$emit('tabsTypeClick', '2');
        },
        doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },
        transitionFun(_url, _title) {
            this.doPlay(_url);
            this.show = !this.show;
            this.vedioTitle = _title;
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
    transform: translateX(100px);
    opacity: 0;
}

.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.videdo-box {
    display: flex;
    align-items: center;
}

.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}

.video-title {
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}

.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;
}

.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}

.dataScreenContent {
    color: #ccc;
    font-size: 100px;
}

div.ds2Bgbox {
    background-image: url("../../../assets/images/dataScreen/ds2_bg_box.png");
    width: 809px;
    min-height: 404px;
    background-size: 100% 100%;
    position: relative;
    font-size: 42px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #fff;
    padding: 60px;
}

div.ds2Bgbox>div {
    float: left;
    height: 70px;
    line-height: 70px;
    margin-right: 40px;
}

div.dsBgbox {
    background-image: url("../../../assets/images/dataScreen/ds1_bg_box.png");
    width: 809px;
    height: 320px;
    background-size: 100% 100%;
    position: relative;
    font-size: 101px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #23D1FF;
}

div.dsBgbox>div.dsIcon1Cls {
    background-image: url("../../../assets/images/dataScreen/ds_icon3.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.dsIcon2Cls {
    background-image: url("../../../assets/images/dataScreen/ds_icon2.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.dsIcon4Cls {
    background-image: url("../../../assets/images/dataScreen/ds_icon4.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.dsIcon5Cls {
    background-image: url("../../../assets/images/dataScreen/ds_icon5.png");
    background-size: cover;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.numCls {
    width: 100%;
    margin-left: 260px;
    padding-top: 40px;
}

div.dsBgbox>div.titleCls {
    width: 100%;
    height: 228px;
    margin-left: 270px;
    font-size: 51px;
    color: #FFFFFF;
}

div.numCls>span.dwCls {
    font-size: 40px;
}

div.tjCls,
div.mapCls {
    float: left;
    position: relative;
    z-index: 999;
}

div.tjCls>div:nth-child(n+1) {
    margin-top: 135px;
}

div.mapCls {
    margin-top: -150px;
    width: 3987px;
    height: 3176px;
    margin-left: -800px;
    position: relative;
    z-index: 100;
    background-image: url("../../../assets/images/dataScreen/map.png");
}

div.mapCls>div.yangCls {
    position: absolute;
    top: 1000px;
    left: 1800px;
    background-image: url("../../../assets/images/dataScreen/yang1.png");
    width: 1341px;
    height: 618px;
    padding-top: 65px;
    font-weight: bolder;
    padding-left: 150px;

}

div.mapCls>div.yangCls>div:nth-child(1) {
    font-size: 75px;

}

div.mapCls>div.yangCls>div:nth-child(2) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 100px;
}

div.mapCls>div.yangCls>div:nth-child(3) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 20px;
}

div.dsBox3 {
    margin-left: 100px;
    float: left;
    width: 6096px;
    height: 3000px;
}

.marquee-list {
    margin-top: 100px;
    overflow: hidden;
}

div.dsBox3>div.yzcyCls:nth-child(n+3) {
    margin-top: 50px;
}

div.dsBox3>div.yzcyCls {
    float: left;
    width: 1940px;
    height: 1481px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
    margin-left: 50px;
}

div.dsBox3>div.yzcyCls>div.qiyeTitleBoxCls {
    position: relative;
    font-weight: bolder;
    font-size: 75px;
    background-image: url("../../../assets/images/dataScreen/zhizhi.png");
    background-size: 1650px 71px;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
}

div.dsBox3>div.yzcyCls>div.qiyeTitleBoxCls>div.fnt {
    text-align: center !important;
}


div.ysStCls {
    background-image: url("../../../assets/images/dataScreen/ysSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    top: -8px;
    left: -8px;

}

div.zxStCls {
    background-image: url("../../../assets/images/dataScreen/zxSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    bottom: -8px;
    right: -8px;

}

div.yzcyCls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzcy2Cls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzTableHeaderCls {
    margin-top: 40px;
}

div.yzTableHeaderCls>div {
    float: left;
    color: #A4DF11;
    font-size: 59px;
}

div.yzTableHeaderCls>div:nth-child(1),
div.yzTableRowCls>div:nth-child(1) {
    width: 300px;
}

div.yzTableHeaderCls>div:nth-child(2),
div.yzTableRowCls>div:nth-child(2) {
    width: 250px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(3),
div.yzTableRowCls>div:nth-child(3) {
    width: 275px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(4),
div.yzTableRowCls>div:nth-child(4) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(5),
div.yzTableRowCls>div:nth-child(5) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(6),
div.yzTableRowCls>div:nth-child(6) {
    width: 500px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(7),
div.yzTableRowCls>div:nth-child(7) {
    width: 300px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(8),
div.yzTableRowCls>div:nth-child(8) {
    width: 275px;
    text-align: right;
}

div.yzTableRowCls>div {
    height: 120px;
    line-height: 120px;
    float: left;
    color: #00FB40;
    font-size: 59px;
}


div.vedioCls:nth-child(2n+1) {
    margin-left: 25px;
    margin-right: 0px;
}

div.vedioCls {
    width: 935px;
    height: 717px;
    background: #0E1639;
    border: 5px solid #123171;
    float: left;
    margin-top: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
}

div.vedioCls>div.vedioTitleCls {
    background-image: url("../../../assets/images/dataScreen/vedioTitlebg.png");
    position: absolute;
    font-size: 60px;
    color: #fff;
    bottom: 20px;
    right: 0px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-align: right;
    padding-right: 50px;
}

div.yzcy3Cls {
    width: 5369px;
    height: 2902px;
    margin-left: 100px;
    float: left;
    position: relative;
    background-color: #01003d;
}

div.yzcy3Cls>div.ys1Cls {
    background-image: url("../../../assets/images/dataScreen/jx1.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    left: 0;
}

div.yzcy3Cls>div.ys2Cls {
    background-image: url("../../../assets/images/dataScreen/jx2.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    right: 0;
}

div.yzcy3Cls>div.ys3Cls {
    background-image: url("../../../assets/images/dataScreen/jx3.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    right: 0;
}

div.yzcy3Cls>div.ys4Cls {
    background-image: url("../../../assets/images/dataScreen/jx4.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    left: 0;
}

div.paibianCls {
    background-image: url("../../../assets/images/dataScreen/paibian.png");
    width: 1638px;
    height: 118px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

div.yzcy3Cls>div.mapsCls {
    background-image: url("../../../assets/images/dataScreen/map.png");
    width: 2981px;
    height: 2881px;
    background-size: cover;
    margin-left: 200px;
    float: left;
    position: relative;

}

div.jaigepai1Cls {
    position: absolute;
    top: 350px;
    left: 1100px;
}

div.jaigepai2Cls {
    position: absolute;
    top: 1320px;
    left: 200px;
}

div.jaigepai3Cls {
    position: absolute;
    top: 1220px;
    left: 980px;
}

div.jaigepai4Cls {
    position: absolute;
    top: 1090px;
    left: 1970px;
}

div.jaigepai5Cls {
    position: absolute;
    top: 1550px;
    left: 1470px;
}

div.jaigepai6Cls {
    position: absolute;
    top: 2100px;
    left: 1950px;
}

div.gjiageCls {
    background-image: url("../../../assets/images/dataScreen/g448.png");
    width: 295px;
    height: 105px;
    line-height: 105px;
    text-align: center;
    font-size: 45px;

}

div.gjiageCls>span {
    font-size: 65px;
}

div.yzcy3Cls {

    overflow: hidden;
}

div.textListBoxCls {
    float: left;
    width: 1950px;
    height: 100%;
    margin-top: 200px;
    padding-left: 100px;

}

div.textListBoxCls>div.rouyangTableCls {
    width: 1950px;
    height: 1690px;

}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div {
    float: left;
    background-color: #00c29a;
    color: #fff;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls>div {
    float: left;
    color: #3de2d6;
    font-size: 45px;
    height: 80px;
    line-height: 80px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(1),
div.bodyCls>div.rowCls>div:nth-child(1) {
    width: 400px;
    text-indent: 50px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(2),
div.bodyCls>div.rowCls>div:nth-child(2) {
    width: 650px;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(3),
div.bodyCls>div.rowCls>div:nth-child(3) {
    width: 300px;
    text-align: center;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(4),
div.bodyCls>div.rowCls>div:nth-child(4) {
    width: 250px;
    text-align: center;
}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div:nth-child(5),
div.bodyCls>div.rowCls>div:nth-child(5) {
    width: 340px;
}

div.bodyCls>div.rowCls>div:nth-child(5) {
    padding-right: 40px;
}

div.textListBoxCls>div.rouyangTableCls>marquee>div.bodyCls>div.rowCls:nth-child(2n+1)>div {
    background: rgba(73, 232, 217, 0.2);
}

div.tubiaoCls {
    position: relative;
    margin-top: 50px;
}

.money {
    position: absolute;
    top: 140px;
    left: 110px;
    color: #3de2d6;
}

.days {
    right: -210px;
    bottom: 0px;
    color: #3de2d6;
    position: absolute;
}

div.zhoushititleCls {
    font-size: 74px;
    margin-bottom: 50px;
}

div.zoushituCls {
    /*background-image: url("../../../assets/images/dataScreen/111.png");*/
    width: 1951px;
    height: 674px;
}

div.qiyetongjiBoxCls {
    height: 380px;
    margin-top: 100px;
    display: flex;
    font-size: 48px;
    color: #FFFFFF;
}

div.qiyetongjiBoxCls>div {
    background-size: 283px 168px;
    background-repeat: no-repeat;
    background-position: center bottom;
    text-align: center;
    flex: 1;
}

div.qiyetongjiBoxCls>div>div {
    text-align: center;
}

div.qiyetongjiBoxCls>div>div:nth-child(1) {
    font-size: 64px;
    height: 100px;
    line-height: 100px;
    font-weight: bolder;
}

div.qiyetongjiBoxCls>div>div:nth-child(2) {
    font-size: 48px;
}

div.qiyetongjiBoxCls>div:nth-child(1) {
    background-image: url("../../../assets/images/dataScreen/qqq1.png");
}

div.qiyetongjiBoxCls>div:nth-child(2) {
    background-image: url("../../../assets/images/dataScreen/qqq2.png");
}

div.qiyetongjiBoxCls>div:nth-child(3) {
    background-image: url("../../../assets/images/dataScreen/qqq3.png");
}

div.qiyetongjiBoxCls>div:nth-child(4) {
    background-image: url("../../../assets/images/dataScreen/qqq4.png");
}

div.qiyeContentCls {
    text-indent: 100px;
    width: 100%;
    height: 420px;
    font-size: 50px;
    color: #6FB6FF;
    line-height: 80px;
    text-align: justify;
    text-justify: distribute-all-lines;
}

div.yzcyBCls {
    position: relative;
    width: 3300px;
    height: 3010px;
    float: left;
    background: #0E1639;
    border: 5px solid #123171;
    padding: 85px;
}

div.yzcyBCls>div.vTitleCls {
    font-size: 74px;
    font-weight: bolder;
    height: 147px;
    line-height: 147px;
    background-image: url("../../../assets/images/dataScreen/malinshu-title.png");
    background-repeat: no-repeat;
    background-size: 1163px 147px;
    text-indent: 60px;

}

div.nongjiDetailCls {
    background-image: url("../../../assets/images/dataScreen/nongjidetail2.png");
    background-repeat: no-repeat;
    width: 1193px;
    height: 819px;
    background-size: cover;
    position: absolute;
    z-index: 10;
    padding-left: 160px;
    padding-top: 30px;
    padding-right: 100px;
    margin-top: 240px;
    margin-left: 120px;
}

div.nongjiDetailCls>div:nth-child(1) {
    font-size: 58px;
    height: 120px;
    line-height: 120px;
    font-weight: bolder;
    color: #fff;
}

div.nongjiDetailCls>div:nth-child(2) {
    font-size: 70px;
    height: 150px;
    line-height: 150px;
    font-weight: bolder;
    border-bottom: 3px solid #35b7db;
    margin-top: 60px;
    margin-bottom: 60px;
    color: #fff;
}

div.nongjiDetailCls>div:nth-child(n+3) {
    font-size: 53px;
    height: 80px;
    line-height: 80px;
    color: #fff;

}

div.vBoxCls {
    width: 100%;
    height: 400px;
    margin-top: 60px;
}

div.vBoxCls>div.vconBoxCls {
    width: 995px;
    height: 800px;
    background: #0a284e;
    border: 5px solid #123354;
    float: left;
    margin-top: 60px;
    margin-right: 60px;
}

div.vBoxCls>div.vconBoxCls:nth-child(3n) {
    margin-right: 0px;
}

div.mapCls>div.nongjicheCls {
    background-image: url("../../../assets/images/dataScreen/quanquan.png");
    width: 107px;
    height: 123px;
    display: flex;
    align-items: center;
    position: absolute;
    background-size: cover;
    cursor: pointer;
}

div.qiyeTableCls>div {
    float: left;
}





div.qiyeTableCls>div:nth-child(1) {
    width: 150px;
}

div.qiyeTableCls>div:nth-child(2) {
    width: 900px;
}

div.qiyeTableCls>div:nth-child(3) {
    width: 400px;
}

div.qiyeTableCls>div:nth-child(4) {
    width: 400px;
}

div.qiyeTableCls>div:nth-child(5) {
    width: 350px;
}

div.qiyeTableCls>div:nth-child(6) {
    width: 500px;
}


div.qiyeTableCls1>div:nth-child(1) {
    width: 150px;
}

div.qiyeTableCls1>div:nth-child(2) {
    width: 500px;
    text-align: center;
}

div.qiyeTableCls1>div:nth-child(3) {
    width: 500px;
    text-align: center;    
}

div.qiyeTableCls1>div:nth-child(4) {
    width: 500px;
    text-align: center;    
}

div.qiyeTableCls1>div:nth-child(5) {
    width: 500px;
    text-align: center;    
}

div.qiyeTableCls1>div:nth-child(6) {
    width: 500px;
    text-align: center;    
}



div.qiyeTableCls.qiyeTableHeadCls {
    margin-top: 100px;
    font-size: 53px;
    color: #fff;
    border-bottom: 1px solid #212a44;
    height: 155px;
    line-height: 155px;

}

div.qiyeTableCls.qiyeTableRowCls {
    font-size: 53px;
    color: #fff;
    border-bottom: 1px solid #212a44;
    height: 155px;
    line-height: 155px;
    color: #6db8ff;

}

div.qiyeTableCls.qiyeTableRowCls>div:nth-child(4) {
    color: #fba72f;
}

div.qiyeTableCls.qiyeTableRowCls>div:nth-child(6) {
    color: #00f3bd;
}
div.cclxBoxCls{
    background-image: url("../../../assets/images/dataScreen/tmp5.png");
    background-size: 2601px 1029px;
    background-repeat: no-repeat;
    background-position: center;
    width: 2750px;
    height: 1200px;

}
.cclxBoxClsEcharts {
    width: 2750px;
    
}
div.cczcylBoxCls{
    background-image: url("../../../assets/images/dataScreen/tmp6.png");
    background-size: 5372px 928px;
    background-repeat: no-repeat;
    background-position: center;
    width: 5800px;
    height: 1230px;

}
.cczcylBoxClsEcarts {
    width: 2900px;
    height: 1230px;
    
}
.type-item {
    width: 1270px;
    height: 385px;
    position: relative;
    margin-bottom: 154px;
}
.type-title {
    width: 120px;
    height: 100%;
    background: url("../../../assets/images/dataScreen/typeLeft.png") no-repeat;
    background-size: 100% 100%;
}

.type-title-text {
    width: 80%;
    text-align: center;
    font-size: 60px;
    line-height: 80px;
    color: #FFBD64;
}
.square {
    width: 15px;
    height: 15px;
    background: #fff;
    position: absolute;
}
.squarelt {
    left: -4px;
    top: -4px;
}
.squarert {
    right: -4px;
    top: -4px;
}
.squarelb {
    left: -4px;
    bottom: -4px;
}
.squarerb {
    right: -4px;
    bottom: -4px;
}
.type-main{
    width: 557px;
    height: 100%;
    background: url("../../../assets/images/dataScreen/typeBg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 30px;
}
.type-main-title {
    font-size: 48px;
    color: #fff;
    text-align: center;
}
.type-main-count {
    margin-top: 90px;
    text-align: center;
    color: #00F6FF;
}
.video-title-top {
    width: 840px;
    position: absolute;
    top: 27px;
    left: 46px;
    z-index: 5000;
}
.video-circle {
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 50%;
    margin-right: 20px;
}
.video-title-item {
    width: 405px;
    height: 99px;
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
    box-sizing: border-box;
    padding-left: 51px;
}
</style>