<template>
   <div class="test">777</div>
</template>

<script>
export default {
    name: '',
    data(){
        return{

        }
    },

    mounted(){

    },
    methods:{
        
    }
}
</script>

<style scoped>
.test {
        color: #ccc;
        font-size: 100px;
    }
</style>