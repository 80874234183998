<template>
    <div class="dataScreenContent">
        <div class="tjCls">
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0?'animate__bounceInDown': '']">
                <div class="dsIconCls"></div>
                <div class="numCls">{{yangObj.yearFeedingQuantity}}<span class="dwCls">万</span></div>
                <div class="titleCls">年饲养量（只）</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0?'animate__bounceInDown': '']">
                <div class="dsIconCls map-left-icon-2"></div>
                <div class="numCls">{{yangObj.yearAnnualInventory}}<span class="dwCls">万</span></div>
                <div class="titleCls">年存栏量（只）</div>
            </div>
            <div :class="['dsBgbox', 'animate__animated', timeNum >= 0?'animate__bounceInDown': '']">
                <div class="dsIconCls map-left-icon-3"></div>
                <div class="numCls">{{yangObj.yearAnnualSales}}<span class="dwCls">万</span></div>
                <div class="titleCls">年出栏量（只）</div>
            </div>
        </div>
        <div class="mapCls">
            <baidu-map v-if="isTrue" :center="center" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
            </baidu-map>
            <!-- <div class="sheep animate__animated animate__fadeInUp" v-for="item in yangObj.datascreen1BaseBOList" :key="item.id" :style="{top: item.yAxis+'px',left: item.xAxis+'px'}" @click="goDataScreen2">
                <img src="../../../assets/images/dataScreen/egg/sheep.gif" style="width: 100px" alt="">
                <div class="yangCls  animate__animated animate__fadeInUp" v-if="item.position==1" style="top:-490px;left:50px;" @click="goDataScreen2">
                    <div>{{item.baseName}}</div>
                    <div>面积：{{item.baseArea}}㎡</div>
                    <div>地址：{{item.baseAddr}}</div>
                </div>
                <div class="yangCls teshu animate__animated animate__fadeInUp" v-else style="top:100px;left:50px;" @click="goDataScreen2">
                    <div>{{item.baseName}}</div>
                    <div>面积：{{item.baseArea}}㎡</div>
                    <div>地址：{{item.baseAddr}}</div>
                </div>
            </div> -->

            <!--<div class="yangCls teshu animate__animated animate__fadeInUp" @click="goDataScreen2">
                    <div>榆林学院白绒山羊繁育基地</div>
                    <div>面积：394,000 ㎡</div>
                    <div>地址：榆阳区牛家梁镇常乐堡村</div>
                    <div class="sheep animate__animated animate__fadeInUp" style="position:absolute;top: -96px; left: -36px;">
                        <img src="../../../assets/images/dataScreen/egg/sheep.gif" style="width: 100px" alt="">
                    </div>
            </div>-->

            <!--<div class="yangCls  animate__animated animate__fadeInUp" style="top: 420px; left: 740px;" @click="goDataScreen2">
                <div>榆阳区白绒山羊育种基地</div>
                <div>面积：488,586 ㎡</div>
                <div>地址：榆阳区马合镇补兔村</div>

            </div>
            <div class="sheep animate__animated animate__fadeInUp" style="top: 910px; left: 690px;">
                <img src="../../../assets/images/dataScreen/egg/sheep.gif" style="width: 100px" alt="">
            </div>-->

            <!--<div class="yangCls  animate__animated animate__fadeInUp" style="top: 920px; left: 980px;" @click="goDataScreen2">
                <div>10万只新中盛湖羊养殖基地</div>
                <div>面积：450,000 ㎡</div>
                <div>地址：榆阳区小纪汗镇大纪汗村</div>

            </div>
            <div class="sheep animate__animated animate__fadeInUp" style="top: 1390px; left: 940px;">
                <img src="../../../assets/images/dataScreen/egg/sheep.gif" style="width: 100px" alt="">
            </div>-->

<!-- 
            <div class="yangCls  animate__animated animate__fadeInUp" style="top: 1670px; left: 2000px;" @click="goDataScreen2">
                    <div>榆林学院白绒山羊繁育基地</div>
                    <div>面积：394,000</div>
                    <div>地址：榆阳区牛家梁镇常乐堡村</div>
                

            </div>
            <div class="sheep animate__animated animate__fadeInUp" style="top: 1590px; left: 1940px;">
                <img src="../../../assets/images/dataScreen/egg/sheep.gif" style="width: 100px" alt="">
            </div> -->
            <!-- <div class="shuliang">
                    111111
            </div> -->
        </div>
        <div class="animate__animated animate__fadeInRight">
            <div class="dsBox3">
            <div class="yzcyCls">
                <div class="ysStCls"></div>
                <div class="zxStCls"></div>
                <div class="titleCls">
                    <div>各乡镇羊子饲养量及相关畜产品一览</div>
                    <div>单位：只 / 吨</div>
                </div>
                <div class="yzTableHeaderCls">
                    <div>乡镇</div>
                    <div>存栏</div>
                    <div>出栏</div>
                    <div>山羊绒产量</div>
                    <div>山羊毛产量</div>
                    <div>绵羊毛产量</div>
                    <div>羊肉</div>
                    <div>羊奶</div>
                </div>
                <!-- <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5"> -->
                <!-- <div id="sheep1yyp" style="height:600px; overflow: hidden; clear: both"> -->
                    <list-scroll style="height: 600px; clear: both" :speed="1" v-if="isDkShow">
                        <div class="list">
                            <div class="yzTableRowCls" v-for="(item,index) in yangObj.datascreen1IndustrialTownshipBOList" :key="index">
                                <div>{{item.township}}</div>
                                <div>{{item.sheepStock}}</div>
                                <div>{{item.sheepOutStock}}</div>
                                <div>{{item.goatLintYield}}</div>
                                <div>{{item.goatGrossOutput}}</div>
                                <div>{{item.sheepGrossOutput}}</div>
                                <div>{{item.weightOfMutton}}</div>
                                <div>{{item.weightOfGoatMilk}}</div>
                            </div>
                        </div>
                    </list-scroll>
                    <!-- <div id="sheep1yyp1">
                        <div class="yzTableRowCls" v-for="(item,index) in yangObj.datascreen1IndustrialTownshipBOList" :key="index">
                            <div>{{item.township}}</div>
                            <div>{{item.sheepStock}}</div>
                            <div>{{item.sheepOutStock}}</div>
                            <div>{{item.goatLintYield}}</div>
                            <div>{{item.goatGrossOutput}}</div>
                            <div>{{item.sheepGrossOutput}}</div>
                            <div>{{item.weightOfMutton}}</div>
                            <div>{{item.weightOfGoatMilk}}</div>
                        </div>
                    </div>
                    <div v-if="isYes">
                        <div class="yzTableRowCls" v-for="(item,index) in yangObj.datascreen1IndustrialTownshipBOList" :key="index">
                            <div>{{item.township}}</div>
                            <div>{{item.sheepStock}}</div>
                            <div>{{item.sheepOutStock}}</div>
                            <div>{{item.goatLintYield}}</div>
                            <div>{{item.goatGrossOutput}}</div>
                            <div>{{item.sheepGrossOutput}}</div>
                            <div>{{item.weightOfMutton}}</div>
                            <div>{{item.weightOfGoatMilk}}</div>
                        </div>
                    </div> -->
                <!-- </div> -->
                    
                <!-- </marquee> -->
            </div>
            <div class="yzcy2Cls" v-if="isTrue">
                <div class="ysStCls"></div>
                <div class="zxStCls"></div>
                <div class="titleCls">示范基地实时画面</div>
                <div class="vedioCls" style="overflow: hidden;" v-for="(item, i) in yangObj.deviceEnterpriseRelationBOS" :key="i">
                    <!-- <div class="vedioIot">
                    </div> -->
                    <div class="iotQx flex f_ai_c f_jc_b" style="height: 106px; width: 95%; margin: 0 auto;">
                        <el-carousel height="106px" style="width: 100%" indicator-position="none" interval="6000">
                            <el-carousel-item style="width: 100%" >
                                <div class="flex" style="justify-content: space-around; align-items: center; line-height: 106px">
                                    <div class="flex f_ai_c">
                                        <img style="width: 40px;height: 40px;" src="@/assets/images/dataScreen/sheep/meteorological1.png" alt="">
                                        <div class="fs40 ml6" style="color: #36FF1D;" v-if="qxList[i]">{{(qxList[i].temperature1 && qxList[i].temperature1 > 0) ? qxList[i].temperature1 : 27}}</div>
                                        <div class="cfff fs30">℃</div>
                                    </div>
                                    <div class="flex f_ai_c">
                                        <img style="width: 40px;height: 40px;" src="@/assets/images/dataScreen/sheep/meteorological2.png" alt="">
                                        <div class="fs40 ml6" style="color: #36FF1D;" v-if="qxList[i]">{{(qxList[i].humidity1 && qxList[i].humidity1 > 0) ? qxList[i].humidity1 : 19}}</div>
                                        <div class="cfff fs30">%RH</div>
                                    </div>
                                    <div class="flex f_ai_c">
                                        <img style="width: 40px;height: 40px;" src="@/assets/images/dataScreen/sheep/meteorological3.png" alt="">
                                        <div class="fs40 ml6" style="color: #EA66AF;" v-if="qxList[i]">{{(qxList[i].co21 && qxList[i].co21 > 0) ? qxList[i].co21 : 432}}</div>
                                        <div class="cfff fs30">ppm</div>
                                    </div>
                                    <div class="flex f_ai_c">
                                        <img style="width: 40px;height: 40px;" src="@/assets/images/dataScreen/sheep/meteorological1.png" alt="">
                                        <div class="fs40 ml6" style="color: #EA66AF;" v-if="qxList[i]">{{(qxList[i].temperatureOut && qxList[i].temperatureOut > 0) ? qxList[i].temperatureOut : 27}}</div>
                                        <div class="cfff fs30">℃</div>
                                    </div>
                                </div>
                                
                            </el-carousel-item>
                            <el-carousel-item>
                                <div class="flex" style="justify-content: space-around; align-items: center; line-height: 106px">
                                <div class="flex f_ai_c">
                                    <img style="width: 40px;height: 40px;" src="@/assets/images/dataScreen/sheep/meteorological2.png" alt="">
                                    <div class="fs40 ml6" style="color: #EA66AF;" v-if="qxList[i]">{{(qxList[i].humidityOut && qxList[i].humidityOut > 0) ? qxList[i].humidityOut : 18}}</div>
                                    <div class="cfff fs30">%RH</div>
                                </div>
                                <div class="flex f_ai_c">
                                    <img style="width: 40px;height: 40px;" src="@/assets/images/dataScreen/sheep/meteorological6.png" alt="">
                                    <div class="fs40 ml6" style="color: #47E5E5;" v-if="qxList[i]">{{(qxList[i].lightIntensity && qxList[i].lightIntensity > 0) ? qxList[i].lightIntensity : 31615}}</div>
                                    <div class="cfff fs30">Lux</div>
                                </div>
                                <div class="flex f_ai_c">
                                    <img style="width: 40px;height: 40px;" src="@/assets/images/dataScreen/sheep/meteorological7.png" alt="">
                                    <div class="fs40 ml6" style="color: #47E5E5;" v-if="qxList[i]">{{(qxList[i].windSpeed && qxList[i].windSpeed > 0) ? qxList[i].windSpeed : 1.1}}</div>
                                    <div class="cfff fs30">m/s</div>
                                </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                        
                        
                        <!-- <div class="flex f_ai_c">
                            <img style="width: 40px;height: 40px;" src="@/assets/images/dataScreen/sheep/meteorological8.png" alt="">
                            <div class="fs40 ml6" style="color: #47E5E5;">9</div>
                            <div class="cfff fs30">m</div>
                        </div> -->
                    </div>
                 
                    <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="environmentalAttribute" :flv="item.deviceUrl" />
                    <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="environmentalAttribute" :flv="item.deviceUrl"/>
                    <!-- <EZUIKitJs msg="video1" :attribute="environmentalAttribute"
                        :flv="yangObj.shifanjidiVedio1Url" /> -->
                    <div class="vedioTitleCls"  @click="transitionFun(item.deviceUrl, item.installArea)">{{item.installArea}}</div>
                </div>
                <!-- <div class="vedioCls">
                    <EZUIKitJs msg="video2" :attribute="environmentalAttribute"
                        :flv="yangObj.shifanjidiVedio2Url" />
                    <div class="vedioTitleCls"  @click="transitionFun(yangObj.shifanjidiVedio2Url, yangObj.shifanjidiVedio2Title)">{{yangObj.shifanjidiVedio2Title}}</div>
                </div>
                <div class="vedioCls">
                    <EZUIKitJs msg="video3" :attribute="environmentalAttribute"
                        :flv="yangObj.shifanjidiVedio3Url" />
                    <div class="vedioTitleCls"  @click="transitionFun(yangObj.shifanjidiVedio3Url, yangObj.shifanjidiVedio3Title)">{{yangObj.shifanjidiVedio3Title}}</div>
                </div>
                <div class="vedioCls">
                    <EZUIKitJs msg="video4" :attribute="environmentalAttribute"
                        :flv="yangObj.shifanjidiVedio4Url" />
                    <div class="vedioTitleCls"  @click="transitionFun(yangObj.shifanjidiVedio4Url, yangObj.shifanjidiVedio4Title)">{{yangObj.shifanjidiVedio4Title}}</div>
                </div> -->
            </div>
        </div>
        <div class="yzcy3Cls">
            <div class="ys1Cls"></div>
            <div class="ys2Cls"></div>
            <div class="ys3Cls"></div>
            <div class="ys4Cls"></div>
            <div class="paibianCls"></div>
            <DIV class="mapsCls">
                <baidu-map v-if="isTrue" :center="center" :zoom="zoom" @ready="handler2" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
                </baidu-map>
                <!-- <div class="gjiageCls jaigepai1Cls"><span>40</span>/斤</div>
                <div class="gjiageCls jaigepai2Cls"><span>42</span>/斤</div>
                <div class="gjiageCls jaigepai3Cls"><span>41</span>/斤</div>
                <div class="gjiageCls jaigepai4Cls"><span>42</span>/斤</div>
                <div class="gjiageCls jaigepai5Cls"><span>48</span>/斤</div>
                <div class="gjiageCls jaigepai6Cls"><span>43</span>/斤</div> -->
            </DIV>
            <DIV class="textListBoxCls">
                <div class="rouyangTableCls">
                    <div class="headerCls">
                        <div style="width: 330px; text-indent: 50px">卖家</div>
                        <div style="width: 430px">乡镇</div>
                        <div style="width: 200px">羊只性别</div>
                        <div style="width: 300px; text-align: center">羊只月龄</div>
                        <div style="width: 350px; text-align: center">数量</div>
                        <div style="width: 340px">订单时间</div>
                    </div>

                    <list-scroll style="height: 1560px; clear: both" :speed="1" v-if="isDkShow">
                        <div class="list bodyCls">
                            <div class="rowCls flex" v-for="(item,index) in yangObj.datascreen1ExponentialGraphBOList" :key="index">
                                <div>{{item.head}}</div>
                                <div>{{item.townshipName}}</div>
                                <div>{{item.sex}}</div>
                                <div>{{item.agemoon}}</div>
                                <div>{{item.count}}</div>
                                <div>{{item.orderTime}}</div>
                            </div>
                        </div>
                    </list-scroll>
                    <!-- <marquee class="marquee-list2" direction="up" behavior="scroll" scrollamount="5"> -->
                        <!-- <div class="bodyCls" id="ddyr1yyp" style="height:2000px; overflow: hidden; clear: both"> 
                            <div id="ddyr1yyp1">
                                <div class="rowCls" v-for="(item,index) in yangObj.datascreen1ExponentialGraphBOList" :key="index">
                                    <div>{{item.head}}</div>
                                    <div>{{item.townshipName}}</div>
                                    <div>{{item.sex}}</div>
                                    <div>{{item.agemoon}}</div>
                                    <div>{{item.count}}</div>
                                    <div>{{item.orderTime}}</div>
                                </div>
                            </div>
                            <div v-if="isYes2">
                                <div class="rowCls" v-for="(item,index) in yangObj.datascreen1ExponentialGraphBOList" :key="index">
                                    <div>{{item.head}}</div>
                                    <div>{{item.townshipName}}</div>
                                    <div>{{item.sex}}</div>
                                    <div>{{item.agemoon}}</div>
                                    <div>{{item.count}}</div>
                                    <div>{{item.orderTime}}</div>
                                </div>
                            </div>
                            
                        </div> -->
                    <!-- </marquee> -->
                </div>
                <div class="tubiaoCls">
                    <div class="zhoushititleCls">近两年度羊肉价格走势（农贸市场）</div>
                    <div class="priceBox">
                        <span class="fs50 money">斤/元</span>
                        <div>
                            <span class="fs50 money" style="color: #4AE9D9;left: 1280px;">
                                <span style="display:inline-block;width: 53px;height: 5px;background: #4AE9D9;"></span>
                                <span style="margin-left:18px;">{{year1}}年</span>
                            </span>
                            <span class="fs50 money" style="left: 1608px;">
                                <span style="display:inline-block;width: 53px;height: 5px;background: #0F84FF;"></span>
                                <span style="margin-left:18px;">{{year2}}年</span>
                            </span>
                        </div>
                    </div>
                    <!--<span class="fs40 days">月</span>-->
                    <Highecharts1 ref="twenty" class="zoushituCls"></Highecharts1>
                </div>
            </DIV>
        </div>
        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">
                        

                    </div>
                    <div class="video-centent">
                        <div id="video111"
                        flv="" />
                        <div class="video-title">{{vedioTitle}}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
        </div>
        
    </div>
</template>

<script>
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import Highecharts1 from '../highecharts/dataScreen1/highecharts1.vue'
import ListScroll from "../../../components/listScroll.vue";
import cyberplayer from "../../../../static/cyberplayer.js"
import 'animate.css';
export default {
    name: '',
    components: {
        EZUIKitJs,
        EZUIKitJs2,
        Highecharts1,
        ListScroll
    },
    data() {
        return {
            yangObj: {},
            timeNum: 0,
            domHeight: 0,
            isYes: false,
            domHeight2: 0,
            isYes2: false,
            environmentalAttribute: {
                width: 1424,
                height: 1160
            },
            isDkShow: false,
            potatoData: [],
            isTrue: false,
            environmentalAttribute2:{
                width: 3094,
                height: 1510
            },
            qxList: [],
            labels: [],
            pointList: [],
            timer: null,
            timer1: null,
            show: false,
            vedioTitle:"",
            player:null,
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            year1: '--',
            year2: '--',
        }
    },

    mounted() {
        this.init();
        this.doPlay("");
        var timer = setInterval(() => {
            ++this.timeNum
            console.log(this.timeNum)
            if(this.timeNum > 4) {
                clearInterval(timer)
            }
        },50)

        this.iot();
        setInterval(() => {
            this.iot();
        },60000)
    },
    methods: {
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            // map.setTextSize({
            //     textSize: 'BMAP_POI_TYPE_LARGE'
            // });
            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/sheep/sheep.gif"), new BMap.Size(86, 86), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(86, 86),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });     
                // 创建标注对象并添加到地图  
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].xAxis, this.potatoData[i].yAxis);   
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   
                var content = '<div style="font-size: 60px; font-weight: 500; padding-bottom: 47px; border-bottom: 3px solid #47D6FF;">'+this.potatoData[i].baseName+'</div><div style="font-size: 40px; margin-top: 34px">面积：'+this.potatoData[i].baseArea+'</div><div style="font-size: 40px;margin-top: 38px">地址：'+this.potatoData[i].baseAddr+'</div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-60, -500)
                })  
                this.labels.push(label);
                
                map.addOverlay(label);
                // if (this.potatoData[i].keyEnterprises == 'Y') {
                    this.clickBox(this.potatoData[i], i);                       // 将标注添加到地图中
                // }
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-09/f57dba7fdb034dcbb368217508bf60e27c40392958836a055566da041b39027a.png")',
                    backgroundColor: 'transparent',
                    // height: '500px',
                    padding: '52px 56px 100px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            // for(let i = 0; i < this.yangObj.datascreen1WhirlyBOList.length; i++) {
            //     var point = new BMap.Point(this.yangObj.datascreen1WhirlyBOList[i].mapX, this.yangObj.datascreen1WhirlyBOList[i].mapY);   
            //     var marker = new BMap.Marker(point, {icon: myIcon});   
            //     map.addOverlay(marker);   
            // }

            for(let i = 0; i < this.yangObj.datascreen1IndustrialTownshipBOList.length; i++) {
                var point = new BMap.Point(this.yangObj.datascreen1IndustrialTownshipBOList[i].mapX, this.yangObj.datascreen1IndustrialTownshipBOList[i].mapY); 
                var content = '<div style="position: relative; height: 100%;"><div style="font-size: 50px; line-height: 106px; position: absolute; bottom: -166px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.yangObj.datascreen1IndustrialTownshipBOList[i].township+'</div><div>年饲养量：'+(parseFloat(this.yangObj.datascreen1IndustrialTownshipBOList[i].sheepStock)+parseFloat(this.yangObj.datascreen1IndustrialTownshipBOList[i].sheepOutStock))+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-280, -180)
                })  

                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")',
                    backgroundColor: 'transparent',
                    padding: '30px 56px 70px',
                    backgroundSize: '100% 100%'
                })
            }
            var userIcon = new BMap.Icon(require("../../../assets/images/dataScreen/sheep/user.png"), new BMap.Size(86, 104), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(86, 104),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            }); 
            for(let i = 0; i < this.yangObj.datascreen1WhirlyBOList.length; i++) {
                var points = new BMap.Point(this.yangObj.datascreen1WhirlyBOList[i].mapX, this.yangObj.datascreen1WhirlyBOList[i].mapY);   
                var markers = new BMap.Marker(points, {icon: userIcon});   
                this.pointList.push(markers);
                map.addOverlay(markers);   
                this.clickBoxs(this.yangObj.datascreen1WhirlyBOList[i], i);
            }

            
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            // var marker = new BMap.Marker(point) // 创建标注
            // map.addOverlay(marker) // 将标注添加到地图中
            // var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            // map.addOverlay(circle)
            map.enableScrollWheelZoom(true);
        },
        handler2({BMap, map}){
            map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
            for(let i = 0; i < this.yangObj.datascreen1SheepPriceBOList.length; i++) {
                var point = new BMap.Point(this.yangObj.datascreen1SheepPriceBOList[i].mapX, this.yangObj.datascreen1SheepPriceBOList[i].mapY);   
                
                var content = '<div style="position: relative; height: 100%;"><div style="font-size: 50px; line-height: 106px; position: absolute; bottom: -166px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.yangObj.datascreen1SheepPriceBOList[i].township+'</div><div>' + this.yangObj.datascreen1SheepPriceBOList[i].shopName+'</div><div style="text-align: center; font-size: 60px">'+this.yangObj.datascreen1SheepPriceBOList[i].generalPrice+'<span style="font-size: 48px">元/斤</span></div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-320, -280)
                })  

                
                map.addOverlay(label);
                // if (this.potatoData[i].keyEnterprises == 'Y') {
               // 将标注添加到地图中
                // }
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")',
                    backgroundColor: 'transparent',

                    padding: '20px 56px 70px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            var myIcon = new BMap.Icon(require("../../../assets/images/dataScreen/egg/icon1.png"), new BMap.Size(54, 54), {   
                // 指定定位位置。  
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
                // 图标中央下端的尖角位置。   
                anchor: new BMap.Size(54, 54),   
                // 设置图片偏移。  
                // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
                // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
                // imageOffset: new BMap.Size(0, 0 - 25)   // 设置图片偏移   
            });  
            for(let i = 0; i < this.yangObj.datascreen1ExponentialGraphBOList.length; i++) {
                var point = new BMap.Point(this.yangObj.datascreen1ExponentialGraphBOList[i].mapX, this.yangObj.datascreen1ExponentialGraphBOList[i].mapY);   
                var marker = new BMap.Marker(point, {icon: myIcon});   
                map.addOverlay(marker);   
            }
        },
        clickBox(row, index) {
          var that = this;
          this.labels[index].addEventListener('click', function(){
              sessionStorage.setItem("chooseSheep", JSON.stringify(row));
              that.$parent.tabsTypeClickById('2');
          })
        },
        clickBoxs(row, index) {
            var that = this;
          this.pointList[index].addEventListener('click', function(){
              that.transitionFun(row.url, row.whirlyName)
          })
        },
        autoplay(){
            var dom = document.getElementById("sheep1yyp");
            var dom1 = document.getElementById("sheep1yyp1");

            var that = this;

            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes = true;
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                if(this.timer) {
                    clearInterval(this.timer)
                }
                this.timer = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight = 0;
                        dom.scrollTop = that.domHeight;
                    } else {
                        that.domHeight++;
                        dom.scrollTop = that.domHeight;
                    }
                }, 20)
            }
        },
        autoplay2(){
            var dom = document.getElementById("ddyr1yyp");
            var dom1 = document.getElementById("ddyr1yyp1");

            var that = this;

            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes2 = true;
                if(this.timer1) {
                    clearInterval(this.timer1)
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer1 = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight2 = 0;
                        dom.scrollTop = that.domHeight2;
                    } else {
                        that.domHeight2++;
                        dom.scrollTop = that.domHeight2;
                    }
                }, 20)
            }
        },
        init(){
            this.qa.queryDatascreen1ById({}).then(res => {
                this.yangObj = res.data;
                this.isDkShow = true;
                this.potatoData = res.data.datascreen1BaseBOList;
                this.isTrue = true;
                
                let arr1 = [];
                let arr2 = [];
                let xarr = [];
                for (var i = 0; i < res.data.minAvgPriceBOList.length; i++) {
                    xarr.push(res.data.minAvgPriceBOList[i].month + '月');
                    arr1.push(res.data.minAvgPriceBOList[i].avgPrice);
                }
                for (var i = 0; i < res.data.maxAvgPriceBOList.length; i++) {
                    arr2.push(res.data.maxAvgPriceBOList[i].avgPrice);
                }
                this.year1 = res.data.minAvgPriceBOList[0].year;
                this.year2 = res.data.maxAvgPriceBOList[0].year;
                this.$refs.twenty.xData = xarr;
                this.$refs.twenty.yData1 = arr1;
                this.$refs.twenty.yData2 = arr2;
                this.$refs.twenty.year1 = res.data.minAvgPriceBOList[0].year;
                this.$refs.twenty.year2 = res.data.maxAvgPriceBOList[0].year;
                
                this.$refs.twenty.moreChart();
                // this.$nextTick(() => {
                //     this.autoplay();
                //     this.autoplay2();
                // })
                
            })
        },  
        iot(){
            this.qa.withOutQueryRedisByIotEmData2({houseIds: "521,519,520,521"}).then(res => {
                this.qxList = res.data.list;
            })
            // this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '01'}).then(res => {
                

            //         this.qxList = res.data;


            //     // this.qxList = res.data;
   
            // })
        },
        goDataScreen2() {
            this.$emit('tabsTypeClick','2');
        },
        doPlay(_url){
            this.qa.getAccessToken({}).then(res => {
            this.player =  new EZUIKit.EZUIKitPlayer({
                autoplay: true,
                id: "video111",
                accessToken: res.data.accessToken,
                url: _url,
                template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                // 视频上方头部控件
                //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                //plugin: ['talk'],                       // 加载插件，talk-对讲
                // 视频下方底部控件
                // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                // openSoundCallBack: data => console.log("开启声音回调", data),
                // closeSoundCallBack: data => console.log("关闭声音回调", data),
                // startSaveCallBack: data => console.log("开始录像回调", data),
                // stopSaveCallBack: data => console.log("录像回调", data),
                // capturePictureCallBack: data => console.log("截图成功回调", data),
                // fullScreenCallBack: data => console.log("全屏回调", data),
                // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                width: 3080,
                height: 1560
            });
            });
        },
        doPlay2(_url) {
            setTimeout(() => {
                 var player = cyberplayer('video111').setup({
                    width: 3080, // 宽度，也可以支持百分比（不过父元素宽度要有）
                    height: 1560, // 高度，也可以支持百分比
                    title: '111', // 标题
                    isLive: true, // 必须设置，表明是直播视频
                    file: _url, // //您的视频源的地址（目前是乐橙示例播放地址）
                    image: '', // 预览图
                    autostart: true, // 是否自动播放
                    stretching: "uniform", // 拉伸设置
                    repeat: true, // 是否重复播放
                    volume: 0, // 音量，注：仅当用户同意、网站由用户激活或媒体无声时允许自动播放
                    controls: true, // 是否显示控制栏
                    hls: {
                        reconnecttime: 5 // hls直播重连间隔秒数
                    },
                    ak: "a60324c9eed249a7812c629f4d10ee14" // 百度智能云平台注册（https://cloud.baidu.com）即可获得accessKey
                });
            },1000)
        },
        transitionFun(_url,_title) {

           
            this.doPlay(_url);

            this.show = !this.show;
            this.vedioTitle = _title;
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vedioIot {
    height: 106px;
    background: #fff;
}
.videdo-box {
    display: flex;
    align-items: center;
}
.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}
.video-title {
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}
.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;    
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;    
}
.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.dataScreenContent {
    color: #ccc;
    font-size: 100px;
}

div.dsBgbox {
    background-image: url("../../../assets/images/dataScreen/ds1_bg_box.png");
    width: 809px;
    height: 320px;
    background-size: 100% 100%;
    position: relative;
    font-size: 101px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #23D1FF;
}

div.dsBgbox>div.dsIconCls {
    background-image: url("../../../assets/images/dataScreen/ds_icon2.png");
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
div.dsBgbox>div.map-left-icon-2 {
    background-image: url('../../../assets/images/dataScreen/ds_icon1.png');
    background-repeat: no-repeat;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
div.dsBgbox>div.map-left-icon-3 {
    background-image: url('../../../assets/images/dataScreen/ds_icon6.png');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 50% 40%;
    width: 261px;
    height: 228px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

div.dsBgbox>div.numCls {
    width: 100%;
    margin-left: 260px;
    padding-top: 40px;
}

div.dsBgbox>div.titleCls {
    width: 100%;
    height: 228px;
    margin-left: 270px;
    font-size: 51px;
    color: #FFFFFF;
}

div.numCls>span.dwCls {
    font-size: 40px;
}

div.tjCls,
div.mapCls {
    float: left;
    z-index: 999;
    position: relative;
}

div.tjCls>div:nth-child(n+1) {
    margin-top: 135px;
}

div.mapCls {
    margin-top: 0px;
    width: 4687px;
    height: 2910px;
    margin-left: -800px;
    z-index: 100;

    position: relative;
    background-image: url("../../../assets/images/dataScreen/map.png");
}

div.mapCls>div.yangCls {
    position: absolute;
    background-size: 100% 100%;
    background-image: url("../../../assets/images/dataScreen/egg/duihua.png");
    width: 1041px;
    height: 518px;
    padding-top: 45px;
    font-weight: bolder;
    padding-left: 100px;

}
.yangCls {
    position: absolute;
    background-size: 100% 100%;
    background-image: url("../../../assets/images/dataScreen/egg/duihua.png");
    width: 1041px;
    height: 518px;
    padding-top: 45px;
    font-weight: bolder;
    padding-left: 100px;

}
.yangCls>div:nth-child(1) {
    font-size: 75px;

}
.yangCls>div:nth-child(2) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 100px;

}
.yangCls>div:nth-child(3) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 20px;

}

div.mapCls>div.teshu {
    padding-top: 105px;
    background-image: url("../../../assets/images/dataScreen/egg/fanzhe.png");

}

.teshu {
    padding-top: 105px;
    background-image: url("../../../assets/images/dataScreen/egg/fanzhe.png");

}

div.mapCls>div.sheep {
    position: absolute;


}

div.mapCls>div.shuliang {
    position: absolute;

    background: url("../../../assets/images/dataScreen/sheep/xiabiao.png") no-repeat center center;
    background-size: 200px 100px;
    width: 200px;
    height: 100px;
    padding-top: 65px;
    padding-left: 150px;

}

div.mapCls>div.yangCls>div:nth-child(1) {
    font-size: 75px;

}

div.mapCls>div.yangCls>div:nth-child(2) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 100px;
}

div.mapCls>div.yangCls>div:nth-child(3) {
    font-size: 53px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    opacity: 0.6;
    margin-top: 20px;
}

div.dsBox3 {
    margin-left: 200px;
    float: left;
    width: 3096px;
    height: 100%;
}

div.dsBox3>div.yzcyCls {
    float: left;
    width: 3096px;
    height: 1037px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
}

div.dsBox3>div.yzcy2Cls {
    margin-top: 60px;
    float: left;
    width: 3096px;
    height: 1816px;
    background: #0E1639;
    border: 5px solid #123171;
    position: relative;
    padding: 85px;
}

div.ysStCls {
    background-image: url("../../../assets/images/dataScreen/ysSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    top: -8px;
    left: -8px;

}

div.zxStCls {
    background-image: url("../../../assets/images/dataScreen/zxSt-jiao.png");
    width: 59px;
    height: 59px;
    position: absolute;
    bottom: -8px;
    right: -8px;

}

div.yzcyCls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    font-size: 75px;
    width: 100%;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzcy2Cls>div.titleCls {
    background-image: url("../../../assets/images/dataScreen/juxing.png");
    font-size: 75px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-indent: 40px;
}

div.yzTableHeaderCls {
    margin-top: 40px;
}

div.yzTableHeaderCls>div {
    float: left;
    color: #A4DF11;
    font-size: 59px;
}
div.yzTableRowCls {
    height: 120px;
}
div.yzTableHeaderCls>div:nth-child(1),
div.yzTableRowCls>div:nth-child(1) {
    width: 300px;
}

div.yzTableHeaderCls>div:nth-child(2),
div.yzTableRowCls>div:nth-child(2) {
    width: 250px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(3),
div.yzTableRowCls>div:nth-child(3) {
    width: 275px;
    text-align: right;
}

div.yzTableHeaderCls>div:nth-child(4),
div.yzTableRowCls>div:nth-child(4) {
    width: 400px;
    margin-left: 100px;
    text-align: center;
}

div.yzTableHeaderCls>div:nth-child(5),
div.yzTableRowCls>div:nth-child(5) {
    width: 400px;
    margin-left: 50px;    
    margin-right: 50px;    
    text-align: center;
}

div.yzTableHeaderCls>div:nth-child(6),
div.yzTableRowCls>div:nth-child(6) {
    width: 500px;
    text-align: center;
}

div.yzTableHeaderCls>div:nth-child(7),
div.yzTableRowCls>div:nth-child(7) {
    width: 300px;
    text-align: center;
}

div.yzTableHeaderCls>div:nth-child(8),
div.yzTableRowCls>div:nth-child(8) {
    width: 275px;
    text-align: center;
}

div.yzTableRowCls>div {
    height: 120px;
    line-height: 120px;
    float: left;
    color: #00FB40;
    font-size: 59px;
}



div.vedioCls:nth-child(2n+1) {
    margin-left: 25px;
    margin-right: 0px;
}

div.vedioCls {
    width: 1435px;
    height: 717px;
    background: #0E1639;
    border: 5px solid #123171;
    float: left;
    margin-top: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    
}

div.vedioCls>div.vedioTitleCls {
    background-image: url("../../../assets/images/dataScreen/vedioTitlebg.png");
    position: absolute;
    font-size: 70px;
    color: #fff;
    bottom: 20px;
    right: 0px;
    width: 1043px;
    height: 134px;
    line-height: 134px;
    text-align: right;
    padding-right: 50px;
}

div.yzcy3Cls {
    width: 5369px;
    height: 2902px;
    margin-left: 100px;
    float: left;
    position: relative;
    background-color: #0A131F;
}

div.yzcy3Cls>div.ys1Cls {
    background-image: url("../../../assets/images/dataScreen/jx1.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    left: 0;
}

div.yzcy3Cls>div.ys2Cls {
    background-image: url("../../../assets/images/dataScreen/jx2.png");
    width: 137px;
    height: 138px;
    position: absolute;
    top: 0;
    right: 0;
}

div.yzcy3Cls>div.ys3Cls {
    background-image: url("../../../assets/images/dataScreen/jx3.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    right: 0;
}

div.yzcy3Cls>div.ys4Cls {
    background-image: url("../../../assets/images/dataScreen/jx4.png");
    width: 137px;
    height: 138px;
    position: absolute;
    bottom: 0;
    left: 0;
}

div.paibianCls {
    background-image: url("../../../assets/images/dataScreen/paibian.png");
    width: 1638px;
    height: 118px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

div.yzcy3Cls>div.mapsCls {
    background-image: url("../../../assets/images/dataScreen/map.png");
    width: 2981px;
    height: 2681px;
    background-size: cover;
    margin-left: 200px;
    float: left;
    margin-top: 200px;
    position: relative;

}
div.jaigepai1Cls{
    position: absolute;
    top: 350px;
    left: 1100px;
}

div.jaigepai2Cls{
    position: absolute;
    top: 1320px;
    left: 200px;
}
div.jaigepai3Cls{
    position: absolute;
    top: 1220px;
    left:980px;
}
div.jaigepai4Cls{
    position: absolute;
    top: 1090px;
    left:1970px;
}
div.jaigepai5Cls{
    position: absolute;
    top: 1550px;
    left:1470px;
}
div.jaigepai6Cls{
    position: absolute;
    top: 2100px;
    left:1950px;
}
div.gjiageCls{
    background-image: url("../../../assets/images/dataScreen/g448.png");
    width: 295px;
    height:105px;
    line-height: 105px;
    text-align: center;
    font-size: 45px;

}
div.gjiageCls>span{
    font-size: 65px;
}

div.yzcy3Cls {

    overflow: hidden;
}

div.textListBoxCls {
    float: left;
    width: 1950px;
    height: 100%;
    margin-top: 200px;
    padding-left: 100px;

}

div.textListBoxCls>div.rouyangTableCls {
    width: 1950px;
    height: 1690px;

}

div.textListBoxCls>div.rouyangTableCls>div.headerCls>div {
    float: left;
    background-color: #0F84FF;
    color: #fff;
    font-size: 45px;
    height: 120px;
    line-height: 120px;
}

.bodyCls>div.rowCls>div {
    float: left;
    color: #3de2d6;
    font-size: 45px;
    height: 120px;
    line-height: 120px;
}

.bodyCls>div.rowCls>div:nth-child(1) {
    width: 330px;
    text-indent: 50px;
}

.bodyCls>div.rowCls>div:nth-child(2) {
    width: 430px;
}

.bodyCls>div.rowCls>div:nth-child(3) {
    width: 200px;
    text-align: center;    
}
.bodyCls>div.rowCls>div:nth-child(4) {
    width: 300px;
    text-align: center;
}

.bodyCls>div.rowCls>div:nth-child(5) {
    width: 350px;
    text-align: center;
}
.bodyCls>div.rowCls>div:nth-child(6) {
    width: 340px;
}

div.bodyCls>div.rowCls>div:nth-child(5) {
    padding-right: 40px;
}

.bodyCls>div.rowCls:nth-child(2n+1)>div {
    background: rgba(15, 132, 255, 0.2);
}
div.tubiaoCls{
    position: relative;
    margin-top: 50px;
}
.money {
    display: flex;
    align-items: center;
    position: absolute;
    top: 140px;
    left: 110px;
    color:#0F84FF;
} 
.days {
    right: -210px;
    bottom: 0px;
    color:#3de2d6;
    position: absolute;
}
div.zhoushititleCls{
    font-size: 60px;
    margin-bottom: 50px;
    width: 1100px;
    height: 114px;
    line-height: 114px;
    background: linear-gradient(to right,#1D5496,#01053D);
    padding-left: 78px;
}
div.zoushituCls{
    /*background-image: url("../../../assets/images/dataScreen/111.png");*/
    width: 1951px;
    height: 674px;
}
.priceBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>