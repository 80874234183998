<template>
  <div>
        <div id="echarts2" class="echarts2"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
// @ is an alias to /src
export default {
  name: 'sheepHose',
  data(){
      return{
          xData: [],
          yData: []
      }
  },
  props: {
    // echartsMonth: {
    //     type: Array
    // }
    //   echartsDetermination: {
    //       type: Object
    //   },
    //   eweProduce: {
    //       type: Object
    //   },
    //   echartsSex: {
    //       type: String
    //   } 
  },
  mounted(){
      //this.actualCount()
  },
  watch: {

  },
  methods:{
      actualCount() {
        var chartDom = document.getElementById('echarts2');
        var myChart = echarts.init(chartDom);
        var option;
        // var xData = ['马铃薯','蔬菜','豆制品','甘薯','果蔬','苹果','水果','冷冻猪羊肉','萝卜','牛肉','葡萄','肉品','沙盖菜','山药','辣椒','红薯','速冻甜糯玉米','鱼类肉食品']
        
        // var y1 = [60, 42, 5000, 5350, 5100, 5200]; //生产

        // function getRandomIntInclusive(min, max) {
        //     min = Math.ceil(min);
        //     max = Math.floor(max);
        //     return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值 
        // }  
        var arr = [];
        // for (var i = 0; i < this.xData.length; i++) {
        //     var num = getRandomIntInclusive(30, 80);
        //     num = parseInt(num, 10);
        //     arr.push(num);
        // }
        // this.yData = arr
        option = {
            // title: {
            //     text: '配种图表(单位: 只)',
            //     //subtext: '纯属虚构'
            //      textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
            //         fontFamily: 'Arial, Verdana, sans...',
            //         fontSize: 14,
            //         color: '#0B1947',
            //         fontWeight: 'normal',
            //     },
            // },


            grid:{
                top: 70,
                left: 180,
                right: 40,
                bottom:  70,
                borderWidth:1
            },
            // tooltip: {
            //     trigger: 'axis'
            // },
           color: ['#00D4F9', '#FED400'],
            // legend: {
            //     icon: 'rectangle',
            //     data: ['生产', '配种', '怀孕'],
            //     right: '4%'
            // },
            // toolbox: {
            //     show: false,
            //     feature: {
            //         dataView: {show: true, readOnly: false},
            //         magicType: {show: true, type: ['line', 'bar']},
            //         restore: {show: true},
            //         saveAsImage: {show: true}
            //     }
            // },
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    data: this.xData,
                    splitLine:{
                        show:false
                    },
                    axisLabel : {
                        margin: 20,
                        interval: 0,   
                        textStyle: { //改变刻度字体样式
                            color: '#6FB6FF',
                            fontSize: 50,
            
                        }
                    },
                    // splitArea: {
                    //     show: true,
                    //     interval: 0,
                    //     areaStyle: {
                    //         color: [
                    //             new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                    //                 offset: 0, color: '#1F1461' // 0% 处的颜色
                    //             }, {
                    //                 offset: 1, color: '#0A182A' // 100% 处的颜色
                    //             }]),
                    //             new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                    //                 offset: 0, color: '#0A182A' // 0% 处的颜色
                    //             },  {
                    //                 offset: 1, color: '#1F1461' // 100% 处的颜色
                    //             }]),
                    //         ],
                    //     },
                    // },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine:{
                        show:true,
                        lineStyle:{
                            color: '#142D4A',
                            width: 2,
                            type: 'solid'
                        }
                    },
                    axisLabel : {
                        textStyle: { //改变刻度字体样式
                            color: '#6FB6FF',
                            fontSize: 50
                        }
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick:{ //y轴刻度线
                        "show":false
                    },
               }
            ],
            series: [
                {
                    name: '',
                    type: 'bar',
                    data: this.yData,
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(255, 255, 255, 0.05)',
                    },

                    barWidth : 120,//柱图宽度
                    emphasis: {
                        focus: "series",
                    },
                    itemStyle: {
                        //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        // emphasis: {
                        //     barBorderRadius: 50
                        // },
                        // normal: {
                        //     barBorderRadius:[50, 50, 50, 50],
                        // },
                        
                        normal: {
                            label: {
                                show: true, //开启显示
                                position: 'top', //在上方显示
                                textStyle: { //数值样式
                                    color: '#fff',
                                    fontSize:  60
                                }
                            },
                            color: function(params) {
                                // build a color map as your need.
                                var colorList = [
                                '#167697','#92793B','#A2A7B7',
                                ];
                                return colorList[params.dataIndex % colorList.length]

                            },
                        },
                        
                        
                    },
                },
            ]
        };
        option && myChart.setOption(option);
      }
  }
}
</script>

<style scoped>
    .echarts2 {
        width: 100%;
        height: 1230px;
    }
</style>