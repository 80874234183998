<template>
  <div class="echarts-box">
    <div id="container"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  data(){
    return {
        xsyf: [],
        xssl: []
    }
  },
  mounted() {
    // this.moreChart();
  },
  methods: {
    moreChart() {
      var chartDom = document.getElementById('container');
        var myChart = echarts.init(chartDom);
        var option;
        var xData = this.xsyf;
        var yStandard = this.xssl; //标准
        option = {
                // title: {
                //     text: '体重测定图表(单位: 公斤)',
                //     // subtext: '纯属虚构'
                //     textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                //         fontFamily: 'Arial, Verdana, sans...',
                //         fontSize: 14,
                //         color: '#0B1947',
                //         fontWeight: 'normal',
                //     },
                // },
                tooltip: {
                    trigger: 'axis',
                    textStyle: {
                        fontSize: 50
                    },
                    formatter: function(params) {
                        var result = ''
                        var dotHtml = 
                            '<span style="display:inline-block;margin-right:5px;border-radius:50px;width: 30px;height:30px;background-color:#D905F4"></span>'
                        params.forEach(function (item) {
                            console.log(item)
                            result += item.axisValue + "</br>"  + dotHtml+ ' ' + item.seriesName + ' ' + item.data+'只'
                        })
                        return result
                    }
                },
                grid: {
                    left: 140,
                    top: 30,
                    bottom: 50,
                    right: 50
                },
                // legend: {
                //     icon: 'rectangle',
                //     data: ['标准', '测定'],
                //     right: '4%'
                // },
                toolbox: {
                    show: false,
                    textStyle: {
                        color: '#fff',
                        fontSize: 50
                    },
                    
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        dataView: {readOnly: false},
                        magicType: {type: ['line', 'bar']},
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xData,
                    nameTextStyle :{
					    fontSize: 50
				    },
                    axisLabel: {
                        textStyle: {
                            color: '#CCCCCC',
                            fontSize: 50
                        },
                    },
                    splitArea: {
                        show: true,
                        interval: 0,
                        areaStyle: {
                            color: [
                                '#0B182D',
                                new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
                                    offset: 0, color: '#0A182A' // 0% 处的颜色
                                },  {
                                    offset: 1, color: '#1F1461' // 100% 处的颜色
                                }]),
                            ],
                        },
                    },
                    // axisLine: {
                    //     lineStyle: {
                    //     type: 'solid',
                    //     color: '#3de2d6',
                    //     width: 6
                    //     }
                    // },

                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false,
                        lineStyle:{
                            color: '#CCCCCC',
                            width: 10,
                            type: 'solid'
                        }
                     },
                     splitLine: {
                        show: true,
                        lineStyle:{
                            color: '#142D4A',
                            width: 2,
                            type: 'solid'
                        }
                     },
                    axisLabel: {
                        textStyle: {
                            color: '#CCCCCC',
                            fontSize: 50
                        },
                    },
                    axisLine: {
                        lineStyle: {
                        type: 'solid',
                        color: '#3de2d6',
                            width: 6
                        }
                    },
                },
                series: [
                    {
                        name: '新生羔羊',
                        type: 'line',
                        data: yStandard,
                        smooth: .4,
                        symbolSize: 25,
                        symbol:'circle',
                        color: '#ABA5EE',
                        showSymbol: false,
                        lineStyle:{
                            width: 8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: '#3061F3'
                            }, {
                                offset: 1,
                                color: '#D905F4'
                            }])
                        },
                        // areaStyle: {
                        //     opacity: 0.8,
                        //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        //         offset: 0,
                        //         color: 'rgba(246, 214, 97)'
                        //     }, {
                        //         offset: 1,
                        //         color: 'rgba(246, 214, 97, 0.1)'
                        //     }])
                        // },
                    },
                ]
        };
        option && myChart.setOption(option);
    }
  }
};
</script>

<style scoped>
    .charts-box, #container {
        width: 100%;
        height: 500px;
    }
</style>