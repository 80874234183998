
<template>
  <div class="echarts-box">
    <div id="container1"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  data(){
    return {
      data: [],
      xData: [],
    }
  },  
  mounted() {
    this.moreChart();
  },
  methods: {
    moreChart() {
      var chartDom = document.getElementById('container1');
        var myChart = echarts.init(chartDom);
        var option;

        // var yStandard = [200, 220,210,300,280,310,320,300,350,290]; //标准
        option = {
          tooltip: {
                    textStyle: {
                        fontSize: 50
                    },
                    // formatter: function(params) {
                    //     var result = ''
                    //     var dotHtml = 
                    //         '<span style="display:inline-block;margin-right:5px;border-radius:50px;width: 30px;height:30px;background-color:#3de2d6"></span>'
                    //     params.forEach(function (item) {
                    //         console.log(item)
                    //         result += item.axisValue + "</br>"  + dotHtml+ ' ' + item.seriesName + ' ' + item.data
                    //     })
                    //     return result
                    // }
                },
             xAxis: {
    scale: true
  },
  yAxis: {
    scale: true,
    axisLabel: {
      textStyle: {
          color: '#CCCCCC',
          fontSize: 50
      },
    },
  },
  xAxis: {
      data: this.xData,

      axisLabel: {
          show: false,
          textStyle: {
              color: '#CCCCCC',
              fontSize: 50
          },
      },


  },
  series: this.data
      };
        option && myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss"  scoped>

    .charts-box, #container1 {
        width: 100%;
        height: 600px;
    }

    
</style>