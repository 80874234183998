<template>
    <div class="box flex">
        <div class="map-box">
            <div class="map-left">
                <div class="map-left-item  animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-1"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ddObj.totalArea || 0}}</div>
                        <div class="fs50 cfff mt10">总面积（亩）</div>
                    </div>
                </div>
                <div class="map-left-item  animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-2"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ddObj.greenhousesNumber || 0}}</div>
                        <div class="fs50 cfff mt10">温室数量（座）</div>
                    </div>
                </div>
                <div class="map-left-item  animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-3"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ddObj.greenhousesArea || 0}}</div>
                        <div class="fs50 cfff mt10">温室面积（亩）</div>
                    </div>
                </div>
                <div class="map-left-item  animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-4"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ddObj.archesNumber || 0}}</div>
                        <div class="fs50 cfff mt10">拱棚数量（座）</div>
                    </div>
                </div>
                <div class="map-left-item  animate__animated animate__bounceInDown">
                    <div class="map-left-icon map-left-icon-3"></div>
                    <div class="map-left-text pb33">
                        <div class="c23D1FF fs101">{{ddObj.archesArea || 0}}</div>
                        <div class="fs50 cfff mt10">拱棚面积（亩）</div>
                    </div>
                </div>
            </div>
            <div class="map-img">
                <baidu-map v-if="isShow" :center="center" :zoom="zoom" @ready="handler" style="height:100%; width: 100%" @click="getClickInfo" :scroll-wheel-zoom='true'>
                </baidu-map>
                 <!-- <div v-for="(item, i) in mapData" :style="{left: item.xAxis + 'px', top: item.yAxis + 'px'}" :key="i" class="map-click-left animate__animated animate__fadeInUp"  >
                    <div :class="[ item.position == '1'?'bubbleB': 'bubbleR', 'ml31', 'mb10']">
     
                        <div class="map-text-box-title ">总面积: {{item.totalArea}}</div>
                        <div class="map-text-box-title " >温室: {{item.greenhousesNumber}}</div>
                        <div class="map-text-box-title " >拱棚: {{item.archesNumber}}</div>
                    </div>
                    <div class="potato-icon1"></div>
                    <div class="potato-icon2"></div>
                </div> -->
<!-- 
                <div style="left:1924px; top: 1317px;"  class="map-click-left animate__animated animate__fadeInUp" >
                    <div class="yellowB ml31' mb10">
     
                        <div class="map-text-box-title fw600 mb20">{{ddObj.areaName}}</div>
                        <div class="map-text-box-title ">总面积: {{ddObj.yellowTotalArea}}</div>
                        <div class="map-text-box-title " >温室: {{ddObj.yellowGreenhousesNumber}}</div>
                        <div class="map-text-box-title " >拱棚: {{ddObj.yellowArchesNumber}}</div>
                    </div>
                    <div class="potato-icon1"></div>
                    <div class="potato-icon2"></div>
                </div> -->
                <!-- <div class="map-text-boxs">
                    <div class="map-text-boxs-title">企业：{{ddObj.mapEnterpriseCount || 0}}家</div>
                </div> -->
            </div>
        </div>

        <div class="right animate__animated animate__fadeInRight">
            <!-- <div class="header-text">{{city.enterpriseName}}</div> -->
            <!-- <div class="header"></div> -->
            <div style="display:flex;">
                <div class="content_left item-box">
                    <div class="box_j lt_"></div>
                    <div class="box_j lb_"></div>
                    <div class="box_j rt_"></div>
                    <div class="box_j rb_"></div>
                    <div class="content-top-title-box flex f_ai_c">
                        <img class="content-top-title-line" src="../../../assets/images/dataScreen/titleLine.png" alt="">
                        <img class="content-top-title-icon" src="../../../assets/images/dataScreen/title-logo.png" alt="">
                        <div>基地实时画面</div>

                        <div class="flex" style="justify-content: space-around; align-items: center; line-height: 106px; margin-left: auto">
                                    <div class="flex f_ai_c mr60">
                                        <img style="width: 70px;height: 70px;" class="mr10" src="@/assets/images/dataScreen/dmsc/wd.png" alt="">
                                        <div class="fs60 ml6" style="color: #00e620;" v-if="qxList">温度：{{qxList.iotDataBO.ambientTemperature ? qxList.iotDataBO.ambientTemperature : '--'}}</div>
                                        <div class="cfff fs50" style="color: #00e620">℃</div>
                                    </div>
                                    <div class="flex f_ai_c mr60">
                                        <img style="width: 70px; height: 70px;" class="mr10" src="@/assets/images/dataScreen/dmsc/ph.png" alt="">
                                        <div class="fs60 ml6" style="color: #fce413;" v-if="qxList">PH值：{{qxList.iotDataBO.ph ? qxList.iotDataBO.ph : '--'}}</div>
                                        <div class="cfff fs50"></div>
                                    </div>
                                    <div class="flex f_ai_c mr60">
                                        <img style="width: 70px;height: 70px;" class="mr10" src="@/assets/images/dataScreen/dmsc/sd.png" alt="">
                                        <div class="fs60 ml6" style="color: #e2a513;" v-if="qxList">水分：{{qxList.iotDataBO.ambientHumidity ? qxList.iotDataBO.ambientHumidity : '--'}}</div>
                                        <div class="cfff fs50" style="color: #e2a513">%</div>
                                    </div>
                                    
                                    <div class="flex f_ai_c mr60">
                                        <img style="width: 70px;height: 70px;" class="mr10" src="@/assets/images/dataScreen/dmsc/js.png" alt="">
                                        <div class="fs60 ml6" style="color: #1ab2f1;" v-if="qxList && qxList.deviceFactory == 'JD'">盐分：{{qxList.iotDataBO.soilCond ? qxList.iotDataBO.soilCond / 2 : '--'}}ppm</div>
                                        <div class="fs60 ml6" style="color: #1ab2f1;" v-if="qxList && qxList.deviceFactory != 'JD'">盐分：{{qxList.iotDataBO.salt ? qxList.iotDataBO.salt : '--'}}ppm</div>
                                        <div class="cfff fs50"></div>
                                    </div>
                                    <div class="flex f_ai_c mr60">
                                        <img style="width: 70px;height: 70px;" class="mr10" src="@/assets/images/dataScreen/dmsc/ddl.png" alt="">
                                        <div class="fs60 ml6" style="color: #9400e9;" v-if="qxList">电导率：{{qxList.iotDataBO.soilCond ? qxList.iotDataBO.soilCond : '--'}}uS/cm</div>
                                        <div class="cfff fs50"></div>
                                    </div>
    
                                </div>
                    </div>
                    <div class="" style="display:flex; flex-wrap: wrap;" >
                        <div class="content-item item-box" v-for="(item, i) in ddObj.deviceEnterpriseRelationBOS" :key="i">
                            <div class="content-item-img" >
                                <EZUIKitJs v-if="item.deviceFactory == 'HK'" :msg="'video'+(i+1)" :attribute="webcamAttribute" :flv="item.deviceUrl" />
                                <EZUIKitJs2 v-if="item.deviceFactory == 'DH'" :msg="'video'+(i+1)"  :attribute="webcamAttribute" :flv="item.deviceUrl"/>
                                <!-- <EZUIKitJs msg="video1" :attribute="webcamAttribute"
                                    :flv="ddObj.url1" /> -->
                                <!-- <EZUIKitJs2 v-if="ddObj.url1" :msg="'video1'" :title="video1Title" :attribute="webcamAttribute"
                                    :flv="ddObj.url1" /> -->
                            </div>
                            <div class="content-item-title" @click="transitionFun(item.deviceUrl, item.installArea, item.deviceFactory)">{{item.installArea}}</div>
                        </div>
                        
                        
                      
                        
                    </div>
                </div>
                <div>
                    <div class="tjfx">
                        <div class="box_j lt_"></div>
         
                        <div class="box_j rb_"></div>
                        <div class="content-top-title-box flex f_ai_c">
                            <img class="content-top-title-line" src="../../../assets/images/dataScreen/titleLine.png" alt="">
                            <img class="content-top-title-icon" src="../../../assets/images/dataScreen/title-logo.png" alt="">
                            <div>全区主要蔬菜播种面积与产量统计分析</div>
                            <div style="margin-left: auto; margin-right: 94px; font-weight: 400">单位：亩/吨</div>
                        </div>
                        <div class="tjfx_content">
                            <div class="tjfx_box" v-for="(item,index) in nypzList" :key="index">
                                <div class="tjfx_box_title">
                                    {{item.cerealsName}}
                                </div>
                                <div class="tjfx_box_content">
                                    <div>
                                        <div class="tjfx_sn">
                                            <p>上年度面积</p>
                                            <h3>{{item.onYearArea}}</h3>
                                        </div>
                                        <div class="tjfx_jn">
                                            <p>本年度面积</p>
                                            <h3>{{item.thisYearArea}}<img :src="require('../../../assets/images/dataScreen/dmsc/'+(Number(item.thisYearArea) >= Number(item.onYearArea) ? 'up.png' : 'dowm.png'))"></h3>
                                        </div>
                                    </div>
                                    <img src="../../../assets/images/dataScreen/dmsc/shu.png" alt="">
                                    <div>
                                        <div class="tjfx_sn">
                                            <p>上年度产量</p>
                                            <h3>{{item.onYearOutput}}</h3>
                                        </div>
                                        <div class="tjfx_jn">
                                            <p>本年度产量</p>
                                            <h3>{{item.thisYearOutput}}<img :src="require('../../../assets/images/dataScreen/dmsc/'+(Number(item.thisYearOutput) >= Number(item.onYearOutput) ? 'up.png' : 'dowm.png'))"></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="right_bottom">
                        <div class="mjtj">
                            <div class="box_j lt_"></div>
         
                            <div class="box_j rb_"></div>
                            <div class="content-top-title-box flex f_ai_c">
                                <img class="content-top-title-line" src="../../../assets/images/dataScreen/titleLine.png" alt="">
                                <img class="content-top-title-icon" src="../../../assets/images/dataScreen/titlelogo.png" alt="">
                                <div>各乡镇设施蔬菜建设面积统计</div>
                                <div style="margin-left: auto; margin-right: 94px; font-weight: 400">单位：亩/座</div>
                            </div>
                            <div class="tHeader flex f_ai_c">
                                <div class="th th1 ta_c">乡镇</div>
                                <div class="header-line header-line1"></div>
                                <div class="th th2 ta_c">总面积</div>
                                <div class="header-line header-line2"></div>
                                <div class="th th3 ta_c ">温室数量</div>
                                <div class="header-line header-line3"></div>
                                <div class="th th4 ta_c">温室面积</div>
                                <div class="header-line header-line3"></div>
                                <div class="th th4 ta_c">拱棚数量</div>
                                <div class="header-line header-line3"></div>
                                <div class="th th4 ta_c">拱棚面积</div>
                            </div>

                            <list-scroll style="height: 1000px;" :speed="1" v-if="isDkShow">
                                <div class="list bodyCls">
                                    <div v-for="(item, i) in xzscList" :key="i" class="tr flex">
                                        <div class="td td1 by1">{{item.townshipName}}</div>
                                        <div class="td td2 by1">{{item.totalArea}}</div>
                                        <div class="td td3 by1">{{item.greenhouseNumber}}</div>
                                        <div class="td td4 by1">{{item.greenhouseArea}}</div>
                                        <div class="td td3 by1">{{item.shedNumber}}</div>
                                        <div class="td td4 by1">{{item.shedArea}}</div>
                                    </div>
                                </div>
                            </list-scroll>
                            <!-- <div id="scjs13yyp" style="height: 1000px; overflow: hidden">
                                <div id="scjs13yyp1">
                                    <div v-for="(item, i) in xzscList" :key="i" class="tr flex">
                                        <div class="td td1 by1">{{item.townshipName}}</div>
                                        <div class="td td2 by1">{{item.totalArea}}</div>
                                        <div class="td td3 by1">{{item.greenhouseNumber}}</div>
                                        <div class="td td4 by1">{{item.greenhouseArea}}</div>
                                        <div class="td td3 by1">{{item.shedNumber}}</div>
                                        <div class="td td4 by1">{{item.shedArea}}</div>
                                    </div>
                                </div>
                                <div v-if="isYes">
                                    <div v-for="(item, i) in xzscList" :key="i" class="tr flex">
                                        <div class="td td1 ">{{item.townshipName}}</div>
                                        <div class="td td2">{{item.totalArea}}</div>
                                        <div class="td td3">{{item.greenhouseNumber}}</div>
                                        <div class="td td4">{{item.greenhouseArea}}</div>
                                        <div class="td td3">{{item.shedNumber}}</div>
                                        <div class="td td4">{{item.shedArea}}</div>
                                    </div>
                                </div>
                            </div> -->
                            
                        </div>
                        <div class="jgqj">
                            <div class="box_j lt_"></div>
         
                            <div class="box_j rb_"></div>
                            <div class="content-top-title-box flex f_ai_c">
                                <img class="content-top-title-line" src="../../../assets/images/dataScreen/titleLine.png" alt="">
                                <img class="content-top-title-icon" src="../../../assets/images/dataScreen/titlelogo.png" alt="">
                                <div>全区设施农业主要播种品种价格区间</div>
                                <div class="flex" style="margin-left: auto; margin-right: 94px; font-weight: 400">
                                    <div>
                                        <img src="../../../assets/images/dataScreen/dmsc/sheng.png" alt="" class="mr17">
                                        <span style="font-size: 50px; color: #00FED8;">最高</span>
                                    </div>
                                    <div class="ml86">
                                        <img src="../../../assets/images/dataScreen/dmsc/jiang.png" alt="" class="mr17">
                                        <span style="font-size: 50px; color: #FEDB5A;">最低</span>
                                    </div>
                                </div>
                            </div>
                        <el-carousel height="1150px">
                            <el-carousel-item v-for="(item,index) in scdqList" :key="index">
                            <div>   
                                <div class="flex" style="flex-wrap: wrap; justify-content: space-around">
                                    <div class="ddk" v-for="(itm,inx) in item.children" :key="inx">
                                        <div class="dklt"></div>
                                        <div class="dklb"></div>
                                        <div class="dkrt"></div>
                                        <div class="dkrb"></div>
                                        <div class="alllive">
                                            <div class="flex" style="align-items: center; justify-content: space-around">
                                                <div class="sct">
                                                    {{itm.cerealsName}}
                                                </div>
                                                <div class="sctg">
                                                    <img src="../../../assets/images/dataScreen/dmsc/sheng.png" alt="" class="mr17">
                                                    <span style="font-size: 50px; color: #00FED8;">{{itm.highestPrice}}元</span>
                                                </div>
                                                <div class="sctd">
                                                    <img src="../../../assets/images/dataScreen/dmsc/jiang.png" alt="" class="mr17">
                                                    <span style="font-size: 50px; color: #FEDB5A;">{{itm.lowestPrice}}元</span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            </el-carousel-item>
                        </el-carousel>                            
                        </div>
                    </div>
                </div>
                
                <!-- <div class="trademark item-box">
                    <div class="box_j lt_"></div>
                    <div class="box_j lb_"></div>
                    <div class="box_j rt_"></div>
                    <div class="box_j rb_"></div>
                    <div class="content-top-title-box flex f_ai_c">
                        <img class="content-top-title-line" src="../../../assets/images/dataScreen/titleLine.png" alt="">
                        <img class="content-top-title-icon" src="../../../assets/images/dataScreen/title-logo2.png" alt="">
                        <div>两品一标</div>
                    </div>
                    <div style="margin-top: 98px;" class="flex flex_wrap">
                        <div v-for="(item, i) in trademarkData" :key="i" class="trademark-itme ml20 mr20   ta_c">
                            <img class="trademark-img" :src="item.url" alt="">
                            <div class="trademark-value">{{item.specifications}}</div>
                            <div class="trademark-name">{{item.commodity}}</div>
                        </div>
                    </div>
                </div> -->
                <!-- <div>
                    <div class="weather-station">
                        <div class="box_j lt_"></div>
                        <div class="box_j lb_"></div>
                        <div class="box_j rt_"></div>
                        <div class="box_j rb_"></div>
                        <div class="content-top-title-box flex f_ai_c">
                            <img class="content-top-title-line" src="../../../assets/images/dataScreen/titleLine.png" alt="">
                            <img class="content-top-title-icon" src="../../../assets/images/dataScreen/title-logo2.png" alt="">
                            <div>巴拉素示范大棚物联网监测数据</div>
                        </div>
                        <div>
                            <div class="weather-dashboard item-box">
                                <div class="weather-left">
                                    9.2
                                </div>
                                <div class="weather-right">
                                    <div>
                                        <div class="qxzKeyCls qxzIndex1Cls">东北风</div>
                                        <div>
                       
                                            <div class="qxzValCls">2.7m/s</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex2Cls">湿度</div>
                                        <div>
                        
                                            <div class="qxzValCls">28%Rh</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex3Cls">雨量</div>
                                        <div>
                            
                                            <div class="qxzValCls">0mm</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex4Cls">CO2</div>
                                        <div>
               
                                            <div class="qxzValCls">428ppm</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex5Cls">光照</div>
                                        <div>
    
                                            <div class="qxzValCls">32.7Lux</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="qxzKeyCls qxzIndex6Cls">大气压</div>
                                        <div>
                   
                                            <div class="qxzValCls">88.1Pa</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
    
                            <div style="margin-left: 40px;">
                                <div class="jttrzbjcColumnCls jttrzbjcHeaderCls" style="overflow: hidden;">
                                    <div>地块</div>
                                    <div>土壤PH值</div>
                                    <div>土壤温度</div>
                                    <div>土壤水分</div>
                                    <div>土壤盐分</div>
                                    <div>土壤电导率</div>
                                </div>

                                <div class="jttrzbjcColumnCls jttrzbjcRowCls">
                                    <div>郝哥薯业2号棚</div>
                                    <div>7.3</div>
                                    <div>5℃</div>
                                    <div>68.3%</div>
                                    <div>0.6</div>
                                    <div>0.6</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vegetable-statistics">
                        <div class="box_j lt_"></div>
                        <div class="box_j lb_"></div>
                        <div class="box_j rt_"></div>
                        <div class="box_j rb_"></div>
                        <div class="content-top-title-box flex f_ai_c">
                            <img class="content-top-title-line" src="../../../assets/images/dataScreen/titleLine.png" alt="">
                            <img class="content-top-title-icon" src="../../../assets/images/dataScreen/title-logo2.png" alt="">
                            <div>乡镇设施蔬菜统计</div>
                        </div>
                        <div class="tHeader flex f_ai_c">
                            <div class="th th1 ta_c">名称</div>
                            <div class="header-line header-line1"></div>
                            <div class="th th2 ta_c">总面积(亩)</div>
                            <div class="header-line header-line2"></div>
                            <div class="th th3 ta_c ">拱棚数量(座)</div>
                            <div class="header-line header-line3"></div>
                            <div class="th th4 ta_c">拱棚面积(亩)</div>
                        </div>
                        <div class="tBody">
                            <div v-for="(item, i) in vegetableStatistics" :key="i" class="tr flex">
                                <div class="td td1 ">{{item.township}}</div>
                                <div class="td td2">{{item.totalArea}}</div>
                                <div class="td td3">{{item.archesNum}}</div>
                                <div class="td td4">{{item.archesArea}}</div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="content-right item-box">
                    <div class="box_j lt_"></div>
                    <div class="box_j lb_"></div>
                    <div class="box_j rt_"></div>
                    <div class="box_j rb_"></div>
                    <div class="flex flex_wrap">
                        <div class="yzcyCls" >
                            <div class="qiyeTitleBoxCls">
                                <div class="fnt">{{ddObj.cooperative1}}</div>
                            </div>
                            <div class="qiyetongjiBoxCls">
                                <div>
                                    <div>{{ddObj.cooperative1value1}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative1unit1}}</span></div>
                                    <div>{{ddObj.cooperative1name1}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative1value2}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative1unit2}}</span></div>
                                    <div>{{ddObj.cooperative1name2}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative1value3}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative1unit3}}</span></div>
                                    <div>{{ddObj.cooperative1name3}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative1value4}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative1unit4}}</span></div>
                                    <div>{{ddObj.cooperative1name4}}</div>
                                </div>
                            </div>
                            <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5">
                                <div class="qiyeContentCls">
                                    {{ddObj.synopsis1}}
                                </div>
                            </marquee>
                        </div>

                        <div class="yzcyCls" >
                            <div class="qiyeTitleBoxCls">
                                <div class="fnt">{{ddObj.cooperative2}}</div>
                            </div>
                            <div class="qiyetongjiBoxCls">
                                <div>
                                    <div>{{ddObj.cooperative2value1}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative2unit1}}</span></div>
                                    <div>{{ddObj.cooperative2name1}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative2value2}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative2unit2}}</span></div>
                                    <div>{{ddObj.cooperative2name2}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative2value3}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative2unit3}}</span></div>
                                    <div>{{ddObj.cooperative2name3}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative2value4}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative2unit4}}</span></div>
                                    <div>{{ddObj.cooperative2name4}}</div>
                                </div>
                            </div>
                            <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5">
                                <div class="qiyeContentCls">
                                    {{ddObj.synopsis2}}     
                                </div>
                            </marquee>
                        </div>

                        <div class="yzcyCls" >
                            <div class="qiyeTitleBoxCls">
                                <div class="fnt">{{ddObj.cooperative3}}</div>
                            </div>
                            <div class="qiyetongjiBoxCls">
                                <div>
                                    <div>{{ddObj.cooperative3value1}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative3unit1}}</span></div>
                                    <div>{{ddObj.cooperative3name1}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative3value2}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative3unit2}}</span></div>
                                    <div>{{ddObj.cooperative3name2}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative3value3}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative3unit3}}</span></div>
                                    <div>{{ddObj.cooperative3name3}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative3value4}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative3unit4}}</span></div>
                                    <div>{{ddObj.cooperative3name4}}</div>
                                </div>
                            </div>
                            <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5">
                                <div class="qiyeContentCls">
                                    {{ddObj.synopsis3}}
                                </div>
                            </marquee>
                        </div>

                        <div class="yzcyCls" >
                            <div class="qiyeTitleBoxCls">
                                <div class="fnt">{{ddObj.cooperative4}}</div>
                            </div>
                            <div class="qiyetongjiBoxCls">
                                <div>
                                    <div>{{ddObj.cooperative4value1}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative4unit1}}</span></div>
                                    <div>{{ddObj.cooperative4name1}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative4value2}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative4unit2}}</span></div>
                                    <div>{{ddObj.cooperative4name2}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative4value3}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative4unit3}}</span></div>
                                    <div>{{ddObj.cooperative4name3}}</div>
                                </div>
                                <div>
                                    <div>{{ddObj.cooperative4value4}}<span style="font-size:48px;font-weight: normal;">{{ddObj.cooperative4unit4}}</span></div>
                                    <div>{{ddObj.cooperative4name4}}</div>
                                </div>
                            </div>
                            <marquee class="marquee-list" direction="up" behavior="scroll" scrollamount="5">
                                <div class="qiyeContentCls">
                                   {{ddObj.synopsis4}}
                                </div>
                            </marquee>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <transition name="fade">
            <div v-if="show" class="transtionBox">
                <div class="videdo-box">
                    <div class="video-left">
                        

                    </div>
                    <div class="video-centent">
                        <div id="video111"></div>
                        <div class="video-title">{{vedioTitle}}</div>
                        <div class="video-close" @click="transitionFun"></div>
                    </div>
                    <div class="video-right"></div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import EZUIKitJs2 from '../../../components/EZUIKitJs2.vue'
import EZUIKitJs from '../../../components/EZUIKitJs.vue'
import EZUIKit from "ezuikit-js";
import cyberplayer from "../../../../static/cyberplayer.js"
import Highecharts1 from '../highecharts/dataScreen3/highecharts1.vue'
import ListScroll from "../../../components/listScroll.vue";
export default {
    name: '',
    components: {
        EZUIKitJs2,
        EZUIKitJs,
        Highecharts1,
        ListScroll
    },
    data() {
        return {
            city: {},
            ddObj: {},
            renCount: 0,
            maxCount: 0,
            show: false,
            domHeight: 0,
            isShow: false,
            isYes: false,
            center: {lng: 109.77446, lat: 38.27671},
            zoom: 12,
            timer: null,
            potatoData: [],
            trademarkData: [
            //     {
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // },{
            //     imgUrl: '../../../assets/images/dataScreen/trademark1.png',
            //     trademarkValue: '良好农业规范(GAP)',
            //     trademarkName: '福禄山药',
            // }
            ],
            vegetableStatistics: [
            // {
            //     name: '许家崖村',
            //     zArea: 642,
            //     gpNum: 52,
            //     gpArea: 104
            // },{
            //     name: '南沙村',
            //     zArea: 642,
            //     gpNum: 52,
            //     gpArea: 104
            // },{
            //     name: '米家园则村',
            //     zArea: 642,
            //     gpNum: 52,
            //     gpArea: 104
            // },{
            //     name: '酸梨海则村',
            //     zArea: 642,
            //     gpNum: 52,
            //     gpArea: 104
            // },{
            //     name: '口则队村',
            //     zArea: 642,
            //     gpNum: 52,
            //     gpArea: 104
            // },{
            //     name: '牛家梁村',
            //     zArea: 642,
            //     gpNum: 52,
            //     gpArea: 104
            // }
            ],
            nypzList: [],
            xzscList: [],
            scdqList: [],
            mapData: [],
            qxList: {},
            environmentalAttribute: {
                width: 1185,
                height: 1210
            },
            webcamAttribute: {
                width: 1484,
                height: 1164
            },
            webcamAttribute2: {
                width: 807,
                height: 507
            },
            video1Title: '1',
            video2Title: '2',
            video3Title: '3',
            video4Title: '4',
            video5Title: '5',
            video6Title: '6',
            isDkShow: false

        }
    },

    mounted() {
        this.init();
        this.doPlay("");
    },
    methods: {
        getClickInfo () {},
        handler ({BMap, map}) {
             map.setMapStyleV2({     
                 styleId: 'adad6aa4405217adf1935fb635ffbe8e'
            });
            var bdary = new BMap.Boundary();
            bdary.get('榆阳区', function(rs){
                // map.clearOverlays();
                 var count = rs.boundaries.length;
                 for(var i = 0; i < count; i++){
                        var ply = new BMap.Polygon(rs.boundaries[i], 
                                {strokeWeight: 10, //设置多边形边线线粗
                                strokeOpacity: 1, //设置多边形边线透明度0-1
                                strokeStyle: "dashed", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed

                                strokeColor: "#3c65e2", //设置多边形边线颜色
                                fillColor: "#00ffff", //设置多边形填充颜色
                                fillOpacity:0.01
                                                }); //建立多边形覆盖物
                        map.addOverlay(ply);  //添加覆盖物
                        map.setViewport(ply.getPath());    //调整视野         
                    } 
            })
                // 创建标注对象并添加到地图  
                console.log(this.potatoData)
            for(let i = 0; i < this.potatoData.length; i++) {
                var point = new BMap.Point(this.potatoData[i].xAxis, this.potatoData[i].yAxis);   
                var content = '<div style="position: relative; height: 100%; line-height: 80px"><div style="position: absolute; bottom: -126px; left: 0; right: 0; margin: auto; color: #49e9d9; text-align: center">'+this.potatoData[i].township + '</div><div>' +  '总面积：'+this.potatoData[i].totalArea +'</div>'+'<div>温室：'+this.potatoData[i].greenhousesNumber+'</div>'+'<div>拱棚：'+this.potatoData[i].archesNumber+'</div></div>';
                var label = new BMap.Label(content, {       // 创建文本标注
                    position: point,
                    offset: new BMap.Size(-200, -368)
                })  
                map.addOverlay(label);
                label.setStyle({                              // 设置label的样式
                    color: '#fff',
                    fontSize: '50px',
                    border: '0',
                    backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-08/50a3fb1b05f647a1839b26056cb1a69c0a712bf0586304ba06b739d2a8ff0300.png")',
                    backgroundColor: 'transparent',
                    height: '348px',
                    padding: '15px 35px 46px',
                    backgroundSize: '100% 100%'
                })
                
                
            }

            // var yhPoint = new BMap.Point(this.mlsObj.yhList[0].mapX,this.mlsObj.yhList[0].mapY);
            // var yhMaker = new BMap.Marker(yhPoint, {icon: myIcon});
            // map.addOverlay(yhMaker);   
            //  var yhContent = this.mlsObj.yhList[0].enterpriseName + '<br />'+ this.mlsObj.yhList[0].enterpriseValue + '亩';
            //     var yhLabel = new BMap.Label(yhContent, {      
            //         position: yhPoint,
            //         offset: new BMap.Size(20, -240)
            //     })  

            //     map.addOverlay(yhLabel);
            //     var that = this;
            // yhLabel.addEventListener('click', function(){
            //     that.$parent.tabsTypeClick('4');
            // })
            // yhLabel.setStyle({                              
            //         color: '#fff',
            //         fontSize: '50px',
            //         border: '0',
            //         backgroundImage: 'url("http://coalcbnet-dev-bucket.oss-cn-beijing.aliyuncs.com/userFile/2023-03-04/a7a6f7d5947b40988ec305c109dae093147382af403a6d0d35dfad034fd31344.png")',
            //         backgroundColor: 'transparent',
            //         height: '208px',
            //         padding: '15px 35px 46px',
            //         backgroundSize: '100% 100%'
            //     })
            var point = new BMap.Point(109.72446,38.27671)
            map.centerAndZoom(point, 12)
            map.setCurrentCity("榆阳区");
            // var marker = new BMap.Marker(point) // 创建标注
            // map.addOverlay(marker) // 将标注添加到地图中
            // var circle = new BMap.Circle(point, 6, { strokeColor: 'Red', strokeWeight: 6, strokeOpacity: 1, Color: 'Red', fillColor: '#f03' })
            // map.addOverlay(circle)
            map.enableScrollWheelZoom(true);
        },
        init(){
           // this.city = JSON.parse(sessionStorage.getItem("chooseId"));
            this.qa.queryDatascreen17Info({}).then(res => {
                this.ddObj = res.data;
                this.potatoData = res.data.workMapPOS;
                if(this.ddObj.workMapPOS != null && this.ddObj.workMapPOS.length > 0) {
                    this.mapData = this.ddObj.workMapPOS
                }
                if(this.ddObj.trademarkPOS != null && this.ddObj.trademarkPOS.length > 0) {
                    this.trademarkData = this.ddObj.trademarkPOS
                }

                if(this.ddObj.facilitiesPOS != null && this.ddObj.facilitiesPOS.length > 0) {
                    this.vegetableStatistics = this.ddObj.facilitiesPOS
                }
                console.log(this.mapData)
                //基地人员
                let list = [];
                // for(let i = 0; i < this.ddObj.datascreen5JobBOList.length; i++) {
                //     this.renCount += parseInt(this.ddObj.datascreen5JobBOList[i].jobPersonCount);
                //     let obj = {};
                //     obj.value = this.ddObj.datascreen5JobBOList[i].jobPersonCount;
                //     obj.name = this.ddObj.datascreen5JobBOList[i].jobName + ' ' + this.ddObj.datascreen5JobBOList[i].jobPersonCount + '人';
                //     obj.itemStyle = {
                //         color: `rgb(${parseInt(Math.random()*255)},${parseInt(Math.random()*255)},${parseInt(Math.random()*255)})`
                //     }
                //     list.push(obj)
                // }

                // this.$nextTick(function () {
                //     this.$refs.jdry.Rydata = list;
                //     this.$refs.jdry.actualCount();
                // })
                this.isShow = true;
                this.$nextTick(() => {
                    this.autoplay();
                })
            })
            this.qa.datascreen17AllAreaSowingStatisticsqueryForList({}).then(res => {
                this.nypzList = res.data;
            })
            this.qa.datascreen17TownshipsVegetableAreaStatisticsqueryForList({}).then(res => {
                this.xzscList = res.data;
                this.isDkShow = true;
            })
            this.qa.datascreen17AllAreaSowingPricequeryForList({}).then(res => {
                let count = Math.ceil(res.data.length / 6);
                console.log(count)
                for(let i = 0; i < count; i++) {
                    let obj = {};
                    obj.children = [];
                    for(let j = i*6; j < (i+1)*6; j++) {
                        if(res.data.length == j) {
                            break;
                        }
                        obj.children.push(res.data[j]);
                        
                        
                    }
                    this.scdqList.push(obj)
                }
                
                // this.scdqList = res.data;
            })

            this.qa.queryIotDataByDate({deviceType: 'SENSOR_QX', datascreenNo: '17'}).then(res => {
                
                for(let i = 0; i < res.data.length; i++) {
                    if(!res.data[i].iotDataBO) {
                        res.data[i].iotDataBO = {};
                    }
                }
                if(res.data.length > 0) {
                    this.qxList = res.data[0];
                }
                
   
            })
        },
        doPlay(_url) {
            this.qa.getAccessToken({}).then(res => {
                this.player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video111",
                    accessToken: res.data.accessToken,
                    url: _url,
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    //header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    //plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    // footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                    audio: 0, // 是否默认开启声音 0 - 关闭 1 - 开启
                    // openSoundCallBack: data => console.log("开启声音回调", data),
                    // closeSoundCallBack: data => console.log("关闭声音回调", data),
                    // startSaveCallBack: data => console.log("开始录像回调", data),
                    // stopSaveCallBack: data => console.log("录像回调", data),
                    // capturePictureCallBack: data => console.log("截图成功回调", data),
                    // fullScreenCallBack: data => console.log("全屏回调", data),
                    // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                    width: 3080,
                    height: 1560
                });
            });
        },
        doPlay2(_url){
            this.$nextTick(() => {
                 var player = cyberplayer('video111').setup({
                    width: 3080, // 宽度，也可以支持百分比（不过父元素宽度要有）
                    height: 1560, // 高度，也可以支持百分比
                    title: '111', // 标题
                    isLive: true, // 必须设置，表明是直播视频
                    file: _url, // //您的视频源的地址（目前是乐橙示例播放地址）
                    image: '', // 预览图
                    autostart: true, // 是否自动播放
                    stretching: "uniform", // 拉伸设置
                    repeat: true, // 是否重复播放
                    volume: 0, // 音量，注：仅当用户同意、网站由用户激活或媒体无声时允许自动播放
                    controls: true, // 是否显示控制栏
                    hls: {
                        reconnecttime: 5 // hls直播重连间隔秒数
                    },
                    ak: "a60324c9eed249a7812c629f4d10ee14" // 百度智能云平台注册（https://cloud.baidu.com）即可获得accessKey
                });
            })
           
        },
        autoplay(){
            var dom = document.getElementById("scjs13yyp");
            var dom1 = document.getElementById("scjs13yyp1");

            var that = this;
            console.log(dom1.offsetHeight)
            console.log(dom.offsetHeight)
            if (dom1.offsetHeight > dom.offsetHeight) {
                this.isYes = true;
                if(this.timer) {
                    clearInterval(this.timer);
                }
                // dom2.innerHTML = dom1.innerHTML;//克隆list1的数据，使得list2和list1的数据一样
                this.timer = setInterval(function(){
                    
                    if (dom.scrollTop >= dom1.scrollHeight) {
                        that.domHeight = 0;
                        dom.scrollTop = that.domHeight;
                    } else {
                        that.domHeight++;
                        dom.scrollTop = that.domHeight;
                    }
                }, 20)
            }
        },
        transitionFun(_url, _title, type) {

            this.show = !this.show;
            if(type == "HK") {
                this.doPlay(_url);
            } else {
                this.doPlay2(_url);
            }

            
            this.vedioTitle = _title;
        }
    }
}
</script>

<style scoped>
.item-box {
    position: relative;
}

.box_j {
    width: 61px;
    height: 61px;
    position: absolute;
}

.lt_ {
    background: url(../../../assets/images/dataScreen/sheep/lt.png) no-repeat;
    background-size: 100% 100%;
    top: -5px;
    left: -5px;
}

.rt_ {
    background: url(../../../assets/images/dataScreen/sheep/rt.png) no-repeat;
    background-size: 100% 100%;
    top: -5px;
    right: -5px;
}

.lb_ {
    background: url(../../../assets/images/dataScreen/sheep/lb.png) no-repeat;
    background-size: 100% 100%;
    left: -5px;
    bottom: -5px;
}

.rb_ {
    background: url(../../../assets/images/dataScreen/sheep/rb.png) no-repeat;
    background-size: 100% 100%;
    right: -5px;
    bottom: -5px;
}

.test {
    color: #ccc;
    font-size: 100px;
}

.map-box {
    position: relative;
}

.map-left {
    margin-top: 247px;
    position: relative;
    z-index: 999;
}

.map-left-item {
    width: 798px;
    height: 311px;
    display: flex;
    align-items: center;
    margin-bottom: 135px;
    background: url(../../../assets/images/dataScreen/ds1_bg_box.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-left: 311px;
    position: relative;
}

.map-left-icon {
    background: url(../../../assets/images/dataScreen/mountain-link-full.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 85px;
}

.map-left-icon-1 {
    width: 168px;
    height: 157px;
    background: url(../../../assets/images/dataScreen/mountain-link-full.png) no-repeat;
    background-size: 100% 100%;
}
.map-left-icon-2 {
    width: 157px;
    height: 173px;
    background: url(../../../assets/images/dataScreen/dmscIcon2.png) no-repeat;
    background-size: 100% 100%;
}

.map-left-icon-3 {
    width: 171px;
    height: 151px;
    background: url(../../../assets/images/dataScreen/dmscIcon3.png) no-repeat;
    background-size: 100% 100%;
}
.map-left-icon-4 {
    width: 173px;
    height: 153px;
    background: url(../../../assets/images/dataScreen/dmscIcon4.png) no-repeat;
    background-size: 100% 100%;
}
.map-left-icon-5 {
    width: 171px;
    height: 151px;
    background: url(../../../assets/images/dataScreen/dmscIcon3.png) no-repeat;
    background-size: 100% 100%;
}

.map-img {
    width: 3918px;
    height: 3140px;
    background: url(../../../assets/images/dataScreen/map1.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: -180px;
    z-index: 100;
    left: 0;
}

.map-text-box {
    box-sizing: border-box;
    padding: 70px 0 0 170px;
    width: 1102px;
    height: 678px;
    position: relative;
    /* left: 1242px;
    top: 292px; */
    background: url(../../../assets/images/dataScreen/malinshu-map-text.png) no-repeat;
    background-size: 100% 100%;
}

.map-text-boxs {
    box-sizing: border-box;
    position: relative;
    width: 519px;
    height: 200px;
    left: 670px;
    top: 1445px;
    background: url(../../../assets/images/dataScreen/4-qiye.png) no-repeat;
    background-size: 100% 100%;
}

.map-text-boxs-title {
    font-size: 49px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2CFFFF;
    text-align: right;
    padding-top: 15px;
    padding-right: 25px;
}

.map-text-box-title {
    color: #fff;
    font-size: 40px;
}

.map-text-box-title-2 {
    color: #fff;
    opacity: .8;
    font-size: 53px;
}

.right {
    /*width: 8173px;*/
    margin-left: 3310px;
}

.header {
    width: 7920px;
    height: 72px;
    background: url(../../../assets/images/dataScreen/s_title.png) no-repeat;
    background-size: 100% 100%;

    text-align: center;

    margin: 30px auto 0;
}

.header-text {
    width: 7920px;
    height: 72px;
    color: #fff;
    font-size: 90px;
    margin: 40px auto 0;
    text-align: center;
}

.content_left, .trademark {
    width: 4744px;
    height: 2850px;
    background: #091729;
    border: 9px solid #081E3D;
    /*opacity: 0.78;*/
    /* margin-top: 86px; */
    margin-right: 47px;
   
}
.trademark { 
    width: 2038px;
}
.content-top-left-corner {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/juxingjiao1.png) no-repeat;
}
.content-top-right-corner {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/juxingjiao2.png) no-repeat;
}
.content-bottom-right-corner {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/juxingjiao3.png) no-repeat;
}
.content-bottom-left-corner {
    position: absolute;
    right: -4px;
    bottom: -4px;
    width: 59px;
    height: 59px;
    background: url(../../../assets/images/dataScreen/juxingjiao4.png) no-repeat;
}

.content-top-title-box {
    width: 100%;
    height: 200px;
    padding-left: 82px;
    background: rgba(6, 58, 141, .2);
    font-size: 70px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    position: relative;
}

.content-top-title-icon {
    width: 75px;
    height: 75px;
    margin-right: 42px;
}

.content-top-title-line {
    width: 260px;
    height: 30px;
    position: absolute;
    bottom: 10px;
    left: 210px;
}

.content-top-title {
    
}

.content-item {
    margin-left: 76px;
    width: 1484px;
    /*height: 1191px;*/
    background: rgba(3, 115, 201, 0.18);
    border: 5px solid rgba(71, 214, 255, 0.18);
    /*opacity: 0.18;*/
    margin-top: 97px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-item-img {
    width: 1439px;
    /*height: 1111px;*/
  
}

.content-item-title {
    width: 1440px;
    height: 164px;
    line-height: 164px;
    text-align: right;
    padding-right: 56px;
    position: absolute;
    bottom: 35px;
    right: 29px;
    background: url(../../../assets/images/dataScreen/title-right.png) no-repeat;
    background-size: 100%;
    font-size: 64px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
}

.base-box {
    width: 2076px;
    height: 2710px;
    background: rgba(14, 22, 57, 0.78);
    border: 5px solid rgba(71, 214, 255, 0.18);
    margin-top: 86px;
    margin-left: 83px;
    padding: 105px 84px 65px 82px;
}

.base-title-box {
    display: flex;
    align-items: center;
}

.base-title {
    /*width: 392px;*/
    /*height: 61px;*/
    font-size: 66px;
    font-family: FZZDHJW;
    font-weight: 600;
    color: #FFFFFF;
    margin-right: 70px;
}

.base-title-img {
    width: 235px;
    height: 84px;
    background: url(../../../assets/images/dataScreen/tit-bg.png) no-repeat;
    background-size: 200px 49px;
    background-position: 30px 25px;
}

.base-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 110px;
}

.base-content-item {
    min-width: 475px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-title {
    /*width: 392px;*/
    font-size: 66px;
    font-family: FZZDHJW;
    white-space: nowrap;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
}

.item-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.item-text-num {
    display: inline-block;
    font-size: 86px;
    font-weight: 600;
    color: RGBA(35, 209, 255, 1);
    margin-right: 10px;
}

.item-text-company {
    display: inline-block;
    font-size: 66px;
    color: #fff;
}

.item-img {
    width: 7px;
    height: 132px;
    background: url(../../../assets/images/dataScreen/border.png) no-repeat;
}

.planting-area-content {
    width: 810px;
    height: 100px;
    background: RGBA(32, 63, 118, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 45px;
    padding-right: 45px;
    color: #FFFFFF;
    margin-top: 45px;
}

.planting-area-contents {
    height: 100px;
    background: RGBA(32, 63, 118, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 45px;
    padding-right: 45px;
    color: #FFFFFF;
    margin-top: 45px;
}

.planting-area-item {
    min-width: 154px;
    font-size: 46px;
}

.item-line {
    width: 5px;
    height: 76px;
    background: #FFFFFF;
}

.base-information-box {
    width: 1938px;
    height: 690px;
    /* background: url(../../../assets/images/dataScreen/renyuan.png) no-repeat;*/
    background-size: 100% 100%;
}

.charts1 {
    width: 50%;
    height: 100%;
    position: relative;
}

.echarts-num {
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 50px;
    color: #FDBA00;
    text-align: center;
    height: 50px;
}

.base-information-boxs {
    width: 100%;
    height: 690px;
    display: flex;
    justify-content: space-between;
    padding-right: 61px;
    margin-top: 107px;
}

.base-img {
    width: 816px;
    height: 514px;
    border: 5px solid rgba(8, 110, 148, 1);
    background: red;
    position: relative;
}

.base-text {
    font-size: 53px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    position: absolute;
    bottom: 38px;
    right: 47px;
}

.farm {
    width: 1852px;
    height: 2710px;
    background: rgba(14, 22, 57, 0.78);
    border: 5px solid #123171;
    margin-left: 91px;
    margin-top: 86px;
    padding: 105px 0 0 87px;
}

.farm-item-box {
    padding-left: 31px;
    display: flex;
    flex-wrap: wrap;
}

.farm-item {
    width: 790px;
    height: 442px;
    background: url(../../../assets/images/dataScreen/nongchang.png) no-repeat;
    background-size: 100% 100%;
    padding: 10px 90px 0 81px;
    margin-right: 36px;
    margin-top: 111px;
}

.farm-item-title {
    height: 62px;
    font-size: 67px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #EEC915;
    line-height: 43px;
    text-align: center;
    margin-top: -30px;
}

.farm-item-subheading {
    height: 63px;
    font-size: 68px;
    font-family: Swiss721BT;
    font-weight: 800;
    color: #EEC915;
    line-height: 75px;
    text-align: center;
    margin-top: 58px;
}

.farm-item-list-box {
    display: flex;
    justify-content: space-between;
    font-size: 46px;
    font-family: NotoSansHans;
    font-weight: 400;
    color: #FFFFFF;
}

.list-right {
    font-weight: 500;
    color: #0291C9;
}

.weather-station {
    width: 1459px;
    height: 1234px;
    /*background: rgba(14, 22, 57, 1);*/
    border: 5px solid rgba(18, 49, 113, 1);
    margin-top: 86px;
    position: relative;
}
.vegetable-statistics {
    
    width: 1459px;
    height: 1530px;
    /*background: rgba(14, 22, 57, 1);*/
    border: 5px solid rgba(18, 49, 113, 1);
    margin-top: 86px;
    position: relative;
}
.weather-dashboard {
   margin: 44px 0 0 80px;
   overflow: hidden;
}

.rank-box {
    width: 618px;
    height: 398px;
    color: #ccc;
}

.supplies-item {
    width: 230px;
    text-align: left;
}

.supplies-line-box {
    width: 403px;
    height: 35px;
    position: relative;
}

.supplies-line {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, rgba(45, 233, 180, 0.1), #2DE9B4);
}

.supplies-line-blue {
    width: 100%;
    height: 8px;
    background: linear-gradient(90deg, rgba(0, 90, 238, 0.1), #005AEE);
}

.supplies-inner-circle {
    width: 17px;
    height: 17px;
    background: #77E9D8;
    border-radius: 50%;
    position: absolute;
    right: 0;
}

.supplies-inner-circle-blue {
    background: #28BEFD;
}

.supplies-outer-circle {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(45, 233, 180, 0.3);
    box-shadow: 0px 2px 10px 0px rgba(45, 233, 180, 0.8);
    border-radius: 50%;
    position: absolute;
    right: -17px;
}

.supplies-outer-circle-blue {
    box-shadow: 0px 2px 10px 0px rgba(0, 90, 238, 0.8);
}

.weather-left {
    width: 500px;
    height: 500px;
    line-height: 455px;
    background: url(../../../assets/images/dataScreen/nowBg.png) no-repeat;
    background-size: 100%;
    font-size: 165px;
    text-align: center;
    color: #FFFFFF;
    float: left;
}

.weather-right {
    float: left;
    width: 720px;
    height: 600px;
    margin-left: 30px;
}

.weather-right>div {
    height: 90px;
    font-size: 38px;
}

div.qxzKeyCls {
    float: left;
    color: #00de1e;
}

div.qxzKeyCls.qxzIndex1Cls {
    background: url(../../../assets/images/dataScreen/wlsb1.png) no-repeat;
    background-size: 72px 67px;
    background-position: left 10px;
    text-indent: 100px;
}

div.qxzKeyCls.qxzIndex2Cls {
    background: url(../../../assets/images/dataScreen/wlsb2.png) no-repeat;
    background-size: 52px 52px;
    background-position: 100px 10px;
    text-indent: 200px;
}

div.qxzKeyCls.qxzIndex3Cls {
    background: url(../../../assets/images/dataScreen/wlsb3.png) no-repeat;
    background-size: 52px 52px;
    background-position: 200px 10px;
    text-indent: 300px;
}

div.qxzKeyCls.qxzIndex4Cls {
    background: url(../../../assets/images/dataScreen/wlsb4.png) no-repeat;
    background-size: 52px 52px;
    background-position: 200px 10px;
    text-indent: 300px;
}

div.qxzKeyCls.qxzIndex5Cls {
    background: url(../../../assets/images/dataScreen/wlsb5.png) no-repeat;
    background-size: 52px 52px;
    background-position: 100px 10px;
    text-indent: 200px;
}

div.qxzKeyCls.qxzIndex6Cls {
    background: url(../../../assets/images/dataScreen/wlsb6.png) no-repeat;
    background-size: 52px 52px;
    background-position: left 10px;
    text-indent: 100px;
}

.weather-right>div>div:nth-child(2) {
    float: right;
    color: #fff;

}

div.qxzStatusCls {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    float: left;
    margin-top: 20px;
    margin-right: 20px;
}

div.qxzValCls {
    float: left;

}

div.qxzStatusCls.s1Cls {
    background-color: #00de1e;

}

div.qxzStatusCls.s2Cls {
    background-color: red;

}

div.qxzStatusCls.s3Cls {
    background-color: #ffcb00;

}

div.jttrzbjcRowCls>div {
    font-size: 45px;
    float: left;
    text-align: center;
    height: 150px;
}

div.jttrzbjcHeaderCls>div {
    font-size: 45px;
    color: #fff;
    float: left;
    background-size: 153px 115px;
    background-repeat: no-repeat;
    background-position: top center;
    height: 250px;
    padding-top: 150px;
    text-align: center;
}

div.jttrzbjcHeaderCls>div:nth-child(1) {
    background-image: url(../../../assets/images/dataScreen/turanzb1.png);

}

div.jttrzbjcHeaderCls>div:nth-child(2) {
    background-image: url(../../../assets/images/dataScreen/turanzb2.png);

}

div.jttrzbjcHeaderCls>div:nth-child(3) {
    background-image: url(../../../assets/images/dataScreen/turanzb3.png);

}

div.jttrzbjcHeaderCls>div:nth-child(4) {
    background-image: url(../../../assets/images/dataScreen/turanzb4.png);

}

div.jttrzbjcHeaderCls>div:nth-child(5) {
    background-image: url(../../../assets/images/dataScreen/turanzb5.png);

}

div.jttrzbjcHeaderCls>div:nth-child(6) {
    background-image: url(../../../assets/images/dataScreen/turanzb6.png);

}

div.jttrzbjcColumnCls>div:nth-child(1) {
    width: 300px;
}

div.jttrzbjcColumnCls>div:nth-child(2) {
    width: 200px;
}

div.jttrzbjcColumnCls>div:nth-child(3) {
    width: 200px;
}

div.jttrzbjcColumnCls>div:nth-child(4) {
    width: 200px;
}

div.jttrzbjcColumnCls>div:nth-child(5) {
    width: 200px;
}

div.jttrzbjcColumnCls>div:nth-child(6) {
    width: 300px;
}

div.jttrzbjcRowCls>div:nth-child(1) {
    color: #9fd80d;
}

div.jttrzbjcRowCls>div:nth-child(2) {
    color: #e5d500;
}

div.jttrzbjcRowCls>div:nth-child(3) {
    color: #00e73c;
}

div.jttrzbjcRowCls>div:nth-child(4) {
    color: #e5d500;
}

div.jttrzbjcRowCls>div:nth-child(5) {
    color: #00ecef;
}

div.jttrzbjcRowCls>div:nth-child(6) {
    color: #c155ed;
}

div.rewuyujinCls {
    width: 100%;
    height: 710px;
}
div.rewuyujinCls>div.rwssjdCls{
    height: 100%;
    width: 50%;
    float: left;
}
div.rewuyujinCls>div.nsyjCls{
    height: 100%;
    width: 50%;
    float: left;

}
div.rwmcCls{
    margin-top: 50px;
    background-size: 593px 98px;
    width: 593px;
    height: 98px;
    line-height: 98px;
    background-image: url(../../../assets/images/dataScreen/rwbg1.png);
}
div.rwmcCls>div:nth-child(1){
    float: left;
    color: #fff;
    font-size: 40px;
    margin-left: 70px;
    background-image: url(../../../assets/images/dataScreen/fwd1.png);
    background-size: 50px 50px;
    background-position: center left;
    background-repeat: no-repeat;
    text-indent: 70px;
}
div.rwmcCls>div:nth-child(2){
    color: #eddd03;
    float: right;
    font-size: 50px;
    margin-right: 100px;
}
div.renwuBoxCls{
    border: 5px solid #25a1ba;
    margin-top: 50px;
    background-size: 593px 98px;
    width: 593px;
    height: 98px;
    line-height: 98px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
}
div.renwuBoxCls>div:nth-child(1){
    float: left;
    background-color: #365acb;
    color: #f4f17d;
    font-size: 30px;
    text-indent: 50px;
    padding-right: 40px;
}
div.renwuBoxCls>div:nth-child(2){
    float: left;
    background-color: #c2be6c;
    color: #0160fc;
    font-size: 30px;
    text-indent: 50px;
    flex: 1;
}
div.nsyjBoxCls{
    margin-top: 80px;
    margin-bottom: 80px;
    background-size: 694px 61px;
    width: 694px;
    height: 61px;
    line-height: 61px;
    background-image: url(../../../assets/images/dataScreen/rwbg2.png);
}

div.nsyjBoxCls>div:nth-child(1){
    background-image: url(../../../assets/images/dataScreen/baojin.png);
    background-size: 30px 30px;
    background-position: 30px center;
    background-repeat: no-repeat;
    text-indent: 100px;
    font-size: 32px;
    color: #fff;
    float: left;
    
}

div.nsyjBoxCls>div:nth-child(2){
    font-size: 25px;
    color: #fff;
    float: right;
    margin-right: 40px;
}
.map-click-left {
    /* width: 1241px;
    top: 266px;
    left: 1225px;*/
    position: absolute;
}
.bubbleR {
    height: 213px;
    box-sizing: border-box;
    padding: 15px 68px 46px 30px;
    background: url(../../../assets/images/dataScreen/dmscMapBgRight.png) no-repeat;
    background-size: 100% 100%;
}
.bubbleB {
    height: 258px;
    box-sizing: border-box;
    padding: 15px 35px 46px;
    background: url(../../../assets/images/dataScreen/dmscMapBgBottom.png) no-repeat;
    background-size: 100% 100%;
}
.trademark-img {
    width: 221px;
    height: 221px;
}
.trademark-itme {
    width: 460px;
    margin-bottom: 109px;
}
.trademark-value {
    color: #19EF64;
    font-size: 46px;
    text-align: center;
    margin: 20px 10px;
}
.trademark-name {
    color: #fff;
    font-size: 60px;
    text-align: center;
}
.tHeader {
    width: 2530px;
    height: 139px;
    font-size: 48px;
    color: #fff;
    background: #072148;
    margin: 52px auto 0;
    position: relative;
}
.td {
    text-align: center;
    font-size: 42px;
    color: #fff;
}
.th1, .td1 {
    width: 346px;
}
.th2, .td2  {
    width: 426px;
}
.th3, .td3  {
    width: 426px;
}
.th4, .td4  {
    width: 426px;
}
.header-line {
    width: 2px;
    height: 77px;
    background: #0F3875;
    /* position: absolute; */
    top: 33px;
}
.header-line1 {
    left: 217px;
}
.header-line2 {
    left: 571px;
}
.header-line3 {
    left: 982px;
}
.tr {
    width: 2530px;
    margin: 0 auto;
}
.tr:nth-child(odd){
    height: 178px;
    line-height: 178px;
}
/* 偶数行 */
.tr:nth-child(even){
    height: 133px;
    line-height: 133px;
    background: #081B37;
}
.content-right {
    width: 3296px;
    height: 2850px;
    /* border: 5px solid rgba(18, 49, 113, 1); */
    margin-top: 85px;
    position: relative;
    margin-left: 47px;
    box-sizing: border-box;
    padding: 113px 0 0;
}

.yzcyCls {
    width: 1424px;
    margin-left: 133px;
}
div.qiyeTitleBoxCls {
    position: relative;
    font-weight: bolder;
    font-size: 75px;
    background-image: url("../../../assets/images/dataScreen/zhizhi.png");
    background-size: 1650px 71px;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
}

div.qiyeTitleBoxCls>div.fnt {
    text-align: center !important;
}

div.qiyetongjiBoxCls {
    height: 305px;
    margin-top: 100px;
    display: flex;
    font-size: 48px;
    color: #FFFFFF;
}

div.qiyetongjiBoxCls>div {
    background-size: 191px 116px;
    background-repeat: no-repeat;
    background-position: center bottom;
    text-align: center;
    flex: 1;
}

div.qiyetongjiBoxCls>div>div {
    text-align: center;
}

div.qiyetongjiBoxCls>div>div:nth-child(1) {
    font-size: 50px;
    height: 100px;
    line-height: 100px;
    font-weight: bolder;
}

div.qiyetongjiBoxCls>div>div:nth-child(2) {
    font-size: 36px;
}

div.qiyetongjiBoxCls>div:nth-child(1) {
    background-image: url("../../../assets/images/dataScreen/q1.png");
}

div.qiyetongjiBoxCls>div:nth-child(2) {
    background-image: url("../../../assets/images/dataScreen/q2.png");
}

div.qiyetongjiBoxCls>div:nth-child(3) {
    background-image: url("../../../assets/images/dataScreen/q3.png");
}

div.qiyetongjiBoxCls>div:nth-child(4) {
    background-image: url("../../../assets/images/dataScreen/q4.png");
}

div.qiyeContentCls {
    text-indent: 100px;
    width: 100%;
    height: 750px;
    font-size: 50px;
    color: #6FB6FF;
    line-height: 80px;
    text-align: justify;
    text-justify: distribute-all-lines;
}

.marquee-list {
    margin-top: 30px;
    margin-bottom: 80px;
    height: 750px;
    overflow: hidden;
}

.yellowB {
    width: 403px;
    height: 394px;
    box-sizing: border-box;
    padding: 20px 30px;
    background: url(../../../assets/images/dataScreen/yellowBg.png) no-repeat;
    font-size: 40px;
    background-size: 100% 100%;
}
.tjfx {
    position: relative;
    width: 4698px;
    height: 1328px;
    background: #091729;
    border: 9px solid #081E3D;
    margin-bottom: 71px;
}
.tjfx_content {
    display: flex;
    padding: 80px 98px;
    flex-wrap: wrap;
    margin-bottom: -80px;
    
}
.tjfx_box {
    width: 849px;
    height: 445px;
    background: #081B37;
    position: relative;
    margin-left: 59px;
    margin-bottom: 80px;
}
.tjfx_box:nth-child(5n+1) {
    margin-left: 0;
}

.tjfx_box_title {
    position: absolute;
    width: 380px;
    height: 99px;
    background: url(../../../assets/images/dataScreen/dmsc/biaoti.png) no-repeat;
    background-size: 100% 100%;
    left: 0;
    right: 0;
    top: -45px;
    margin: auto;
    color: #fff;
    font-size: 55px;
    font-weight: 500;
    text-align: center;
    line-height: 99px;
    
}
.tjfx_box_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    margin-top: 45px;
}
.tjfx_sn p{
    font-size: 40px;
    font-weight: 400;
    color: #BBC0C8;
    line-height: 60px;
}
.tjfx_sn h3 {
    font-size: 60px;
    font-weight: bold;
    color: #00FED8;
    line-height: 90px;
}
.tjfx_jn p{
    font-size: 40px;
    font-weight: 400;
    color: #BBC0C8;
    line-height: 60px;
    margin-top: 30px;
}
.tjfx_jn h3 {
    font-size: 60px;
    font-weight: bold;
    color: #FEDB5A;
    line-height: 90px;
}
.tjfx_jn h3 img {
    margin-left: 20px;
}
.right_bottom {
    display: flex;
    justify-content: space-between;
}
.mjtj {
    width: 2590px;
    height: 1450px;
    background: #091729;
    border: 9px solid #081E3D;
    position: relative;
}
.jgqj {
    width: 2040px;
    height: 1450px;
    background: #091729;
    border: 9px solid #081E3D;
    position: relative;
}
.ddk {
    width: 932px;
    height: 290px;
    background: #081B37;
    border-radius: 15px;
    position: relative;
    margin-top: 60px;
 
}
.ddk >.alllive {
    height: 100%;
}
.dklt {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../../assets/images/dataScreen/dmsc/lt.png) no-repeat;
    background-size: 100% 100%; 

}
.dklb {
    width: 22px;
    height: 22px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(../../../assets/images/dataScreen/dmsc/lb.png) no-repeat;
    background-size: 100% 100%; 

}
.dkrt {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    right: 0;
    background: url(../../../assets/images/dataScreen/dmsc/rt.png) no-repeat;
    background-size: 100% 100%; 

}
.dkrb {
    width: 22px;
    height: 22px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(../../../assets/images/dataScreen/dmsc/rb.png) no-repeat;
    background-size: 100% 100%; 

}
.sct {
    text-align: center;
    width: 215px;
    height: 220px;
    background: url(../../../assets/images/dataScreen/dmsc/db.png) no-repeat center 120px;
    font-size: 50px;
    font-weight: 500;
    color: #fff;
    line-height: 215px;
}
.transtionBox {
    width: 100%;
    height: 3456px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videdo-box {
    display: flex;
    align-items: center;
}
.video-left {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-right.png");
    background-size: 100% 100%;
    cursor: pointer;
}
.video-centent {
    width: 3575px;
    height: 2056px;
    background: #000E2C;
    border: 6px solid #001E5D;
    margin: 0 222px;
    padding: 194px 238px 238px 238px;
    position: relative;
}
.video-title {
    font-size: 65px;
    color:#fff;
    width: 1517px;
    height: 205px;
    line-height: 205px;
    text-align: center;
    background: #001E5D;
    border-radius: 100px;
    position: absolute;
    bottom: -100px;
    left: 1029px;
}
.video-close {
    width: 347px;
    height: 347px;
    background: url("../../../assets/images/dataScreen/guanbi.png");
    background-size: 100% 100%;    
    position: absolute;
    bottom: -400px;
    left: 1614px;
    cursor: pointer;    
}
.video-right {
    width: 590px;
    height: 1968px;
    background: url("../../../assets/images/dataScreen/tip-left.png");
    background-size: 100% 100%;
    cursor: pointer;
}
</style>